import type { MedrecSnapshot } from '@/types/dischargePatient';
import type { Medication } from '@/types/medication';
import { gql } from '@apollo/client';

export const VERIFY_MEDICATION = gql`
  mutation changeVerificationStatusAccountMedications($medsVerificationStatuses: [MedicationVerificationStatusInput!]!) {
    changeVerificationStatusAccountMedications(medsVerificationStatuses: $medsVerificationStatuses) {
      status
    }
  }
`;

export type GetMedicationsResponse = {
  accountMedications: {
    accountMedications: Medication[];
  };
};
export const GET_MEDICATIONS = gql`
  query getMedications($truentityId: String!, $filterOptions: MedicationFilterOptionsInput!, $pageNum: Int!, $pageSize: Int!) {
    accountMedications(truentityId: $truentityId, filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      accountMedications {
        id
        ndc
        name
        displayName
        isEffective
        isTakenAsPrescribed
        isTakenAsDirected
        isNoLongerTaking
        isTakingDifferently
        isUnableToVerify
        importedName
        lastVerifiedById
        source
        isCurrent
        pharmacyName
        numRefills
        daysSupply
        instructions
        lastFillDateAt
        soldDateAt
        prescriberName
        prescriptionWrittenDateAt
        isSupplement
        dosage {
          id
          value
          unit
          isPrescribed
        }
        strength {
          id
          value
          unit
          isPrescribed
        }
        period {
          id
          value
          unit
          isPrescribed
        }
        timeOfDay {
          id
          value
          unit
          isPrescribed
        }
        routeOfAdministration {
          id
          value
          unit
          isPrescribed
        }
        interactions {
          title
          id
          conflictNdc
          conflictDrugLabelName
          primaryDrugLabelName
          severityLevel
          severityDetail
          effect
          mechanismAction
          clinicalEffects
          patientManagement
        }
        createdAt
      }
    }
  }
`;

export type ResetAllscriptsMedicationsResponse = {
  resetAllscriptsMedication: {
    status: string;
  };
};

export const RESET_MEDICATIONS = gql`
  mutation ResetAllscriptsMedication($truentityId: String!) {
    resetAllscriptsMedication(truentityId: $truentityId) {
      status
    }
  }
`;

export type GetMedrecSnapshotsResponse = {
  medrecSnapshots: {
    medrecSnapshots: MedrecSnapshot[];
  };
};
export const GET_MED_REC_SNAPSHOTS = gql`
  query GetMedrecSnapshots($truentityId: String!) {
    medrecSnapshots(truentityId: $truentityId) {
      medrecSnapshots {
        id
        name
        description
        isMedRecAssignForRpmReport
        createdAt
        updatedAt
        relyingPartyAdmin {
          id
          name
          email
        }
      }
    }
  }
`;

export type GetMedrecSnapshotDetailResponse = {
  medrecSnapshot: {
    accountMedications: Medication[];
    medrecSnapshot: MedrecSnapshot;
    meta?: {
      totalPages: number;
      totalCount: number;
    };
  };
};
export const GET_MED_REC_SNAPSHOT_DETAIL = gql`
  query GetMedrecSnapshot($medrecSnapshotId: String!, $truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    medrecSnapshot(medrecSnapshotId: $medrecSnapshotId, truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      accountMedications {
        name
        displayName
        ndc
        id
        instructions
        isEffective
        prescriberName
        note
        adherance
        dosage {
          value
          unit
          isPrescribed
        }
        strength {
          value
          unit
          isPrescribed
        }
        interactions {
          title
          id
        }
        period {
          value
          unit
          isPrescribed
        }
      }
      meta {
        totalPages
        totalCount
      }
      medrecSnapshot {
        createdAt
        name
        relyingPartyAdmin {
          name
        }
      }
    }
  }
`;
export const SNAPSHOT_REPORT_EXPORT = gql`
  mutation createPdfMedrecSnapshotReport($medrecSnapshotId: String!) {
    createPdfMedrecSnapshotReport(medrecSnapshotId: $medrecSnapshotId) {
      pdfDownloadUrl
    }
  }
`;
export const GET_MEDREC_INTERACTIONS = gql`
  query getMedrecInteractions($medrecSnapshotId: String!, $truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    medrecInteractions(medrecSnapshotId: $medrecSnapshotId, truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      description
      meds {
        name
      }
    }
  }
`;

export const GET_COMMENTS_FOR_MEDICATIONS = gql`
  query comments($accountMedicationId: String!, $pageNum: Int!, $pageSize: Int!) {
    commentsByAccountMedication(accountMedicationId: $accountMedicationId, pageNum: $pageNum, pageSize: $pageSize) {
      comments {
        id
        body
        createdAt
        sender {
          name
          email
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const ADD_NOTE_TO_ACCOUNT_MEDICATION = gql`
  mutation AddNoteToAccountMedication($accountMedicationId: String!, $note: String!) {
    addNoteToAccountMedication(accountMedicationId: $accountMedicationId, note: $note) {
      status
      message
    }
  }
`;
