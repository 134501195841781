import { DialogActions, DialogContent, Stack } from '@mui/material';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import { useEffect, useState } from 'react';
import HorizontalStepper from '@/components/Stepper/HorizontalStepper';
import AssignUserForm from '@/routes/Administration/Components/AssignUserForm';
import type { AddCompanyFormValuesType } from '@/routes/Administration/Components/AddCompanyForm';
import AddCompanyForm, { addCompanyFormDefault } from '@/routes/Administration/Components/AddCompanyForm';
import Button from '@/components/Button';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import type { AddCompanyResponse } from '@/graphql/company';
import { ADD_COMPANY } from '@/graphql/company';
import { MedicalServices } from '@/types/admin';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { useModal } from 'mui-modal-provider';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import useAssignExistingUser from '@/hooks/forms/useAssignExistingUser';
import AddUserForm from '@/routes/Administration/Components/AddUserForm';
import useAddNewUser from '@/hooks/forms/useAddNewUser';
import { UserFormMode } from '@/types/utils';

type AddCompanyDialogProps = BaseDialogProps & {
  hideDialog: () => void;
  onCompanyAdd: () => void;
};

enum AddCompanySteps {
  COMPANY_DETAILS = 0,
  ASSIGN_EXISTING_USERS = 1,
  ADD_NEW_USER = 2
}

const AddCompanyDialog = ({ title, hideDialog, onCompanyAdd: reFetchCompanies, ...props }: AddCompanyDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [steps, setSteps] = useState<{
    [key: string]: boolean;
  }>({
    [AddCompanySteps.COMPANY_DETAILS]: false,
    [AddCompanySteps.ASSIGN_EXISTING_USERS]: false,
    [AddCompanySteps.ADD_NEW_USER]: false
  });
  const [activeStep, setActiveStep] = useState(0);
  const [newCompany, setNewCompany] = useState<{ id: string; name: string } | null>(null);

  const onExistingUserAdd = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSteps({
      ...steps,
      [AddCompanySteps.ASSIGN_EXISTING_USERS]: true
    });
  };

  const onUserAdd = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    hideDialog();
  };

  const onCompanySave = () => {
    reFetchCompanies();
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSteps({
      ...steps,
      [AddCompanySteps.COMPANY_DETAILS]: true
    });
  };

  const { handleAssignUsersSave, selectedUsers, setSelectedUsers, loadingAssignMultipleAdmins } = useAssignExistingUser({
    onExistingUserAdd
  });

  const {
    control: addUserControl,
    setValue: addUserSetValue,
    getValues: addUserGetValues,
    watch: addUserWatch,
    reset: addUserReset,
    addUserFormState,
    onSubmit: addUserOnSubmit
  } = useAddNewUser({ onUserAdd });

  const {
    control: addCompanyControl,
    handleSubmit: addCompanyHandleSubmit,
    formState: addCompanyFormState,
    reset: addCompanyFormReset,
    watch: addCompanyFormWatch
  } = useForm<AddCompanyFormValuesType>({
    defaultValues: addCompanyFormDefault
  });

  const [addCompany, { loading: loadingAddCompany }] = useMutation<AddCompanyResponse>(ADD_COMPANY);

  const handleCompanySave = async (values: AddCompanyFormValuesType) => {
    const inputValues = {
      name: values.companyName,
      medicalServices: [
        values.rpm ? MedicalServices.RPM : '',
        values.tcm ? MedicalServices.TCM : '',
        values.mtm ? MedicalServices.MTM : ''
      ].filter(value => value !== ''),
      clientOrganizationId: values.clientOrganization,
      clientStoreId: values.clientStore
    };

    try {
      const result = await addCompany({
        variables: inputValues
      });

      if (result?.data?.addRelyingParty?.status === 'Success' && result?.data?.addRelyingParty?.relyingParty?.id) {
        setNewCompany({
          id: result?.data?.addRelyingParty?.relyingParty?.id,
          name: result?.data?.addRelyingParty?.relyingParty?.name
        });
        enqueueSnackbar('Company added successfully', {
          variant: 'success'
        });
        onCompanySave();
      } else {
        enqueueSnackbar(
          result.errors && result.errors?.length > 0 ? result?.errors[0]?.message : 'Something went wrong. Unable to add new company.',
          {
            variant: 'error'
          }
        );
      }
    } catch (err) {
      enqueueSnackbar((err instanceof Error && err?.message) ?? 'Something went wrong. Unable to add new company.', {
        variant: 'error'
      });
    }
  };

  const handleCancelDialog = () => {
    if (newCompany !== null) {
      if (steps[AddCompanySteps.ASSIGN_EXISTING_USERS] || steps[AddCompanySteps.ADD_NEW_USER]) {
        const modal = showModal(ConfirmDialog, {
          title: 'Confirm Cancel',
          message: 'Are you sure you want to finalize the process without adding a New User?',
          onAgree: () => {
            modal.hide();
            hideDialog();
          },
          onDisagree: () => {
            modal.hide();
          }
        });
      } else {
        const modal = showModal(ConfirmDialog, {
          title: 'Confirm Cancel',
          message: 'This will create an incomplete Company. Please contact support to set it up correctly after cancelling.',
          onAgree: () => {
            modal.hide();
            hideDialog();
          },
          onDisagree: () => {
            modal.hide();
          }
        });
      }
    } else {
      hideDialog();
    }
  };

  const handleAddNewUserSkip = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
    setSteps({
      ...steps,
      [AddCompanySteps.ADD_NEW_USER]: true
    });
    enqueueSnackbar('Adding new User skipped', {
      variant: 'info'
    });
    hideDialog();
  };

  useEffect(() => {
    if (newCompany !== null) {
      addUserSetValue('relyingPartyId', newCompany.id);
    }
  }, [newCompany]);

  const handleFormReset = () => {
    setNewCompany(null);
    setSelectedUsers([]);
    addCompanyFormReset();
    addUserReset();
  };

  const onAddCompanyFormSubmit: SubmitHandler<AddCompanyFormValuesType> = data => handleCompanySave(data);

  return (
    <BaseDialog {...props} title={title} hideDialog={handleCancelDialog} fullWidth maxWidth="lg">
      <DialogContent>
        <Stack spacing={1}>
          <HorizontalStepper
            steps={[
              {
                label: 'Company Details',
                children: (
                  <AddCompanyForm
                    watch={addCompanyFormWatch}
                    control={addCompanyControl}
                    onSubmit={addCompanyHandleSubmit(onAddCompanyFormSubmit)}
                  />
                ),
                canSave: () => (newCompany === null && addCompanyFormState?.isDirty && addCompanyFormState?.isValid) || loadingAddCompany,
                trySave: addCompanyHandleSubmit(onAddCompanyFormSubmit),
                optional: false,
                canNext: () => steps[AddCompanySteps.COMPANY_DETAILS] && newCompany !== null
              },
              {
                label: 'Assign Existing Users',
                children: (
                  <AssignUserForm
                    formMode={UserFormMode.NEW_COMPANY}
                    companyName={newCompany?.name}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                  />
                ),
                canSave: () => (selectedUsers.length > 0 && newCompany !== null) || loadingAssignMultipleAdmins,
                trySave: () => {
                  handleAssignUsersSave(newCompany?.id);
                },
                canNext: () => steps[AddCompanySteps.ASSIGN_EXISTING_USERS]
              },
              {
                label: 'Add New User',
                children: (
                  <AddUserForm
                    formMode={UserFormMode.NEW_COMPANY}
                    control={addUserControl}
                    setValue={addUserSetValue}
                    getValues={addUserGetValues}
                    watch={addUserWatch}
                  />
                ),
                canSave: () => addUserFormState?.isDirty && addUserFormState?.isValid,
                trySave: addUserOnSubmit,
                canNext: () => true,
                tryNext: handleAddNewUserSkip
              }
            ]}
            currentStep={activeStep}
            onFormReset={handleFormReset}
            enableFinalize={false}
            disableFinishedStep={true}
          />
          <DialogActions>
            <Button type="reset" a11yLabel="Cancel" disabled={false} appearance="outline" onClick={handleCancelDialog} />
          </DialogActions>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default AddCompanyDialog;
