import type { AutocompleteProps } from '@mui/material';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import type { UseControllerProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type Props = UseControllerProps<AutocompleteProps<any, any, any, any>> & {
  onUpdate: () => void;
  name: string;
  label: string;
};
const ControlledAutoComplete = ({ onUpdate, control, options, name, label, ...props }: Props) => (
  <Controller
    name={name}
    control={control}
    {...props}
    render={({ field: { value, onChange } }) => (
      <Autocomplete
        multiple
        freeSolo
        filterSelectedOptions
        options={options}
        value={value}
        onChange={(e, data) => {
          onChange(data);
          onUpdate();
        }}
        renderInput={params => <TextField {...params} label={`Enter ${label}`} />}
      />
    )}
  />
);

export default ControlledAutoComplete;
