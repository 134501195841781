import SideMenuContext from '@/context/sideMenuContext';
import { AccountListsResponse, CREATE_ACCOUNT_LIST, GET_ALL_ACCOUNTS_LIST } from '@/graphql/account-lists';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import useToken from '@/hooks/useToken';
import { theme } from '@/styles/mui-theme';
import { MedicalServices, Role } from '@/types/admin';
import { pathnameIncludes, pathnameIncludesAny } from '@/util/location';
import { useOrganizationStarredStore } from '@/zustand/OrganizationStarredStore';
import { useMutation, useQuery } from '@apollo/client';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import HomeIcon from '@mui/icons-material/Home';
import MedicationIcon from '@mui/icons-material/Medication';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import PersonIcon from '@mui/icons-material/Person';
import type { StackProps } from '@mui/material';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ACCOUNT_LIST_DATA } from '../StarredPatient';
interface IStyledSideMenuIconButton {
  isSelected?: boolean;
  height?: string;
}
const StyledSideMenuIconButton = styled(IconButton, {
  shouldForwardProp: prop => isPropValid(prop)
})((props: IStyledSideMenuIconButton) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: props.isSelected === true ? theme.palette.primary[600] : theme.palette.primary.dark,
  padding: theme.spacing(3),
  borderRadius: 0,
  height: props.height ?? theme.spacing(10),
  '&:hover': {
    backgroundColor: props.isSelected === true ? theme.palette.primary[600] : theme.palette.primary[600]
  },
  '&:disabled': {
    color: 'inherit',
    opacity: 0.5
  }
}));

type Props = StackProps & {
  drawerOpen: boolean;
  drawerWidth: number;
};
export const BaseSideMenuSideNav = ({ children, drawerOpen, drawerWidth, ...props }: Props) => {
  const { roleType } = useToken();
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isADTAdmin] = useState<boolean>(roleType === Role.ADT_ADMIN);
  const navigate = useNavigate();

  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);
  const isMTMViewable = useShowMedicalServices(MedicalServices.MTM);
  const isTCMViewable = useShowMedicalServices(MedicalServices.TCM);

  const isAnyAdmin = useMemo(() => isSuperAdmin || isPharmacistAdmin || isADTAdmin, [isADTAdmin, isPharmacistAdmin, isSuperAdmin]);

  const { setDrawerOpen } = useContext(SideMenuContext);
  const { setStarredList } = useOrganizationStarredStore();

  // create and get default account list for organization
  const { data: accountListData, refetch: refetchAccountList } = useQuery<AccountListsResponse>(GET_ALL_ACCOUNTS_LIST, {
    variables: {
      pageNum: 1,
      pageSize: 100
    },
    fetchPolicy: 'cache-and-network'
  });
  const [createAccountList] = useMutation(CREATE_ACCOUNT_LIST, {
    onCompleted: () => refetchAccountList()
  });

  useEffect(() => {
    if (pathnameIncludes('/home')) {
      setDrawerOpen(false);
    } else {
      setDrawerOpen(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (accountListData && accountListData.getAllAccountLists) {
      const accountList = accountListData.getAllAccountLists?.accountLists?.find(
        accountList => accountList?.name === DEFAULT_ACCOUNT_LIST_DATA.name
      );
      if (accountList) return setStarredList(accountList);

      createAccountList({
        variables: {
          name: DEFAULT_ACCOUNT_LIST_DATA.name,
          description: DEFAULT_ACCOUNT_LIST_DATA.description,
          isShared: DEFAULT_ACCOUNT_LIST_DATA.isShared
        }
      })
        .then(() => refetchAccountList())
        .catch(err => console.error(err));
    }
  }, [accountListData]);

  return (
    <Stack direction="row" height={'100%'} maxWidth={drawerWidth} {...props}>
      <Stack bgcolor={theme.palette.primary.dark} height="100%" alignItems={'center'}>
        <Tooltip title="Back">
          <StyledSideMenuIconButton
            onClick={() => {
              navigate(-1);
            }}
            disabled={window.history.state?.idx === 0}
            isSelected={false}
          >
            <ArrowBackIosNewIcon />
          </StyledSideMenuIconButton>
        </Tooltip>
        <Tooltip title="Home">
          <StyledSideMenuIconButton
            onClick={() => {
              navigate(`/home`);
            }}
            isSelected={pathnameIncludes('/home')}
          >
            <HomeIcon />
          </StyledSideMenuIconButton>
        </Tooltip>
        <Tooltip title="Patients Management">
          <StyledSideMenuIconButton
            onClick={() => {
              navigate(`/patients?search=lookup`);
            }}
            isSelected={pathnameIncludes('/patients')}
          >
            <PersonIcon />
          </StyledSideMenuIconButton>
        </Tooltip>
        {isMTMViewable && (
          <Tooltip title="Medications Management">
            <StyledSideMenuIconButton
              onClick={() => {
                navigate(`/tasks`);
              }}
              isSelected={pathnameIncludes('/tasks')}
            >
              <MedicationIcon />
            </StyledSideMenuIconButton>
          </Tooltip>
        )}
        {isRPMViewable && (
          <Tooltip title="Remote Patient Monitoring (RPM)">
            <StyledSideMenuIconButton
              onClick={() => {
                navigate(`/remote-patient-monitoring/my-patients`);
              }}
              isSelected={pathnameIncludes('/remote-patient-monitoring')}
            >
              <MonitorHeartIcon />
            </StyledSideMenuIconButton>
          </Tooltip>
        )}
        {isAnyAdmin && isTCMViewable && (
          <Tooltip title="Transitional Care Management">
            <StyledSideMenuIconButton
              onClick={() => {
                navigate(`/transitional-care-management/dashboard`);
              }}
              isSelected={pathnameIncludes('/transitional-care-management')}
            >
              <AssignmentIndIcon />
            </StyledSideMenuIconButton>
          </Tooltip>
        )}
        {isAnyAdmin && (
          <Tooltip title="Administration">
            <StyledSideMenuIconButton
              onClick={() => {
                navigate(`/reports/dashboard`);
              }}
              isSelected={pathnameIncludesAny(['/administration', '/reports/dashboard'])}
            >
              <AdminPanelSettingsIcon />
            </StyledSideMenuIconButton>
          </Tooltip>
        )}
        {!isAnyAdmin && isMTMViewable && (
          <Tooltip title="Administration">
            <StyledSideMenuIconButton
              onClick={() => {
                navigate(`/administration/timesheets`);
              }}
              isSelected={pathnameIncludesAny(['/administration', '/administration/timesheets'])}
            >
              <AdminPanelSettingsIcon />
            </StyledSideMenuIconButton>
          </Tooltip>
        )}
        {!isAnyAdmin && isRPMViewable && (
          <Tooltip title="Administration">
            <StyledSideMenuIconButton
              onClick={() => {
                navigate(`/administration/providers`);
              }}
              isSelected={pathnameIncludesAny(['/administration', '/administration/providers'])}
            >
              <AdminPanelSettingsIcon />
            </StyledSideMenuIconButton>
          </Tooltip>
        )}
      </Stack>
      {drawerOpen && <Box sx={{ overflow: 'auto' }}>{children}</Box>}
    </Stack>
  );
};
