import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { Medication } from '@/types/medication';

import { buildValues } from '@/util/medications';
import ArticleIcon from '@mui/icons-material/Article';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';

type Props = {
  medRecSnapshotDetail: Medication[];
  totalRowCount: number;
  currentPage: number;
  loading: boolean;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  switchToNewDialog: (medicationId: string, medicationTitle: string, note: string) => void;
};

const MedicationDetailsDataGrid = ({
  medRecSnapshotDetail,
  totalRowCount,
  currentPage,
  loading,
  setCurrentPage,
  switchToNewDialog
}: Props) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'medication',
        headerName: 'Medication Name',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params?.row?.displayName
      },
      {
        field: 'strength',
        headerName: 'Strength',
        minWidth: 110,
        sortable: true,
        flex: 0.5,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => buildValues(params?.row?.strength, true)
      },
      {
        field: 'dosage',
        headerName: 'Dosage',
        minWidth: 110,
        sortable: true,
        flex: 0.5,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => buildValues(params?.row?.dosage, true)
      },
      {
        field: 'instructions',
        headerName: 'SIG',
        sortable: false,
        filterable: false,
        minWidth: 110,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'prescriberName',
        headerName: 'Prescriber Name',
        sortable: false,
        filterable: false,
        minWidth: 110,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'note',
        headerName: 'Note',
        sortable: false,
        filterable: false,
        minWidth: 110,
        flex: 1.5,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          return (
            <Stack direction="row" width="100%" justifyContent="flex-end" alignItems="center">
              {params?.row?.note?.trim()?.length > 0 && (
                <Box flex={1} textOverflow="ellipsis" overflow="hidden">
                  {params?.row?.note}
                </Box>
              )}
              <IconButton color="primary" onClick={() => switchToNewDialog(params?.row?.id, params?.row?.displayName, params?.row?.note)}>
                {params?.row?.note?.trim()?.length > 0 ? <ArticleIcon color="success" /> : <NoteAddIcon />}
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    [switchToNewDialog]
  );
  return (
    <Paper elevation={0}>
      <TruentityDataGrid
        name={'dg-medication-details'}
        autoHeight
        rows={medRecSnapshotDetail}
        rowCount={totalRowCount}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
        columns={columns}
        disableRowSelectionOnClick={true}
        paginationMode="server"
        loading={loading}
      />
    </Paper>
  );
};

export default MedicationDetailsDataGrid;
