import Button from '@/components/Button';
import { TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import MedicationReconciliationDetailDialog from '@/components/Dialogs/MedicationReconciliationDetailDialog';
import { H3 } from '@/components/Typography';
import type { GetMedrecSnapshotsResponse } from '@/graphql/medication';
import { GET_MED_REC_SNAPSHOTS } from '@/graphql/medication';
import type { UpdateRpmSetupResponse } from '@/graphql/remotePatientMonitoring';
import { ASSIGN_MEDREC_TO_RPM_REPORT, UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import { theme } from '@/styles/mui-theme';
import type { MedrecSnapshot } from '@/types/dischargePatient';
import { RpmSetupStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { formatDateIgnoreTZ } from '@/util/format';
import { getSelectedTabData, updateRpmTabStatus } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useMutation, useQuery } from '@apollo/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const MedicalReconciliation = () => {
  const gridRef = useGridApiRef();
  const { showModal } = useModal();
  const { isReadOnly } = useRpmWorkflowStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { rpmSetupTabs, editRpmStatus } = useRpmSetupStore();
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const [medrecSnapshots, setMedRecSnapshots] = useState<MedrecSnapshot[]>([]);
  const { data: medRecSnapshotsData, refetch: medRecSnapshotsRefetch } = useQuery<GetMedrecSnapshotsResponse>(GET_MED_REC_SNAPSHOTS, {
    variables: {
      truentityId: id
    },
    fetchPolicy: 'network-only'
  });
  const [assignMedRecSnapshot, { data: assignMedRecSnapshotData }] = useMutation(ASSIGN_MEDREC_TO_RPM_REPORT);
  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const isMedicalReconciliationInProgress = rpmSetupTabs.some(
    tab => tab.type === RpmWorkflowTab.MEDICAL_RECONCILIATION && tab.status === RpmSetupStatusTypes.IN_PROGRESS
  );

  const showMedicationDialog = useCallback(
    (title: string, medrecSnapshotId: string) => {
      const modal = showModal(MedicationReconciliationDetailDialog, {
        title,
        hideDialog: () => {
          modal.hide();
        },
        id,
        medrecSnapshotId
      });
    },
    [id, showModal]
  );

  const showConfirmDialog = useCallback(
    (title: string, message: string, onAgree: () => void) => {
      const modal = showModal(ConfirmDialog, {
        title,
        message,
        onAgree: () => {
          modal.hide();
          onAgree();
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [showModal]
  );

  const assignMedRecSnapshotToRPMReport = useCallback(
    async (medrecSnapshotId: string, id: string | undefined) => {
      return await assignMedRecSnapshot({
        variables: {
          truentityId: id,
          medrecSnapshotId
        }
      });
    },
    [assignMedRecSnapshot]
  );

  const showAssignMedRecSnapshotToRpmReportConfirmation = useCallback(
    (medrecSnapshotId: string) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Assign Medication Reconciliation Snapshot',
        message: 'Are you sure you want to assign this Medication Reconciliation Snapshot?',
        onAgree: () => {
          assignMedRecSnapshotToRPMReport(medrecSnapshotId, id).finally(() => modal.hide());
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [assignMedRecSnapshotToRPMReport, id, showModal]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'medicationName',
        headerName: 'Name',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params?.row?.name
      },
      {
        field: 'performedDate',
        headerName: 'Performed On',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => formatDateIgnoreTZ(params?.row?.createdAt)
      },
      {
        field: 'performedBy',
        headerName: 'Performed By',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params?.row?.relyingPartyAdmin?.name
      },
      {
        field: 'details',
        headerName: 'Details',
        sortable: false,
        filterable: false,
        minWidth: 80,
        flex: 0.25,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <IconButton onClick={() => showMedicationDialog(params?.row?.name, params?.row?.id)}>
            <StickyNote2Icon color="primary" />
          </IconButton>
        )
      },
      {
        field: 'assign',
        headerName: 'Assign',
        sortable: false,
        filterable: false,
        minWidth: 80,
        flex: 0.25,
        align: 'center',
        headerAlign: 'center',
        renderCell: params =>
          params?.row.isMedRecAssignForRpmReport ? (
            <CheckCircleIcon color="success" />
          ) : (
            <IconButton onClick={() => showAssignMedRecSnapshotToRpmReportConfirmation(params?.row?.id)}>
              <ArrowForwardIcon />
            </IconButton>
          )
      }
    ],
    [showAssignMedRecSnapshotToRpmReportConfirmation, showMedicationDialog]
  );

  const checkCompletionEligibility = useCallback(
    (eligibility: boolean) => {
      const currentTab = getSelectedTabData(rpmSetupTabs, RpmWorkflowTab.MEDICAL_RECONCILIATION);
      if (currentTab && currentTab.canCurrentTabBeCompleted !== eligibility) {
        editRpmStatus({ ...currentTab, canCurrentTabBeCompleted: eligibility });
      }
    },
    [rpmSetupTabs, editRpmStatus]
  );

  useEffect(() => {
    const medSnapshots = medRecSnapshotsData?.medrecSnapshots?.medrecSnapshots ?? [];
    setMedRecSnapshots(medSnapshots);

    const isMedRecAssignForReport = medSnapshots.some(snapshot => snapshot.isMedRecAssignForRpmReport === true);

    checkCompletionEligibility(isMedRecAssignForReport);
  }, [medRecSnapshotsData?.medrecSnapshots?.medrecSnapshots, checkCompletionEligibility]);

  useEffect(() => {
    if (assignMedRecSnapshotData && assignMedRecSnapshotData.assignMedrecToRpmReport) {
      if (assignMedRecSnapshotData.assignMedrecToRpmReport.status === 'Success') {
        enqueueSnackbar(assignMedRecSnapshotData.assignMedrecToRpmReport.message, {
          variant: 'success'
        });
        medRecSnapshotsRefetch();
        if (isMedicalReconciliationInProgress) {
          showConfirmDialog('Confirm Action', 'Are you sure you want to mark as completed?', () => {
            updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.MEDICAL_RECONCILIATION, editRpmStatus, updateRpmSetup, true).catch(err =>
              console.error(err)
            );
          });
        }
      } else {
        enqueueSnackbar(assignMedRecSnapshotData.assignMedrecToRpmReport.message, {
          variant: 'error'
        });
      }
    }
  }, [assignMedRecSnapshotData, enqueueSnackbar]);

  return (
    <Paper component={Stack} sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }} elevation={0}>
      <Stack direction="row" p={1} pb={1.25} sx={{ justifyContent: 'space-between' }}>
        <H3>{'Medical Reconciliation'}</H3>

        <Stack direction="row" columnGap={1.25}>
          <Button
            variant={'contained'}
            disableElevation
            onClick={() => navigate(`/patients/${id}/details/medications/triage/list`)}
            disabled={isReadOnly}
          >
            Go to Medrec
          </Button>
        </Stack>
      </Stack>
      <Paper sx={{ height: 'auto', minHeight: '200px', width: '100%', backgroundColor: '#ffffff' }} elevation={0}>
        <TruentityDataGrid
          name={'dg-med-reconciliation'}
          apiRef={gridRef}
          autoHeight
          rows={medrecSnapshots}
          columns={columns}
          pagination={false}
          hideFooter
          hideFooterSelectedRowCount
          disabled={isReadOnly}
          disableRowSelectionOnClick={true}
          disableMultipleRowSelection={true}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
        />
      </Paper>
    </Paper>
  );
};

export default MedicalReconciliation;
