import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import { Body1 } from '@/components/Typography';
import type { GetPatientDetailResponse } from '@/graphql/account';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import type { GetTasksResponse } from '@/graphql/taskEncounter';
import { GET_TASKS, UPDATE_ENCOUNTER } from '@/graphql/taskEncounter';
import type { EncounterType, TaskType } from '@/types/graphql';
import { UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import { formatDateAndTime } from '@/util/format';
import { unknown } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useState } from 'react';
import BaseDialog from '../BaseDialog';
import type { EncounterDialogFormData } from './EncounterDialogForm';
import EncounterDialogForm from './EncounterDialogForm';

export const SelectedTaskListItem = ({ task }: { task: TaskType }) => {
  return (
    <Stack
      sx={{
        marginBottom: '1px',
        marginRight: '2px',
        marginLeft: '2px',
        padding: '4px',
        border: '1px solid #aeaeb4',
        borderRadius: '5px'
      }}
    >
      <Body1 fontWeight="strong">{task.type}</Body1>
      <Body1>
        {task.subType
          ? UPDATED_SUB_TASK_TYPES.filter(type => type.value === task.subType).length > 0
            ? UPDATED_SUB_TASK_TYPES.filter(type => type.value === task.subType)[0].label
            : ''
          : unknown()}
      </Body1>
      <Body1>{task?.medicationName || unknown()}</Body1>
      <Body1>{task?.createdAt ? formatDateAndTime(task?.createdAt) : unknown()}</Body1>
    </Stack>
  );
};

export type EncounterDialogFormState = Partial<EncounterType>;

type Props = BaseDialogProps & {
  id: string;
  encounter: EncounterDialogFormState;
  hideDialog: () => void;
  onComplete: () => void;
  showTasks: boolean;
};

const EditEncounterDialog = ({ id, title, hideDialog, encounter, onComplete, showTasks, ...props }: Props): React.ReactElement => {
  const { data: getInfoData, error: getInfoError } = useQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL, {
    variables: {
      truentityId: id
    }
  });

  const { data: getTasksData, error: getTasksError } = useQuery<GetTasksResponse>(GET_TASKS, {
    variables: {
      truentityId: id,
      pageNum: 1, //TODO:  This is not going to work if the number of tasks becomes too big
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        status: ['PENDING']
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  //FIXME: updateEncounter is not updating selected tasks if the api was already returning it
  const [updateEncounter] = useMutation(UPDATE_ENCOUNTER);
  const [tasksFromAPI, setTasksFromAPI] = useState<TaskType[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (getTasksData && getTasksData.tasks) {
      setTasksFromAPI(getTasksData.tasks.tasks);
    }
  }, [getTasksData]);

  useEffect(() => {
    if (getTasksError) {
      console.error(getTasksError?.message);
      enqueueSnackbar('Unable to retrieve tasks', { variant: 'error' });
    }
  }, [enqueueSnackbar, getTasksError]);

  useEffect(() => {
    if (getInfoError) {
      console.error(getInfoError?.message);
      enqueueSnackbar('Unable to retrieve information', { variant: 'error' });
    }
  }, [enqueueSnackbar, getInfoError]);

  const onSubmit = (formData: EncounterDialogFormData) => {
    const { followUpOn, followUpType, followUpSubType, followUpNote, ...encounterData } = formData;

    updateEncounter({
      variables: {
        truentityId: id,
        encounterData,
        encounterId: encounter?.id
      }
    })
      .then(() => {
        enqueueSnackbar('Encounter saved', { variant: 'success' });
        onComplete();
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Unable to save encounter', { variant: 'error' });
      });
  };

  return (
    <BaseDialog hideDialog={hideDialog} maxWidth={'md'} fullWidth {...props} title={title}>
      <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
        <EncounterDialogForm
          onSubmit={onSubmit}
          onReset={hideDialog}
          encounter={encounter}
          showTasks={showTasks}
          tasksFromAPI={tasksFromAPI}
          isEditForm={true}
          getInfoData={getInfoData}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default EditEncounterDialog;
