import { RpmWorkflowTab } from '@/types/remotePatientMonitoring';

export const KeyNames = {
  Down: 'ArrowDown',
  Enter: 'Enter',
  Escape: 'Escape',
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Space: ' ',
  Up: 'ArrowUp'
};

export const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const mandatoryTabsForEnrolment: string[] = [
  RpmWorkflowTab.CARE_PLAN,
  RpmWorkflowTab.CLINICAL_SUMMARY,
  RpmWorkflowTab.DEVICES,
  RpmWorkflowTab.CONSENT_MANAGEMENT
];

export const patientTemporaryAccessUrlPaths = '/p/${company}/rpm';
