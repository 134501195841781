import AddIcon from '@mui/icons-material/Add';
import { useModal } from 'mui-modal-provider';
import Button from '../Button';
import AddExistingUserDialog from '@/components/Dialogs/AddExistingUserDialog';

const ExistingUserAdd = () => {
  const { showModal } = useModal();

  const showExistingUserDialog = () => {
    const modal = showModal(AddExistingUserDialog, {
      title: 'Add Existing User',
      hideDialog: () => {
        modal.hide();
      },
      onExistingUserAdd: () => {
        modal.hide();
      }
    });
  };

  return <Button startIcon={<AddIcon />} label="Add Existing User" onClick={() => showExistingUserDialog()} />;
};

export default ExistingUserAdd;
