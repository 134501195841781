import { ChecklistByCategoryType } from '@/types/checklists';
import { gql } from '@apollo/client';

export type GetRelyingPartyAdminsChecklistResponse = {
  getRelyingPartyAdminChecklists: {
    allChecklistCount: number;
    totalCompletedCount: number;
    totalPendingCount: number;
    totalMandatoryCount: number;
    completedMandatoryCount: number;
    checklistsByCategory: ChecklistByCategoryType[];
  };
};

export const GET_RELYING_PARTY_ADMIN_CHECKLISTS = gql`
  query GetRelyingPartyAdminChecklistsQuery {
    getRelyingPartyAdminChecklists {
      allChecklistCount
      totalCompletedCount
      totalPendingCount
      totalMandatoryCount
      completedMandatoryCount
      checklistsByCategory {
        categoryName
        totalChecklistCount
        totalPendingCount
        totalCompletedCount
        totalMandatoryCount
        completedMandatoryCount
        checklists {
          id
          checklistItem {
            id
            name
            category
            description
            medicalService {
              shortName
            }
            resourceS3Key
            convertedS3Url
            resourceMimeType
            isMandatory
            orderNum
          }
          checkedOn
          notes
        }
      }
    }
  }
`;

export const UPDATE_RELYING_PARTY_ADMIN_CHECKLIST = gql`
  mutation UpdateRelyingPartyAdminChecklist($relyingPartyAdminChecklistId: ID!, $isChecked: Boolean, $notes: String) {
    updateRelyingPartyAdminChecklist(relyingPartyAdminChecklistId: $relyingPartyAdminChecklistId, isChecked: $isChecked, notes: $notes) {
      message
      status
    }
  }
`;

export const CREATE_CHECKLISTS_FOR_RELYING_PARTY_ADMIN = gql`
  mutation CreateChecklistsForRelyingPartyAdmin {
    createChecklistsForRelyingPartyAdmin {
      message
      status
    }
  }
`;
