import AutoSave from '@/components/AutoSave';
import Button from '@/components/Button';
import { H4 } from '@/components/Typography';
import {
  GetAccountAlertLimitsResponse,
  GetRelyingPartyAlertLimitsResponse,
  GET_ACCOUNT_ALERT_LIMITS,
  GET_RELYING_PARTY_ALERT_LIMITS,
  UpdateAccountAlertLimitsResponse,
  UPDATE_ACCOUNT_ALERT_LIMITS
} from '@/graphql/remotePatientMonitoring';
import AlertLimitsMarkersSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/AlertLimitsMarkersSection';
import AlertLimitsSection from '@/routes/PatientDetails/RemotePatientMonitoring/Components/AlertLimits/AlertLimitsSection';
import { ClinicalRangesFormValues } from '@/types/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getAlertLimitsObject, setAlertLimits } from '@/util/rpm';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Divider, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export const ClinicalRangesConfiguration: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const defaultAlertLimits: ClinicalRangesFormValues = useMemo(
    () => ({
      heartRateLimits: [0, 0, 0, 0],
      bloodGlucoseLimits: [0, 0, 0, 0],
      sysBloodPressureLimits: [0, 0, 0, 0],
      diaBloodPressureLimits: [0, 0, 0, 0]
    }),
    []
  );
  const methods = useForm<ClinicalRangesFormValues>({ defaultValues: defaultAlertLimits, mode: 'onBlur' });
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { control, setValue, watch, handleSubmit } = methods;
  const onSubmit: SubmitHandler<ClinicalRangesFormValues> = data => handleAlertLimitsSave(data);

  const heartRateLimits = watch('heartRateLimits');
  const bloodGlucoseLimits = watch('bloodGlucoseLimits');
  const sysBloodPressureLimits = watch('sysBloodPressureLimits');
  const diaBloodPressureLimits = watch('diaBloodPressureLimits');

  const [updateAccountAlertLimits, { data: updateAccountAlertLimitsData, error: updateAccountAlertLimitsError }] =
    useMutation<UpdateAccountAlertLimitsResponse>(UPDATE_ACCOUNT_ALERT_LIMITS);
  const [getAccountAlertLimits, { data: accountAlertLimitsData, loading: loadingAlertLimits }] =
    useLazyQuery<GetAccountAlertLimitsResponse>(GET_ACCOUNT_ALERT_LIMITS, {
      variables: {
        truentityId: id
      },
      fetchPolicy: 'cache-and-network'
    });
  const [getCompanyAlertLimits, { data: companyAlertLimitsData, loading: loadingCompanyAlertLimits }] =
    useLazyQuery<GetRelyingPartyAlertLimitsResponse>(GET_RELYING_PARTY_ALERT_LIMITS, {
      variables: {
        relyingPartyId: currentUser?.relyingParty?.id
      },
      fetchPolicy: 'cache-and-network'
    });

  const handleAlertLimitsSave = async (values: ClinicalRangesFormValues) => {
    const alertLimitsInput = {
      heartRate: getAlertLimitsObject(values.heartRateLimits),
      bloodGlucose: getAlertLimitsObject(values.bloodGlucoseLimits),
      sysBloodPressure: getAlertLimitsObject(values.sysBloodPressureLimits),
      diaBloodPressure: getAlertLimitsObject(values.diaBloodPressureLimits)
    };
    await updateAccountAlertLimits({
      variables: {
        truentityId: id,
        alertLimitsInput: alertLimitsInput
      }
    });
  };

  const handleResetToDefault = () => {
    if (companyAlertLimitsData?.getRelyingPartyAlertLimitsConfigurations) {
      setAlertLimits(companyAlertLimitsData?.getRelyingPartyAlertLimitsConfigurations, setValue);
      if (!methods?.formState?.isDirty) {
        console.log('Save alert limits manually');
        handleSubmit(onSubmit)();
      }
    }
  };

  useEffect(() => {
    if (accountAlertLimitsData?.getAccountAlertLimitsConfigurations) {
      setAlertLimits(accountAlertLimitsData?.getAccountAlertLimitsConfigurations, setValue);
    }
  }, [accountAlertLimitsData, setValue]);

  useEffect(() => {
    if (updateAccountAlertLimitsData && updateAccountAlertLimitsData?.updateAccountAlertLimits?.status === 'Success') {
      enqueueSnackbar('Patient alert limits updated', { variant: 'success', preventDuplicate: true });
    } else if (updateAccountAlertLimitsData && updateAccountAlertLimitsData?.updateAccountAlertLimits?.status === 'Failure') {
      enqueueSnackbar('Could not update patient alert limits', { variant: 'error' });
    }
  }, [updateAccountAlertLimitsData]);

  useEffect(() => {
    if (updateAccountAlertLimitsError) {
      enqueueSnackbar('Could not update patient alert limits', { variant: 'error' });
    }
  }, [updateAccountAlertLimitsError]);

  useEffect(() => {
    if (id) {
      getAccountAlertLimits({
        variables: {
          truentityId: id
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (currentUser) {
      getCompanyAlertLimits({
        variables: {
          relyingPartyId: currentUser?.relyingParty?.id
        }
      });
    }
  }, [currentUser]);

  return (
    <FormProvider {...methods}>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          backgroundColor: 'background.default'
        }}
      >
        <AlertLimitsMarkersSection showDivider={true} />
        <H4
          sx={{
            mt: 2
          }}
        >
          Blood Pressure
        </H4>
        <AlertLimitsSection
          label="Systolic"
          unit={'mmHg'}
          minValue={40}
          maxValue={190}
          name={'sysBloodPressureLimits'}
          control={control}
          values={sysBloodPressureLimits}
          disabled={loadingAlertLimits}
        />
        <AlertLimitsSection
          label="Diastolic"
          unit={'mmHg'}
          minValue={10}
          maxValue={140}
          name={'diaBloodPressureLimits'}
          control={control}
          values={diaBloodPressureLimits}
          disabled={loadingAlertLimits}
        />
        <Divider
          sx={{
            width: '100%'
          }}
        />
        <AlertLimitsSection
          label="Heart Rate"
          unit={'bpm'}
          minValue={0}
          maxValue={160}
          name={'heartRateLimits'}
          control={control}
          values={heartRateLimits}
          disabled={loadingAlertLimits}
        />
        <Divider
          sx={{
            width: '100%'
          }}
        />
        <AlertLimitsSection
          label="Blood Glucose"
          unit={'mg/dl'}
          minValue={30}
          maxValue={240}
          name={'bloodGlucoseLimits'}
          control={control}
          values={bloodGlucoseLimits}
          disabled={loadingAlertLimits}
        />
        <AutoSave defaultValues={defaultAlertLimits} onSubmit={onSubmit} />
        <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button
            type="button"
            variant="contained"
            isLoading={loadingCompanyAlertLimits}
            label={'Reset to Default'}
            onClick={handleResetToDefault}
            size="medium"
          />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default ClinicalRangesConfiguration;
