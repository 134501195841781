import AutoSave from '@/components/AutoSave';
import Button from '@/components/Button';
import { PdfDialog } from '@/components/Dialogs';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { H3, H5 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { GetRpmConsentResponse, UpdateRpmSetupResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_CONSENT, UPDATE_RPM_CONSENT, UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { RpmSetupStatusTypes, RpmStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { toTruentityDateInput } from '@/util/date';
import { formatDate } from '@/util/format';
import { areAllFieldsFilled, updateRpmTabStatus } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { Box, Checkbox, FormControl, FormControlLabel, Stack } from '@mui/material';
import { Moment } from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import BaseRpmWorkflowTabContent from './BaseRpmWorkflowTabContent';

type FormValues = {
  participateInTheRPMProgram: boolean;
  sharingDataWithProviders: boolean;
  jointCollaborationWithIndycare: boolean;
  sharingMonthlyDataReportWithProvider: boolean;
  canCancelAnyTime: boolean;
  programDescriptionAccepted: boolean;
  returnTheDevicesIfCancelled: boolean;
  consentObtainedOn: Moment | null | string;
};

const defaultValues: FormValues = {
  participateInTheRPMProgram: false,
  sharingDataWithProviders: false,
  jointCollaborationWithIndycare: false,
  sharingMonthlyDataReportWithProvider: false,
  canCancelAnyTime: false,
  returnTheDevicesIfCancelled: false,
  programDescriptionAccepted: false,
  consentObtainedOn: ''
};

const ConsentManagement = () => {
  const { isReadOnly } = useRpmWorkflowStore();
  const { patientInfo } = useContext(PatientDetailContext);
  const { enqueueSnackbar } = useSnackbar();
  const { rpmSetupTabs, editRpmStatus } = useRpmSetupStore();

  const { id } = useParams();
  const { showModal } = useModal();

  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [carePlanReportUrl, setCarePlanReportUrl] = useState<string>('');
  const [consentObtainedByName, setConsentObtainedByName] = useState<string>('');
  const [defaultValue, setDefaultValue] = useState<Moment | null>(null);

  const isEnrolled = patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED;

  const [getRpmConsent, { data: rpmConsentData }] = useLazyQuery<GetRpmConsentResponse>(GET_RPM_CONSENT, {
    fetchPolicy: 'cache-and-network'
  });
  const [updateRpmConsent] = useMutation(UPDATE_RPM_CONSENT);
  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, reset, setValue } = methods;

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      await updateRpmConsent({
        variables: {
          truentityId: id,
          rpmParticipation: values.participateInTheRPMProgram,
          rpmShareData: values.sharingDataWithProviders,
          jointCollaborationWithIndycare: values.jointCollaborationWithIndycare,
          rpmMonthlyReportSharing: values.sharingMonthlyDataReportWithProvider,
          rpmCanCancelAnytime: values.canCancelAnyTime,
          rpmWillReturnDevicesOnCancellation: values.returnTheDevicesIfCancelled,
          rpmProgramDescriptionAccepted: values.programDescriptionAccepted,
          rpmObtainedOn: formatDate(values.consentObtainedOn, 'YYYY-MM-DD')
        }
      });
      enqueueSnackbar('Successfully updated consent', { variant: 'success' });
      resetPatientConsent();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
  };

  const getCurrentUserName = useCallback(() => {
    if (currentUser?.relyingParty) {
      const firstName = currentUser.user?.firstName || '';
      const lastName = currentUser.user?.lastName || '';
      return firstName + ' ' + lastName;
    }
    return '';
  }, [currentUser]);

  const resetPatientConsent = useCallback(() => {
    if (rpmConsentData && rpmConsentData?.getRpmConsent) {
      const consent = rpmConsentData?.getRpmConsent;
      const obtainedByUser = consent?.rpmObtainedBy?.user;

      const consentObtainedByName = obtainedByUser ? getAccountUserFullName(obtainedByUser) : getCurrentUserName();

      const patientConsent: FormValues = {
        participateInTheRPMProgram: consent?.rpmParticipation || false,
        sharingDataWithProviders: consent?.rpmShareData || false,
        jointCollaborationWithIndycare: consent?.jointCollaborationWithIndycare || false,
        sharingMonthlyDataReportWithProvider: consent?.rpmMonthlyReportSharing || false,
        returnTheDevicesIfCancelled: consent?.rpmWillReturnDevicesOnCancellation || false,
        canCancelAnyTime: consent?.rpmCanCancelAnytime || false,
        programDescriptionAccepted: consent?.rpmProgramDescriptionAccepted || false,
        consentObtainedOn: toTruentityDateInput(consent?.rpmObtainedOn) || null
      };

      setConsentObtainedByName(consentObtainedByName || getCurrentUserName());
      setDefaultValue(toTruentityDateInput(consent?.rpmObtainedOn) ?? null);

      reset(patientConsent);
      if (areAllFieldsFilled(patientConsent as Record<string, string | boolean | Moment>)) {
        updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CONSENT_MANAGEMENT, editRpmStatus, updateRpmSetup, true).catch(err =>
          console.error(err)
        );
      } else {
        updateRpmTabStatus(
          rpmSetupTabs,
          RpmWorkflowTab.CONSENT_MANAGEMENT,
          editRpmStatus,
          updateRpmSetup,
          true,
          RpmSetupStatusTypes.IN_PROGRESS
        ).catch(err => console.error(err));
      }
    } else {
      setConsentObtainedByName(getCurrentUserName());
    }
  }, [reset, rpmConsentData, getCurrentUserName]);

  const fetchDocumentPreview = () => {
    try {
      if (carePlanReportUrl) {
        const modal = showModal(PdfDialog, {
          title: 'Program Description Document Preview',
          pdfUrl: carePlanReportUrl,
          hideDialog: () => {
            modal.hide();
          }
        });
      } else {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    } catch (err) {
      enqueueSnackbar('Failed to load document preview', { variant: 'error' });
    }
  };

  useEffect(() => {
    getRpmConsent({
      variables: {
        truentityId: id
      }
    }).catch(err => console.error(err));
  }, [getRpmConsent, id]);

  useEffect(() => {
    resetPatientConsent();
  }, [resetPatientConsent]);

  useEffect(() => {
    if (currentUser) {
      try {
        const settingsString = currentUser.relyingParty?.settings;
        const settingsObject = settingsString ? JSON.parse(settingsString) : {};
        const currentRelyingPartyCarePlanReportUrl = settingsObject.CarePlanUrl;

        if (currentRelyingPartyCarePlanReportUrl) {
          setCarePlanReportUrl(currentRelyingPartyCarePlanReportUrl);
        }
      } catch (error) {
        console.error('Error occurred while parsing user settings:', error);
      }
    }
  }, [currentUser]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <FormProvider {...methods}>
          <Stack component="form" spacing={3}>
            <H3>Consent Management</H3>
            <FormControl component="fieldset" variant="standard" sx={{ gap: theme.spacing(2) }}>
              <Stack
                sx={{
                  backgroundColor: color.grey50,
                  p: 1,
                  border: '1px solid',
                  borderColor: theme => theme.palette.divider,
                  borderRadius: 1,
                  width: '100%'
                }}
              >
                <Stack
                  sx={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    mb: 1
                  }}
                  direction="row"
                >
                  <Box
                    sx={{ width: '55px' }}
                    component={'img'}
                    src={'https://truentity-general.s3.amazonaws.com/Indycare-RPM-Program-1.jpg'}
                  />
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: color.black100,
                      flex: 1,
                      textAlign: 'left',
                      marginLeft: '16px'
                    }}
                  >
                    Program Description
                  </H5>
                  <Button sx={{ width: '86px' }} disabled={isEnrolled} label="View" onClick={fetchDocumentPreview} />
                </Stack>

                <Stack direction={'row'} justifyContent={'space-evenly'}>
                  <Stack sx={{ width: '100%', marginRight: 1 }}>
                    <Controller
                      name="programDescriptionAccepted"
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                          label="This Program Description was explained to the patient (or to the patient's care provider), and that they understand the goals of this program"
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack direction={'row'} justifyContent={'space-evenly'}>
                <Stack sx={{ width: '50%', marginRight: 1 }}>
                  <Controller
                    control={control}
                    name="participateInTheRPMProgram"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                        label="Participate in RPM Program"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="jointCollaborationWithIndycare"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                        label="Joint collaboration W/Indycare and role of supervising Physician"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="sharingDataWithProviders"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                        label="Sharing data with providers"
                      />
                    )}
                  />
                </Stack>
                <Stack sx={{ width: '50%', marginLeft: 1 }}>
                  <Controller
                    control={control}
                    name="sharingMonthlyDataReportWithProvider"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                        label="Sharing monthly data report with provider"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="canCancelAnyTime"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                        label="Can cancel any time"
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name="returnTheDevicesIfCancelled"
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <FormControlLabel
                        control={<Checkbox disabled={isEnrolled} checked={value} onChange={onChange} />}
                        label={'Return the device(s) if cancelled'}
                      />
                    )}
                  />
                </Stack>
              </Stack>
              <Stack direction={'row'} justifyContent={'space-evenly'}>
                <Controller
                  control={control}
                  name="consentObtainedOn"
                  render={({ field: { onChange, value } }) => (
                    <TruentityDatePicker
                      disabled={isEnrolled}
                      sx={{ width: '50%', marginRight: 1 }}
                      TextFieldProps={{ required: false }}
                      onChange={onChange}
                      value={value}
                      defaultValue={defaultValue}
                      label={'Consent obtained on'}
                    />
                  )}
                />

                <TruentityTextField
                  sx={{ width: '50%', marginLeft: 1 }}
                  value={consentObtainedByName}
                  disabled
                  label={'Consent obtained by'}
                />
              </Stack>
            </FormControl>
          </Stack>
          <AutoSave defaultValues={defaultValues} onSubmit={onSubmit} />
        </FormProvider>
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default ConsentManagement;
