import Button from '@/components/Button';
import { SMALL_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import UpdateAccountActivityDialog from '@/components/Dialogs/UpdateAccountActivityDialog';
import { RpmAlertsListToolbar } from '@/components/RpmAlerts/RpmAlertsListToolbar';
import { RpmAlertTypesFiltersSection } from '@/components/RpmAlerts/RpmAlertTypesFiltersSection';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1 } from '@/components/Typography';
import type { GetPatientDetailResponse } from '@/graphql/account';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import type { GetRpmAlertsCountResponse, GetRpmAlertsResponse, UpdateRpmAlertsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_ALERTS, GET_RPM_ALERTS_COUNT, UPDATE_RPM_ALERTS } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import type { PatientsData } from '@/routes/Patients/patients';
import RpmPatientInfoDrawer from '@/routes/RemotePatientMonitoring/RpmPatientDrawer';
import { Role } from '@/types/admin';
import { CustomTimezone } from '@/types/date';
import type { RPMAccountAlert, RpmAlertsTabType } from '@/types/remotePatientMonitoring';
import { RpmAlertsLabelTypes, RpmAlertTabEnum, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { getGeneralTimezone } from '@/util/date';
import { formatDate, formatDateAndTime, formatDateToTimezone } from '@/util/format';
import { getAlertTypeStyles, RpmAlertsReadStatus } from '@/util/rpm';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GradingIcon from '@mui/icons-material/Grading';
import { Chip, Paper, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import type {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowId,
  GridSortModel
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const rpmAlertsTabs: RpmAlertsTabType[] = [
  {
    value: RpmAlertTabEnum.PATIENTS,
    label: 'Patients',
    path: 'patients',
    columnVisibility: {
      name: true,
      phone: true,
      shortDescription: true,
      label: true,
      reading: true,
      isRead: true,
      recordedAt: true,
      lastReadingAt: true
    },
    defaultAlertTypes: {
      [RpmAlertsLabelTypes.CRITICALLY_HIGH]: false,
      [RpmAlertsLabelTypes.HIGH]: false,
      [RpmAlertsLabelTypes.LOW]: false,
      [RpmAlertsLabelTypes.CRITICALLY_LOW]: false,
      [RpmAlertsLabelTypes.RPM_SETUP]: false,
      [RpmAlertsLabelTypes.NO_READINGS]: false,
      [RpmAlertsLabelTypes.PROVIDER_CONFIG]: false,
      [RpmAlertsLabelTypes.MED_REGIMEN]: false
    }
  },
  {
    value: RpmAlertTabEnum.REPORTS,
    label: 'Reports',
    path: 'reports',
    columnVisibility: {
      name: false,
      phone: false,
      shortDescription: true,
      label: true,
      reading: false,
      isRead: true,
      recordedAt: true,
      lastReadingAt: false
    },
    defaultAlertTypes: {
      [RpmAlertsLabelTypes.REPORT_FAX_FAILED]: false
    }
  }
];

export const RpmAlertsList = () => {
  const { id: truentityId } = useParams();
  const { roleType } = useToken();
  const navigate = useNavigate();

  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);
  const isInPatientContext = useMemo(() => !!truentityId, [truentityId]);

  const [organization, setOrganization] = useState<string>('');
  const [readStatus, setReadStatus] = useState<string>(RpmAlertsReadStatus.UNREAD);
  const [rpmStatus, setRpmStatus] = useState<string>(RpmStatusTypes.ENROLLED);
  const [selectedPatient, setSelectedPatient] = useState<PatientsData>({} as PatientsData);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'recordedAt', sort: 'desc' }]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [columnVisibility, setColumnVisibility] = useState<GridColumnVisibilityModel>(rpmAlertsTabs[0].columnVisibility);
  const [alertTypes, setAlertTypes] = useState<{ [key: string]: boolean }>(rpmAlertsTabs[0].defaultAlertTypes);
  const [getPatientDetail] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL);

  const { showModal } = useModal();

  const {
    data: rpmAlerts,
    loading: rpmAlertsLoading,
    refetch: refetchRpmAlerts,
    error: rpmAlertsError
  } = useQuery<GetRpmAlertsResponse>(GET_RPM_ALERTS, {
    variables: {
      ...(isInPatientContext ? { truentityId: truentityId } : {}),
      pageNum: currentPage + 1,
      pageSize: SMALL_PAGE_SIZE,
      alertGenericType: rpmAlertsTabs[currentTab].value,
      filterOptions: {
        // filterModel,
        // sortModel,
        organization: organization,
        readStatus: readStatus,
        rpmStatus: rpmStatus,
        alertTypes: Object.keys(alertTypes).filter(key => alertTypes[key])
      }
    },
    fetchPolicy: 'cache-and-network',
    skip: !organization
  });

  const {
    data: rpmAlertsCount,
    loading: rpmAlertsCountLoading,
    refetch: refetchRpmAlertsCount,
    error: rpmAlertsCountError
  } = useQuery<GetRpmAlertsCountResponse>(GET_RPM_ALERTS_COUNT, {
    variables: {
      ...(!!truentityId ? { truentityId: truentityId } : {}),
      filterOptions: {
        organization: organization,
        readStatus: readStatus,
        rpmStatus: rpmStatus
      }
    },
    fetchPolicy: 'cache-and-network',
    skip: !organization
  });

  const [updateRpmAlerts, { data: updateRpmAlertsData, error: updateRpmAlertsError }] =
    useMutation<UpdateRpmAlertsResponse>(UPDATE_RPM_ALERTS);

  const onRpmAlertsUpdated = async () => {
    try {
      await refetchRpmAlertsCount({
        filterOptions: {
          organization: organization,
          readStatus: readStatus,
          rpmStatus: rpmStatus
        }
      });
      await refetchRpmAlerts({
        organization: organization,
        readStatus: readStatus,
        alertTypes: Object.keys(alertTypes).filter(key => alertTypes[key])
      });
    } catch (err) {
      enqueueSnackbar('Could not retrieve RPM Alert(s).', { variant: 'error' });
    }
  };

  const onSelectPatient = useCallback(
    async (data: RPMAccountAlert) => {
      if (data?.account?.truentityId) {
        try {
          const patientResponse = await getPatientDetail({
            variables: {
              truentityId: data?.account?.truentityId
            }
          });

          if (!patientResponse.data?.accountGet) {
            throw Error('Unable to find patient');
          } else {
            setSelectedPatient({ ...patientResponse.data?.accountGet });
          }
        } catch (err) {
          console.error(err);
          enqueueSnackbar('Unable to retrieve patient data', {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, getPatientDetail]
  );

  const renderAlertLabelChip = useCallback(
    (value: string) => {
      const alertTypeStyles = getAlertTypeStyles(value);

      if (alertTypeStyles?.labelColor) {
        return (
          <Chip
            icon={
              <ErrorOutlineRounded
                sx={{
                  color: `${alertTypeStyles?.labelColor} !important`
                }}
              />
            }
            variant="outlined"
            label={alertTypeStyles?.labelText}
            sx={{
              borderColor: alertTypeStyles?.labelColor,
              color: alertTypeStyles?.labelColor
            }}
          />
        );
      }
    },
    [getAlertTypeStyles]
  );

  function shouldUpdateRpmAlert(rowLabel: RpmAlertsLabelTypes) {
    const disallowedLabels = [
      RpmAlertsLabelTypes.MED_REGIMEN,
      RpmAlertsLabelTypes.PROVIDER_CONFIG,
      RpmAlertsLabelTypes.PATIENT_VITAL_ACCESS_BLOCKED
    ];
    return !disallowedLabels.includes(rowLabel);
  }

  const onPatientNameClick = useCallback(
    row => {
      if (shouldUpdateRpmAlert(row?.label as RpmAlertsLabelTypes)) {
        updateRpmAlerts({
          variables: {
            alertIds: [row?.id.toString()],
            input: {
              isRead: true
            }
          }
        }).catch(console.error);
      }
      if (!isInPatientContext) navigate(`../../patients/${row?.account?.truentityId}/details/rpm/readings`);
    },
    [isInPatientContext]
  );

  const showAddActivityDialog = (row: RPMAccountAlert) => {
    const careActivity = row.careActivity;
    const rpmEnrolledAt = formatDateToTimezone({
      date: row.account.rpmEnrolledAt,
      format: 'YYYY-MM-DD HH:mm:ss',
      timezone: getGeneralTimezone(CustomTimezone.ADMIN_TIMEZONE)
    });
    const rpmStatus = row.account.rpmStatus;

    if (!careActivity) {
      enqueueSnackbar('Please update the medication regimen activity manually as it cannot be found automatically.', {
        variant: 'warning'
      });
    }

    const modal = showModal(UpdateAccountActivityDialog, {
      title: 'Update Activity',
      hideDialog: () => {
        modal.hide();
      },
      onActivitiesChanged: async () => {
        await updateRpmAlerts({
          variables: {
            alertIds: [row?.id.toString()],
            input: {
              isRead: true
            }
          }
        });
        await onRpmAlertsUpdated();
      },
      id: row.account.truentityId,
      data: careActivity,
      rpmEnrolledAtDate: moment(rpmEnrolledAt, 'YYYY-MM-DD HH:mm:ss'),
      rpmStatus: rpmStatus
    });
  };

  const columns: GridColDef<RPMAccountAlert>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Patient Name',
        sortable: true,
        valueGetter: params => getAccountUserFullName(params?.row?.account?.user),
        renderCell: params => {
          return (
            <Button
              variant={'text'}
              onClick={() => onPatientNameClick(params.row)}
              label={params.value}
              endIcon={
                params.row?.label === RpmAlertsLabelTypes.MED_REGIMEN ? (
                  <Tooltip
                    title={
                      params.row.careActivity
                        ? 'Review'
                        : 'Please update the medication regimen activity manually as it cannot be found automatically.'
                    }
                  >
                    <GradingIcon
                      {...(params.row.careActivity
                        ? {
                            onClick: e => {
                              e.preventDefault();
                              e.stopPropagation();
                              showAddActivityDialog(params.row);
                            }
                          }
                        : {})}
                      {...(!params.row.careActivity ? { color: 'disabled' } : {})}
                    />
                  </Tooltip>
                ) : null
              }
            />
          );
        },
        flex: 1,
        align: 'left'
      },
      {
        field: 'phone',
        headerName: 'Phone Number',
        valueGetter: params =>
          params?.row?.account?.phone?.startsWith('1') ? `+${params?.row?.account?.phone}` : params?.row?.account?.phone ?? '-',
        type: 'string',
        sortable: true,
        flex: 1,
        renderCell: params => <TruentityPhoneNumber value={params.value} />
      },
      {
        field: 'shortDescription',
        headerName: 'Description',
        renderCell: params => (
          <Stack direction="row" spacing={0} justifyContent="flex-start" alignItems="center">
            {params.row.shortDescription}
          </Stack>
        ),
        sortable: false,
        flex: 2
      },
      {
        field: 'label',
        headerName: 'Type',
        renderCell: params => {
          if (params.value) {
            return renderAlertLabelChip(params.value);
          } else {
            return <span>-</span>;
          }
        },
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 1
      },
      {
        field: 'reading',
        headerName: 'Reading',
        type: 'string',
        sortable: true,
        valueGetter: params => (params.row.reading === '' ? '-' : params.row.reading),
        flex: 1
      },
      {
        field: 'isRead',
        headerName: 'Read Status',
        type: 'boolean',
        renderCell: params => params.value && <CheckCircleIcon color="success" />,
        sortable: true,
        flex: 1
      },
      {
        field: 'recordedAt',
        headerName: 'Recorded At',
        type: 'string',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          if (params?.row?.recordedAt) {
            return params?.row?.recordedAt;
          } else {
            return params?.row?.createdAt;
          }
        },
        valueFormatter: params => formatDateAndTime(params?.value)
      },
      {
        field: 'lastReadingAt',
        headerName: 'Last Reading Date',
        type: 'string',
        sortable: true,
        flex: 1,
        valueGetter: params => {
          return formatDate(params?.row?.lastReadingAt);
        }
      }
    ],
    [renderAlertLabelChip, updateRpmAlerts]
  );

  const handleRowClick = (param: GridCellParams) => {
    if (!['isRead', '__check__'].includes(param?.field)) {
      onSelectPatient(param.row as RPMAccountAlert);
    }
  };

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, selectedTabIndex: number): void => {
      setCurrentTab(selectedTabIndex);
      setColumnVisibility(rpmAlertsTabs[selectedTabIndex].columnVisibility);
      setCurrentPage(0);
      setAlertTypes(rpmAlertsTabs[selectedTabIndex].defaultAlertTypes);
    },
    [rpmAlertsTabs]
  );

  useEffect(() => {
    if (updateRpmAlertsData) {
      if (updateRpmAlertsData?.updateRpmAlerts?.status === 'Success') {
        onRpmAlertsUpdated();
        enqueueSnackbar('RPM Alert(s) Updated Successfully.', { variant: 'success' });
      } else if (updateRpmAlertsData?.updateRpmAlerts?.status === 'Failure') {
        enqueueSnackbar(
          updateRpmAlertsData?.updateRpmAlerts?.errors?.length > 0
            ? updateRpmAlertsData?.updateRpmAlerts?.errors[0]
            : 'Could not update RPM Alert(s).',
          {
            variant: 'error'
          }
        );
      }
    }
  }, [updateRpmAlertsData]);

  useEffect(() => {
    if (updateRpmAlertsError) {
      enqueueSnackbar('Could not update RPM Alert(s).', { variant: 'error' });
    }
  }, [updateRpmAlertsError]);

  useEffect(() => {
    if (rpmAlertsError || rpmAlertsCountError) {
      enqueueSnackbar('Could not retrieve RPM Alert(s).', { variant: 'error' });
    }
  }, [rpmAlertsError, rpmAlertsCountError]);

  useEffect(() => {
    if (Object.values(alertTypes).every(item => item)) {
      setAlertTypes(rpmAlertsTabs[currentTab].defaultAlertTypes);
    }
  }, [alertTypes]);

  useEffect(() => {
    if (isInPatientContext) {
      setColumnVisibility({
        name: false,
        phone: false,
        ...rpmAlertsTabs[0].columnVisibility
      });
    }
  }, [isInPatientContext]);

  return (
    <>
      <RpmPatientInfoDrawer patientsData={selectedPatient} />
      <Stack
        width="100%"
        minHeight="100%"
        height="auto"
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={isInPatientContext ? 0 : 2}
      >
        <Paper component={Stack} elevation={0} direction="column" spacing={2}>
          <RpmAlertsListToolbar
            readStatus={readStatus}
            rpmStatus={rpmStatus}
            setReadStatus={setReadStatus}
            setRpmStatus={setRpmStatus}
            setOrganization={setOrganization}
            organization={organization}
            isInPatientContext={isInPatientContext}
          />
        </Paper>
        <Paper
          padding={2}
          elevation={0}
          component={Stack}
          direction="column"
          spacing={2}
          sx={{
            flex: 1,
            height: 'auto'
          }}
        >
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
            <Button
              variant="contained"
              disabled={selectionModel.length === 0}
              onClick={() => {
                updateRpmAlerts({
                  variables: {
                    alertIds: selectionModel.map(item => item.toString()),
                    input: {
                      isRead: true
                    }
                  }
                });
              }}
            >
              Mark as Read
            </Button>
            <Button
              variant="outlined"
              disabled={selectionModel.length === 0}
              onClick={() => {
                updateRpmAlerts({
                  variables: {
                    alertIds: selectionModel.map(item => item.toString()),
                    input: {
                      isRead: false
                    }
                  }
                });
              }}
            >
              Mark as Unread
            </Button>
          </Stack>
          {!isInPatientContext && isSuperAdmin ? (
            <Tabs value={currentTab} onChange={handleTabChange}>
              {rpmAlertsTabs?.map((tab: RpmAlertsTabType, index: number) => (
                <Tab
                  style={{ minWidth: 'auto' }}
                  iconPosition={'start'}
                  label={<Body1 textTransform="Capitalize">{tab.label}</Body1>}
                  key={tab.label}
                  id={`tab-${index}`}
                />
              ))}
            </Tabs>
          ) : (
            <></>
          )}
          <Stack sx={{ pt: 2 }}>
            <TruentityDataGrid
              name={'dg-rpm-alerts-list'}
              paginationModel={{ pageSize: SMALL_PAGE_SIZE, page: currentPage }}
              onPaginationModelChange={({ page }) => {
                setCurrentPage(page);
              }}
              sortModel={sortModel}
              onSortModelChange={newModel => {
                setSortModel(newModel);
              }}
              filterModel={filterModel}
              onFilterModelChange={newModel => {
                setFilterModel(newModel);
              }}
              autoHeight
              rows={rpmAlerts?.getRpmAlerts?.rpmAlerts ?? []}
              checkboxSelection
              rowSelectionModel={selectionModel}
              columns={columns}
              disableRowSelectionOnClick
              onRowSelectionModelChange={newSelection => {
                setSelectionModel(newSelection);
              }}
              customFilter={
                <RpmAlertTypesFiltersSection
                  alertsCount={rpmAlertsCount?.getRpmAlertsCounts}
                  alertTypes={alertTypes}
                  setAlertTypes={setAlertTypes}
                  alertsTabEnum={rpmAlertsTabs[currentTab].value}
                />
              }
              initialState={{
                sorting: {
                  sortModel
                }
              }}
              loading={rpmAlertsLoading || rpmAlertsCountLoading}
              paginationMode={'server'}
              rowCount={rpmAlerts?.getRpmAlerts?.meta?.totalCount ?? 0}
              onCellClick={handleRowClick}
              columnVisibilityModel={columnVisibility}
              // TODO: This will confuse the users, need to revisit this UI
              // unstable_headerFilters={true}
            />
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};

export default RpmAlertsList;
