import type { AccountNoteType } from '@/types/remotePatientMonitoring';

import type { AccountNotesFilterOptions } from '@/graphql/remotePatientMonitoring';
import { PatientsData } from '@/routes/Patients/patients';
import { FollowUpRemindersTypes } from '@/types/accountProfile';
import create from 'zustand';

type QueryNotesType =
  | undefined
  | {
      filter?: AccountNotesFilterOptions;
      updated: Date | undefined;
    };

export type AccountStore = {
  globalAccounts: PatientsData[];
  setGlobalAccounts: (value: PatientsData[]) => void;
  accountNotes: AccountNoteType[];
  setAccountNotes: (value: AccountNoteType[]) => void;
  queryNotes: QueryNotesType;
  setQueryNotes: (value: QueryNotesType) => void;
  isAccountChanged: boolean;
  setIsAccountChanged: (value: boolean) => void;
  accountFollowUps: FollowUpRemindersTypes[];
  setAccountFollowUps: (value: FollowUpRemindersTypes[]) => void;
};

export const useAccountStore = create<AccountStore>(set => ({
  globalAccounts: [],
  setGlobalAccounts: value => set(() => ({ globalAccounts: value })),
  accountNotes: [],
  setAccountNotes: value => set(() => ({ accountNotes: value })),
  queryNotes: undefined,
  setQueryNotes: value => set(() => ({ queryNotes: value })),
  isAccountChanged: false,
  setIsAccountChanged: value => set(() => ({ isAccountChanged: value })),
  accountFollowUps: [],
  setAccountFollowUps: value => set(() => ({ accountFollowUps: value }))
}));
