import Button from '@/components/Button';
import { FollowUpAddEditDialog } from '@/components/Dialogs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import MultiStepConfirmationDialog from '@/components/Dialogs/MultiStepConfirmationDialog';
import ScheduleEnrollmentDialog from '@/components/Dialogs/ScheduleEnrollmentDialog';
import MuiMenu from '@/components/MuiMenu';
import { Body1, H4 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import { DELETE_ACCOUNT_FOLLOWUP, FollowUpStatusTypes, FollowUpTypes } from '@/graphql/account';
import type { UpdateCandidateStatusResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_CANDIDATE_STATUS } from '@/graphql/remotePatientMonitoring';
import type { PatientsData } from '@/routes/Patients/patients';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import type { LoggedUser } from '@/types/graphql';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { mandatoryTabsForEnrolment } from '@/util/constants';
import { formatDate } from '@/util/format';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import type { FetchResult } from '@apollo/client';
import { useMutation, useReactiveVar } from '@apollo/client';
import { Popover, Stack } from '@mui/material';
import parse from 'html-react-parser';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

type ModalType = RpmStatusTypes.ENROLLED | RpmStatusTypes.UNENROLLED;

interface buttonPopoverProps {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
}

const ButtonPopover = ({ id, open, anchorEl, handleClose }: buttonPopoverProps) => {
  const toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const mandatoryTabsSentence =
    mandatoryTabsForEnrolment
      .map(tab => `<strong>${toTitleCase(tab.replace(/_/g, ' ').toLowerCase())}</strong>`)
      .join(', ')
      .replace(/,([^,]*)$/, ', and$1') + ' Needs to be completed in order to enroll this patient.';

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      sx={{ mt: 1 }}
    >
      <Stack py={2} px={3} maxWidth={'27.75rem'} rowGap={1.5} sx={{ bgcolor: 'white' }}>
        <H4>Patient cannot be enrolled at the moment</H4>
        <Body1 style={{ fontSize: '.75rem' }}>{parse(mandatoryTabsSentence)}</Body1>
        <Button sx={{ alignSelf: 'flex-end' }} onClick={handleClose}>
          OK
        </Button>
      </Stack>
    </Popover>
  );
};

const RpmWorkflowStatusBar = () => {
  const { id } = useParams();
  const { showModal } = useModal();
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isEnrollmentEligibility } = useRpmSetupStore();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isCandidateOrScheduledPatent = useMemo(
    () => patientInfo?.rpmStatus === RpmStatusTypes.IS_CANDIDATE || patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    [patientInfo]
  );

  const open = Boolean(anchorEl);
  const popperId = open ? 'button-popover' : undefined;

  const [updateCandidateStatus, { loading: loadingCandidateStatus }] = useMutation<UpdateCandidateStatusResponse>(
    UPDATE_RPM_CANDIDATE_STATUS,
    {
      onError: () => enqueueSnackbar(`This patient's status can not be changed, Please contact support`, { variant: 'error' })
    }
  );
  const [deleteFollowup] = useMutation(DELETE_ACCOUNT_FOLLOWUP);

  const statusBarType: ModalType =
    patientInfo?.rpmStatus === RpmStatusTypes.IS_CANDIDATE ||
    patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT ||
    patientInfo?.rpmStatus === RpmStatusTypes.UNENROLLED
      ? RpmStatusTypes.ENROLLED
      : patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED
        ? RpmStatusTypes.UNENROLLED
        : RpmStatusTypes.ENROLLED; // Assigning a default value here

  const statusText = statusBarType === RpmStatusTypes.ENROLLED ? 'Enroll' : 'Unenroll';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBackToCandidate = () => {
    const modal = showModal(ConfirmDialog, {
      title: `Are you sure you want to move this patient back to candidate status?`,
      message: 'This patient will be moved back to candidate status, and the RPM Initial follow-up will not be removed.',
      onAgree: () => {
        updateCandidateStatus({
          variables: {
            truentityIds: [id],
            rpmStatus: RpmStatusTypes.IS_CANDIDATE
          }
        }).then(res => {
          handleSnackbar(RpmStatusTypes.IS_CANDIDATE, res);
          modal.hide();
        });
      },
      onDisagree: () => modal.hide()
    });
  };

  const handleSnackbar = useCallback(
    (rpmStatus: string, res: FetchResult<UpdateCandidateStatusResponse>) => {
      const updateCandidateStatus = res?.data?.updateCandidateStatus;

      if (updateCandidateStatus?.status === 'Success') {
        enqueueSnackbar(`Status changed to '${rpmStatus}' successfully.`, { variant: 'success' });

        setReloadPatientInfo(true);
      } else {
        enqueueSnackbar(`Status can not be changed to '${rpmStatus}'.`, { variant: 'error' });
      }
    },
    [setReloadPatientInfo, enqueueSnackbar]
  );

  const openConfirmationModal = useCallback(
    (modalType: ModalType) => {
      const nextData = formatDate(moment().add(1, 'days'));
      const title = `Are you sure you want to ${statusText.toLowerCase()} this patient?`;
      const message =
        modalType === RpmStatusTypes.ENROLLED
          ? `All device readings will be monitored starting tomorrow, ${nextData}.`
          : "This step will unenroll the patient from this program. Please have the patient return any devices that were supplied. These devices will also be removed from the patient's account. All prior collected data will be archived. Are you sure you want to continue?";

      if (modalType === RpmStatusTypes.UNENROLLED) {
        const modal = showModal(MultiStepConfirmationDialog, {
          title,
          message,
          onAgree: () => {
            updateCandidateStatus({
              variables: {
                truentityIds: [id],
                rpmStatus: modalType
              }
            }).then(res => handleSnackbar(RpmStatusTypes.UNENROLLED, res));
            modal.hide();
          },
          onDisagree: () => {
            modal.hide();
          }
        });
      } else {
        const modal = showModal(ConfirmDialog, {
          title,
          message,
          onAgree: () => {
            updateCandidateStatus({
              variables: {
                truentityIds: [id],
                rpmStatus: modalType
              }
            }).then(res => handleSnackbar(RpmStatusTypes.ENROLLED, res));
            modal.hide();
          },
          onDisagree: () => {
            modal.hide();
          }
        });
      }
    },
    [id, showModal, statusText, updateCandidateStatus, handleSnackbar]
  );

  const isCurrentUsersFollowup = useCallback((followup: FollowUpRemindersTypes, currentUser: LoggedUser | null) => {
    return currentUser?.id === followup.relyingPartyAdmin.id;
  }, []);

  const cancelFollowup = useCallback(
    (followups: FollowUpRemindersTypes[]) => {
      const followupIds = followups.map(followup => followup.id);
      const followupDate = formatDate(followups?.[0]?.followUpOn);

      const modal = showModal(ConfirmDialog, {
        title: followupIds.length > 1 ? 'Delete All Initial Visit Follow-ups' : 'Delete Initial Visit Follow-up',
        message:
          followupIds.length > 1
            ? `Are you sure you want to delete all initial visit follow-ups scheduled for ${followupDate}? This action is irreversible. If you need to delete a specific follow-up, please proceed manually.`
            : `Are you sure you want to delete the initial visit follow-up scheduled for ${followupDate}? This action is irreversible.`,
        onAgree: async () => {
          await deleteFollowup({ variables: { followUpIds: followupIds } })
            .then(response => {
              const data = response.data.removeAccountFollowup;
              const variant = data?.status === 'Success' ? 'success' : 'error';
              enqueueSnackbar(data.message, { variant });
              modal.hide();
              setReloadPatientInfo(true);
            })
            .catch(() => {
              enqueueSnackbar('Failed to delete this follow-up', {
                variant: 'error'
              });
              modal.hide();
            });
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [deleteFollowup, enqueueSnackbar, setReloadPatientInfo, showModal]
  );

  const showEditFollowupModal = useCallback(
    (followups: FollowUpRemindersTypes[], patientInfo: PatientsData) => {
      const isEnrolled = patientInfo?.rpmStatus === RpmStatusTypes.ENROLLED;
      const followupAssignments = patientInfo?.currentAccountsAssignments;

      const modal = showModal(FollowUpAddEditDialog, {
        title: 'Update Follow Up On',
        hideDialog: () => modal.hide(),
        handleRefetchAndClose: () => {
          modal.hide();
          setReloadPatientInfo(true);
        },
        truentityId: patientInfo?.truentityId,
        isRpmEnrolled: isEnrolled,
        doNotCall: patientInfo?.doNotCall,
        followupsData: followups,
        accountAssignments: followupAssignments,
        isInitialVisitFollowupForm: true,
        isUpdateModal: true
      });
    },
    [setReloadPatientInfo, showModal]
  );

  const showConfirmationDialog = useCallback(
    (followups: FollowUpRemindersTypes[], onAgreeCallback: () => void) => {
      const initialVisitFollowUpCount = followups.length;
      const followupOnDate = formatDate(followups?.[0]?.followUpOn);
      const modal = showModal(ConfirmDialog, {
        title: 'Confirmation Required',
        message: `There are <b>${initialVisitFollowUpCount} follow-up${
          initialVisitFollowUpCount > 1 ? 's' : ''
        }</b> scheduled for <b>${followupOnDate}</b> with the same type. This action will modify all associated follow-ups. Are you sure you want to proceed?`,
        onAgree: () => {
          modal.hide();
          onAgreeCallback();
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [showModal]
  );

  const handleEditSchedule = useCallback(
    (followups: FollowUpRemindersTypes[]) => {
      if (!patientInfo) return console.error('Cannot identify patient. Please contact support.');

      const editModalCallback = () => showEditFollowupModal(followups, patientInfo);

      if (followups.length > 0) {
        showConfirmationDialog(followups, editModalCallback);
      } else {
        showEditFollowupModal(followups, patientInfo);
      }
    },
    [patientInfo, showConfirmationDialog, showEditFollowupModal]
  );

  const handleCancelSchedule = useCallback(
    (followups: FollowUpRemindersTypes[]) => {
      if (!patientInfo) return console.error('Cannot identify patient. Please contact support.');

      const deleteModalCallback = () => cancelFollowup(followups);

      if (followups.length > 0) {
        showConfirmationDialog(followups, deleteModalCallback);
      } else {
        cancelFollowup(followups);
      }
    },
    [cancelFollowup, patientInfo, showConfirmationDialog]
  );

  const handleScheduleForEnrollment = useCallback(() => {
    if (!patientInfo) return;

    const patientName = getAccountUserFullName(patientInfo?.user);
    const modal = showModal(ScheduleEnrollmentDialog, {
      title: `Schedule for Enrollment: ${patientName} `,
      patientInfo: patientInfo,
      hideDialog: () => modal.hide(),
      refetch: () => setReloadPatientInfo(true)
    });
  }, [patientInfo, showModal, setReloadPatientInfo]);

  const initialVisitFollowUps = useMemo(() => {
    if (!patientInfo || !patientInfo.accountsFollowupReminders || !currentUser) return [] as FollowUpRemindersTypes[];

    return patientInfo?.accountsFollowupReminders.filter(
      followup => followup.type === FollowUpTypes.RPM_INITIAL_VISIT && followup.followUpOn
    );
  }, [currentUser, patientInfo]);

  const clusteredInitialVisitFollowups = useMemo(() => {
    const groupedByDate = initialVisitFollowUps.reduce(
      (acc, followup) => {
        const dateKey = moment(followup.followUpOn).format('YYYY-MM-DD');
        if (!acc[dateKey]) {
          acc[dateKey] = [];
        }
        acc[dateKey].push(followup);
        return acc;
      },
      {} as { [date: string]: FollowUpRemindersTypes[] }
    );
    const dates = Object.keys(groupedByDate).sort((a, b) => moment(a).diff(moment(b)));
    return groupedByDate[dates[0]] || [];
  }, [initialVisitFollowUps]);

  const initialVisitFollowUp = useMemo(() => {
    const currentUserFollowUp = clusteredInitialVisitFollowups?.find(followup => isCurrentUsersFollowup(followup, currentUser));

    return currentUserFollowUp || clusteredInitialVisitFollowups?.[0] || [];
  }, [currentUser, isCurrentUsersFollowup, clusteredInitialVisitFollowups]);

  const isAllInitialFollowupsCompleted = useMemo(() => {
    if (initialVisitFollowUps.length === 0) {
      return false;
    } else {
      return initialVisitFollowUps?.every(initialVisitFollowUp => initialVisitFollowUp.status === FollowUpStatusTypes.COMPLETED) || false;
    }
  }, [initialVisitFollowUps]);

  const isInitialFollowupScheduled = useMemo(
    () => isCandidateOrScheduledPatent && Object.keys(initialVisitFollowUp).length > 0 && !isAllInitialFollowupsCompleted,
    [isCandidateOrScheduledPatent, initialVisitFollowUp, isAllInitialFollowupsCompleted]
  );

  const getOnClickFunction = useCallback(
    e => {
      if (!isEnrollmentEligibility && isCandidateOrScheduledPatent) {
        return handleClick(e);
      }
      return openConfirmationModal(statusBarType);
    },
    [isEnrollmentEligibility, statusBarType, isCandidateOrScheduledPatent, openConfirmationModal]
  );

  return (
    <>
      <Stack direction="row" justifyContent={'flex-end'} alignItems="center" p="0.5rem 1.5rem" borderRadius="0.5rem" gap={1}>
        {isCandidateOrScheduledPatent && (
          <>
            {isInitialFollowupScheduled ? (
              <MuiMenu
                label={`Scheduled for ${formatDate(initialVisitFollowUp?.followUpOn)}`}
                variant="outlined"
                options={[
                  {
                    label: 'Edit Schedule',
                    onAction: () => handleEditSchedule(clusteredInitialVisitFollowups)
                  },
                  {
                    label: 'Cancel Schedule',
                    onAction: () => handleCancelSchedule(clusteredInitialVisitFollowups)
                  }
                ]}
              />
            ) : (
              <Button variant="outlined" onClick={handleScheduleForEnrollment}>
                {isAllInitialFollowupsCompleted ? 'Re-schedule' : 'Schedule'} for Enrollment
              </Button>
            )}
          </>
        )}
        {patientInfo?.rpmStatus === RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT && (
          <Button variant="outlined" onClick={handleBackToCandidate}>
            Revert to Candidate
          </Button>
        )}
        <Button isLoading={loadingCandidateStatus} onClick={e => getOnClickFunction(e)}>
          {statusText}
        </Button>
      </Stack>
      <ButtonPopover id={popperId} open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};

export default RpmWorkflowStatusBar;
