import SelectList, { TextSelectOption } from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { FormValues, GENDER_OPTIONS } from '@/types/accountProfile';
import { states } from '@/util/address';
import { Grid, Stack } from '@mui/material';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

type Props = {
  control: Control<FormValues, any>;
  setValue: UseFormSetValue<FormValues>;
  defaultBirthDate: Date | undefined;
};

const PatientDetailsForm = ({ control, setValue, defaultBirthDate }: Props) => {
  const STATES: TextSelectOption[] = states();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Stack spacing={2} mt={2}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField autoFocus required onChange={onChange} value={value} label={'First Name'} />
            )}
          />
          <Controller
            control={control}
            name="middleName"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Middle Name'} />}
          />
          <Controller
            control={control}
            name="lastName"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField required onChange={onChange} value={value} label={'Last Name'} />
            )}
          />
          <Controller
            control={control}
            name="gender"
            render={({ field: { onChange, value } }) => (
              <SelectList
                options={GENDER_OPTIONS}
                placeholder="Select an option..."
                onChange={onChange}
                value={value ?? ''}
                label={'Gender'}
              />
            )}
          />
          <Controller
            control={control}
            name="birthDate"
            render={({ field: { onChange, value } }) => (
              <TruentityDatePicker
                sx={{ marginTop: 0 }}
                value={value}
                TextFieldProps={{ required: true }}
                onChange={onChange}
                defaultValue={defaultBirthDate}
                label={'Date of Birth'}
              />
            )}
          />
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack spacing={2} mt={2}>
          <Controller
            control={control}
            name="addressLine1"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address Line 1'} />}
          />
          <Controller
            control={control}
            name="addressLine2"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address Line 2'} />}
          />
          <Controller
            control={control}
            name="addressCity"
            render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'Address City'} />}
          />
          <Controller
            control={control}
            name="addressState"
            render={({ field: { onChange, value } }) => (
              <SelectList
                id={'addressState'}
                label="Address State"
                options={STATES}
                placeholder="Select a state..."
                value={value ?? ''}
                clearFunction={() => {
                  setValue('addressState', '');
                }}
                MenuProps={{
                  sx: { maxHeight: 220 }
                }}
                onChange={onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="zipcode"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField required onChange={onChange} value={value} label={'Zip Code'} />
            )}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PatientDetailsForm;
