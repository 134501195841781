import { amber, blueGrey, green, orange, red } from '@mui/material/colors';

const truentityBlue = {
  50: '#e6eaf0',
  100: '#bfcbd9',
  200: '#95a9c0',
  300: '#6b86a7',
  400: '#4b6c94',
  500: '#2b5281',
  600: '#2F4C74',
  650: '#243A58',
  700: '#20416e',
  800: '#1a3864',
  900: '#102851',
  A100: '#8ab0ff',
  A200: '#578eff',
  A400: '#246cff',
  A700: '#0a5aff',
  contrastDefaultColor: 'light'
};

const truentitySalmon = {
  50: '#fdf0ee',
  100: '#fad9d5',
  200: '#f6c0ba',
  300: '#f2a69e',
  400: '#f09389',
  500: '#ed8074',
  600: '#eb786c',
  700: '#e86d61',
  800: '#e56357',
  900: '#e05044',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffd1ce',
  A700: '#ffbab4',
  contrastDefaultColor: 'dark'
};

const truentityCyan = {
  50: '#e4eff1',
  100: '#bbd7dd',
  200: '#8ebdc6',
  300: '#61a2af',
  400: '#3f8e9d',
  500: '#1d7a8c',
  600: '#1a7284',
  700: '#156779',
  800: '#115d6f',
  900: '#0a4a5c',
  A100: '#90e4ff',
  A200: '#5dd7ff',
  A400: '#2acaff',
  A700: '#10c4ff',
  contrastDefaultColor: 'light'
};

export const color = {
  // // paper & background
  paper: '#F0F4FB',
  white: '#ffffff',

  // primary
  primary100: truentityBlue[100],
  primaryLight: truentityBlue[50],
  primaryMain: truentityBlue[500],
  primaryDark: truentityBlue[650],
  primary200: truentityBlue.A100,
  primary300: truentityBlue[300],
  primary400: truentityBlue[400],
  primary500: truentityBlue[500],
  primary600: truentityBlue[600],
  primary700: truentityBlue[700],
  primary800: truentityBlue.A200,

  // secondary
  secondaryLight: truentitySalmon[50],
  secondaryMain: truentitySalmon[500],
  secondaryDark: truentitySalmon[700],
  secondary100: truentitySalmon[100],
  secondary200: truentitySalmon.A100,
  secondary800: truentitySalmon.A200,

  // success Colors
  successLight: green[50],
  success200: green[200],
  successMain: green[300],
  successDark: green[400],

  // error
  errorLight: red[100],
  errorMain: red[500],
  errorDark: red[600],

  // orange
  orangeLight: orange[100],
  orangeMain: orange[200],
  orangeDark: orange[300],
  orangeDarker: orange[500],

  // warning
  warningLight: amber[100],
  warningMain: amber[400],
  warningDark: amber[500],

  // grey
  grey50: '#fafafa',
  grey100: '#f5f5f5',
  grey200: '#eeeeee',
  grey300: '#e0e0e0',
  grey400: '#C5C5C5',
  grey500: '#9e9e9e',
  grey600: '#757575',
  grey700: '#616161',
  grey800: '#525252',
  grey900: '#212121',

  // blueGrey
  blueGrey300: '#90a4ae',
  blueGrey400: '#78909c',
  blueGrey500: '#607d8b',
  blueGrey600: '#546e7a',

  //black
  black50: '#212121',
  black100: '#000000',

  // blue
  blue500: '#03a9f4',
  blue600: '#039be5',

  //green
  green50: '#E8F5E9',
  green500: '#4caf50',
  green800: '#2E7D32',

  //amber
  amber50: '#FFF8E1',
  amber800: '#FF8F00',

  //red
  red50: '#FFEBEE',
  red900: '#B71C1C',

  //brown
  brown700: '#B46B00',

  //pink
  pink50: '#F24769',
  pink100: '#E86D61',

  truentityBlue,
  truentityCyan,
  truentitySalmon
};
