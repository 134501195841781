import Button from '@/components/Button';
import GenderIcon from '@/components/Gender/GenderIcon';
import PatientName from '@/components/PatientName';
import StarredPatient from '@/components/StarredPatient';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1 } from '@/components/Typography';
import config from '@/config';
import useMessages from '@/hooks/useMessages';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import AccountFollowUp from '@/routes/PatientDetails/PatientInfoTabs/AccountFollowUp';
import { ActiveAlertsRow } from '@/routes/PatientDetails/PatientInfoTabs/activeAlertsRow';
import { ButtonMore, HealthPlanRow, MAX_NAMES_TO_SHOW } from '@/routes/PatientDetails/PatientInfoTabs/healthPlanRow';
import PatientInfoTabsButtonMenu from '@/routes/PatientDetails/PatientInfoTabs/PatientInfoTabsButtonMenu';
import PatientInfoTabsInfoTabs from '@/routes/PatientDetails/PatientInfoTabs/PatientInfoTabsInfoTabs';
import NoteItem from '@/routes/PatientDetails/RemotePatientMonitoring/Components/Notes/NoteItem';
import type { PatientsData } from '@/routes/Patients/patients';
import { MedicalServices } from '@/types/admin';
import { RpmStatusTypes, type AccountNoteType } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { stringInitials } from '@/util/avatar';
import { formatDate } from '@/util/format';
import { useAccountStore } from '@/zustand/AccountStore';
import { useAppFrameStore } from '@/zustand/AppFrameStore';
import { useProviderStore } from '@/zustand/ProviderStore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Chip, Collapse, Divider, Paper, Popover, Stack, useTheme } from '@mui/material';
import { yellow } from '@mui/material/colors';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  patientInfo: PatientsData;
  truentityId: string | undefined;
  currentMedsCount: number;
  onNextCallOnHyperlinkClicked: (truentityId, isRpmEnrolled) => void;
  onAssignedToChipClicked: () => void;
};

const PatientInfoTabs = ({ patientInfo, truentityId, currentMedsCount, onNextCallOnHyperlinkClicked, onAssignedToChipClicked }: Props) => {
  const theme = useTheme();

  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);

  const { accountNotes, queryNotes, setQueryNotes } = useAccountStore();
  const [pinnedNotes, setPinnedNotes] = useState<AccountNoteType[]>([]);

  const [collapsePatientInfo, setCollapsePatientInfo] = useState(true);
  const [shortView, setShortView] = useState(false);

  const currentDate = new Date();
  const birthDateTodate = patientInfo?.birthDate ? new Date(patientInfo?.birthDate.replace(/-/g, '/')) : '';
  const years = moment(currentDate).diff(moment(birthDateTodate), 'years');
  const isRpmEnrolled = patientInfo.rpmStatus === RpmStatusTypes.ENROLLED;

  const navigate = useNavigate();

  const [assignedAdminsAnchorEl, setAssignedAdminsAnchorEl] = useState(null);
  const [showMoreAssignedAdmins, setShowMoreAssignedAdmins] = useState(false);

  const { setIsMessageDrawerOpen, setPatient } = useMessages();

  const { showAssignedProviders, setShowAssignedProviders } = useProviderStore();

  const { contentScrollPosition, setGoTop } = useAppFrameStore();
  const [makeHeaderSticky, setMakeHeaderSticky] = useState(false);
  const [collapseNotes, setCollapseNotes] = useState(false);

  const handleAssignedAdminsPopoverClick = event => {
    setAssignedAdminsAnchorEl(event.currentTarget);
    setShowMoreAssignedAdmins(true);
  };

  const handleAssignedAdminsPopoverClose = () => {
    setAssignedAdminsAnchorEl(null);
    setShowMoreAssignedAdmins(false);
  };

  const goToEditProfile = useCallback(() => {
    if (truentityId) {
      navigate(`/providers/${truentityId}/update-profile`);
    }
  }, [truentityId]);

  useEffect(() => {
    setMakeHeaderSticky(contentScrollPosition > 64);
    if (contentScrollPosition > 100) {
      setShortView(true);
    } else if (contentScrollPosition === 0) {
      setShortView(false);
    }
  }, [contentScrollPosition]);

  useEffect(() => {
    if (makeHeaderSticky) {
      setCollapsePatientInfo(true);
      setCollapseNotes(true);
    }
  }, [makeHeaderSticky]);

  useEffect(() => {
    if (!collapsePatientInfo) {
      setCollapseNotes(false);
    }
  }, [collapsePatientInfo]);

  useEffect(() => {
    if (patientInfo?.truentityId) {
      setPatient({
        id: patientInfo?.id,
        patientId: patientInfo?.truentityId,
        firstName: patientInfo?.user?.firstName,
        lastName: patientInfo?.user?.lastName,
        patientName: getAccountUserFullName(patientInfo?.user),
        patientContactNo: patientInfo?.phone,
        gender: patientInfo?.gender,
        setting: patientInfo.setting
      });
    }
  }, [patientInfo, setPatient]);

  useEffect(() => {
    setPinnedNotes(accountNotes?.filter(notes => notes?.pinToProfile));
  }, [accountNotes, queryNotes]);

  return (
    <Paper
      component={Stack}
      p={1}
      pb={0}
      spacing={1}
      sx={{
        position: makeHeaderSticky ? 'sticky' : 'initial',
        zIndex: theme.zIndex.drawer - 1,
        top: '0'
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        spacing={1}
        sx={{ border: config.DEBUG_LAYOUT ? '5px solid magenta' : undefined }}
      >
        <Stack
          direction="row"
          flex={1}
          spacing={1}
          alignItems={'center'}
          sx={{ border: config.DEBUG_LAYOUT ? '5px solid red' : undefined }}
        >
          <PatientName gotoEditProfile={goToEditProfile} patientInfo={patientInfo}>
            <Stack direction={'row'} alignItems={'center'} alignContent={'center'} spacing={2} sx={{ flexWrap: 'nowrap' }}>
              {isRPMViewable ? (
                <ActiveAlertsRow
                  totalReadings={patientInfo?.numOfReadingsInCurrentMonth}
                  totalTime={patientInfo?.totalTimeSpentSecsInCurrentMonth}
                />
              ) : (
                <HealthPlanRow goToEditProfile={goToEditProfile} patientInfo={patientInfo} />
              )}
              <Stack
                direction={'row'}
                spacing={1}
                alignItems={'center'}
                sx={{ minWidth: 200, border: config.DEBUG_LAYOUT ? '5px solid pink' : undefined }}
              >
                <Stack direction="row" alignItems={'center'} spacing={1}>
                  <GenderIcon gender={patientInfo?.gender} />
                  <Body1 fontWeight={'bold'}>{patientInfo?.birthDate ? `${formatDate(birthDateTodate)} (${years} yr)` : '---'}</Body1>
                </Stack>

                {patientInfo?.phone && <TruentityPhoneNumber value={patientInfo?.phone} editable={false} />}
              </Stack>
            </Stack>
          </PatientName>
        </Stack>

        <Stack
          direction={'row'}
          sx={{ border: config.DEBUG_LAYOUT ? '5px solid teal' : undefined, overflow: 'hidden', width: 'fit-content', flexWrap: 'wrap' }}
          alignItems={'center'}
          alignContent={'space-evenly'}
          justifyContent={'flex-end'}
          spacing={1}
        >
          {!shortView && patientInfo?.currentAccountsAssignments?.length > 0 && (
            <Stack direction="row" spacing={1} alignItems={'center'}>
              <Stack direction="row" spacing={1}>
                {patientInfo?.currentAccountsAssignments[0]?.relyingPartyAdmin && (
                  <Chip
                    size="medium"
                    label={stringInitials(patientInfo?.currentAccountsAssignments[0]?.relyingPartyAdmin?.name)}
                    onClick={onAssignedToChipClicked}
                  />
                )}

                {patientInfo?.currentAccountsAssignments.length > MAX_NAMES_TO_SHOW && (
                  <ButtonMore>
                    <Button
                      variant="text"
                      label={`${patientInfo?.currentAccountsAssignments.length - MAX_NAMES_TO_SHOW}+`}
                      onClick={handleAssignedAdminsPopoverClick}
                      endIcon={showMoreAssignedAdmins ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    />
                  </ButtonMore>
                )}
              </Stack>
              <Popover open={showMoreAssignedAdmins} anchorEl={assignedAdminsAnchorEl} onClose={handleAssignedAdminsPopoverClose}>
                <Stack direction="row" spacing={1} sx={{ p: 2 }}>
                  {patientInfo?.currentAccountsAssignments?.map(
                    ({ relyingPartyAdmin }, index) =>
                      index >= MAX_NAMES_TO_SHOW && (
                        <div key={`${relyingPartyAdmin?.name}-${index}`}>
                          {relyingPartyAdmin?.name && <Chip size="medium" label={relyingPartyAdmin?.name} style={{ marginRight: '4px' }} />}
                        </div>
                      )
                  )}
                  <Button variant="text" onClick={handleAssignedAdminsPopoverClose}>
                    Close
                  </Button>
                </Stack>
              </Popover>
            </Stack>
          )}

          <PatientInfoTabsButtonMenu
            setIsMessageDrawerOpen={val => {
              setGoTop(true);
              setIsMessageDrawerOpen(val);
            }}
            goToInsights={() => navigate('../../insights')}
            toggleAssignedProviders={() => {
              setGoTop(true);
              setShowAssignedProviders(!showAssignedProviders);
            }}
            goToNotes={() => navigate('./notes')}
            togglePatientInfo={() => setCollapsePatientInfo(!collapsePatientInfo)}
            goToAlerts={() => navigate('./rpm/alerts')}
            phone={patientInfo?.phone}
            collapsePatientInfo={collapsePatientInfo}
          />

          <StarredPatient truentityId={patientInfo.truentityId} />
        </Stack>
      </Stack>

      {!shortView && (
        <AccountFollowUp
          doNotCall={patientInfo?.doNotCall}
          followUpRemindersTypes={patientInfo?.accountsFollowupReminders}
          onFollowUpClick={() => {
            onNextCallOnHyperlinkClicked(truentityId, isRpmEnrolled);
          }}
          onAllFollowUpsClick={() => navigate('./follow-ups')}
          onCalendarClick={() => navigate('../../../patients?search=to-call-next')}
        />
      )}

      {pinnedNotes?.length > 0 && (
        <Collapse in={!collapseNotes} timeout="auto">
          <Stack
            sx={{
              backgroundColor: yellow[50],
              borderRadius: '8px',
              paddingY: theme.spacing(1),
              paddingX: theme.spacing(2)
            }}
          >
            <Stack direction={'row'} spacing={1} sx={{ overflowX: 'auto' }}>
              {pinnedNotes.map((note, index) => (
                <NoteItem
                  key={index}
                  index={index.toString()}
                  note={note}
                  onUpdateNoteSuccess={() => setQueryNotes({ updated: new Date() })}
                  sx={{ backgroundColor: 'inherit', minWidth: '100%', flex: 1, padding: 0 }}
                  readonly={true}
                >
                  <Button label="VIEW ALL" variant={'text'} onClick={() => navigate(`/patients/${truentityId}/details/notes`)} />
                </NoteItem>
              ))}
            </Stack>
          </Stack>
        </Collapse>
      )}

      {!collapsePatientInfo && <Divider sx={{ marginTop: '10px' }} />}

      <PatientInfoTabsInfoTabs
        collapsePatientInfo={collapsePatientInfo}
        patientInfo={patientInfo}
        currentMedsCount={currentMedsCount}
        goToEditProfile={goToEditProfile}
        patientId={truentityId}
      />
    </Paper>
  );
};

export default PatientInfoTabs;
