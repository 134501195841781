import { DEFAULT_PAGE_SIZE, HIGH_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { ADD_ACCOUNT } from '@/graphql/account';
import { GET_CLIENT_ORGANIZATIONS_STANDARD, GET_CLIENT_STORES_STANDARD, GET_HEALTH_PLANS } from '@/graphql/administration';
import useShowMedicalServices from '@/hooks/useShowMedicalServices';
import type { HealthPlan } from '@/types/accountProfile';
import { CONSENT_OPTIONS, GENDER_OPTIONS_ARRAY } from '@/types/accountProfile';
import { MedicalServices } from '@/types/admin';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import { states } from '@/util/address';
import { loggedUserData } from '@/util/apollo/cache';
import { formatDate, titleCase } from '@/util/format';
import { zipcodeRestrictToFiveDigits } from '@/util/input';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import {
  Autocomplete,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField as TextFieldMUI
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import MuiAccordion from '../MuiAccordion';
import type { TextSelectOption } from '../SelectList';
import SelectList from '../SelectList';
import TruentityDatePicker from '../TruentityDatePicker';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import type { PatientsData } from '@/routes/Patients/patients';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  onPatientAdded: (account: PatientsData) => void;
};

type FormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date | null;
  email: string;
  phoneNumber: string;
  gender: string;
  zipcode: string;
  ehrId: string;
  consent: string;
  healthPlan: string;
  clientOrganization: string;
  clientStore: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressState: string;
};

const defaultValues: FormValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: null,
  email: '',
  phoneNumber: '',
  gender: '',
  zipcode: '',
  healthPlan: '',
  ehrId: '',
  consent: 'PatientAnyProvider',
  clientOrganization: '',
  clientStore: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: ''
};

const PatientAddDialog = ({ title, hideDialog, onPatientAdded, ...props }: Props) => {
  const currentUser = useReactiveVar(loggedUserData);
  const isRPMViewable = useShowMedicalServices(MedicalServices.RPM);

  const { control, setValue, getValues, watch, reset, handleSubmit } = useForm<FormValues>({ defaultValues });
  const nameFields = watch(['firstName', 'lastName']); // you can also target specific fields by their names
  const { enqueueSnackbar } = useSnackbar();
  const [addAccount, { loading: isAddAccountLoading }] = useMutation(ADD_ACCOUNT);
  const STATES: TextSelectOption[] = states();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: clientStoresDataLoading,
    data: clientStoresData,
    called: clientStoresDataCalled
  } = useQuery(GET_CLIENT_STORES_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: HIGH_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [getHealthPlans, { data: healthPlans }] = useLazyQuery(GET_HEALTH_PLANS);

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [clientStores, setClientStores] = useState<ClientStoreObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);
  const [healthPlanList, setHealthPlans] = useState<HealthPlan[]>([]);
  const [isAddressRequired, setIsAddressRequired] = useState<boolean>(false);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations(organizationData.clientOrganizations.clientOrganizations);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setValue('clientStore', '');
  }, [filteredClientStores]);

  useEffect(() => {
    if (clientStoresDataCalled && !clientStoresDataLoading && clientStoresData) {
      setClientStores(clientStoresData.clientStores.clientStores);
    }
  }, [clientStoresDataLoading, clientStoresData, clientStoresDataCalled]);

  useEffect(() => {
    getHealthPlans({
      variables: {
        pageNum: 0,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });
  }, []);

  useEffect(() => {
    if (healthPlans && healthPlans.healthPlans && healthPlans.healthPlans.healthPlans) {
      setHealthPlans(healthPlans.healthPlans.healthPlans);
    }
  }, [healthPlans]);

  useEffect(() => {
    if (isRPMViewable) {
      setIsAddressRequired(true);
    }
  }, [healthPlans]);

  const getEmail = () => {
    if (nameFields[0]?.trim().length > 0 && nameFields[1]?.trim().length > 0) {
      return `${currentUser?.orgHandle}+${nameFields[0].trim()}.${nameFields[1].trim()}@truentity.com`.toLowerCase();
    }

    return '';
  };

  const updateDefaultEmail = e => {
    const emailField = getValues('email');

    const email = getEmail();
    setValue('email', email);
  };

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      // Use formatDate and not formateDateIgnoreTZ, as the DatePicker gives with UTC false

      const result: any = await addAccount({
        variables: {
          ...values,
          firstName: titleCase(values.firstName.trim()),
          middleName: values.middleName ? titleCase(values.middleName.trim()) : null,
          lastName: titleCase(values.lastName.trim()),
          phoneNumber: values.phoneNumber.trim(),
          zipcode: values.zipcode.trim(),
          dateOfBirth: formatDate(values.dateOfBirth, 'YYYY-MM-DD'),
          addressState: values.addressState.toUpperCase(),
          ehrId: values.ehrId
        }
      });

      const data = result.data!.addAccount;
      const variant = data!.status === 'Success' ? 'success' : 'error';
      const account = data.account as PatientsData;

      enqueueSnackbar(data.message, {
        variant
      });

      onPatientAdded(account);
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to add account', {
        variant: 'error'
      });

      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Grid component={'form'} container columnSpacing={4} rowSpacing={1} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={6}>
            <Stack>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField
                    autoFocus={true}
                    required
                    onBlur={updateDefaultEmail}
                    onChange={onChange}
                    value={value}
                    label={'First Name'}
                  />
                )}
              />

              <Controller
                control={control}
                name="middleName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField onChange={onChange} onBlur={updateDefaultEmail} value={value} label={'Middle Name'} />
                )}
              />

              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField required onChange={onChange} onBlur={updateDefaultEmail} value={value} label={'Last Name'} />
                )}
              />

              <Controller
                control={control}
                name="dateOfBirth"
                render={({ field: { onChange, value } }) => (
                  <TruentityDatePicker TextFieldProps={{ required: true }} onChange={onChange} value={value} label={'Date of Birth'} />
                )}
              />

              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField type={'email'} required onChange={onChange} value={value} label={'Email'} />
                )}
              />

              <Controller
                control={control}
                name="clientOrganization"
                render={({ field: { onChange, value } }) => (
                  <FormControl variant="outlined" fullWidth margin="dense" size="medium">
                    <InputLabel id={'organizations-input'} required>
                      Client Organization
                    </InputLabel>

                    <Select
                      labelId={'organizations'}
                      label={'Organizations'}
                      placeholder={'select an organization'}
                      value={value}
                      required
                      onChange={event => {
                        if (clientStores.length > 0) {
                          const storesBelongingToSelectedOrg = clientStores.filter(store => store.clientOrg?.id === event.target.value);
                          setFilteredClientStores(storesBelongingToSelectedOrg);
                        }
                        onChange(event);
                      }}
                    >
                      {clientOrganizations.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="healthPlan"
                render={({ field: { onChange, value } }) => (
                  <SelectList
                    id={'healthPlan'}
                    label="Health Plan"
                    options={[...healthPlanList]
                      .sort((a, b) => a?.orgName.localeCompare(b?.orgName))
                      .map(x => ({ label: x?.orgName, value: x?.id }))}
                    placeholder="Select a health plan..."
                    value={value}
                    clearFunction={() => {
                      setValue('healthPlan', '');
                    }}
                    MenuProps={{
                      sx: { maxHeight: 220 }
                    }}
                    onChange={onChange}
                  />
                )}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack sx={{ marginTop: 1 }}>
              <Controller
                control={control}
                name="gender"
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    id="gender-options"
                    options={GENDER_OPTIONS_ARRAY}
                    renderInput={params => <TextFieldMUI required {...params} label="Gender" variant="outlined" />}
                    getOptionLabel={option => option}
                    value={value}
                    onChange={(_event, selectedOption) => {
                      onChange(selectedOption);
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="zipcode"
                render={({ field: { onChange, value } }) => (
                  <TruentityTextField
                    onChange={e => zipcodeRestrictToFiveDigits(e.target.value, onChange)}
                    value={value}
                    label={'Zipcode'}
                    required
                  />
                )}
              />

              <Controller
                control={control}
                name="consent"
                render={({ field: { onChange, value } }) => (
                  <SelectList
                    label="Consent"
                    options={CONSENT_OPTIONS}
                    placeholder="Select an option..."
                    value={value}
                    onChange={onChange}
                  />
                )}
              />

              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { onChange, value } }) => (
                  <TruentityPhoneNumber editable={true} label={'Phone'} value={value} onChange={onChange} />
                )}
              />

              <Controller
                control={control}
                name="clientStore"
                render={({ field: { onChange, value } }) => (
                  <FormControl variant="outlined" fullWidth margin="dense" size="medium">
                    <InputLabel required id={'organizations-input'}>
                      Client Location
                    </InputLabel>

                    <Select labelId={'stores'} label={'Stores'} placeholder={'select a store'} value={value} required onChange={onChange}>
                      {filteredClientStores.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                control={control}
                name="ehrId"
                render={({ field: { onChange, value } }) => <TruentityTextField onChange={onChange} value={value} label={'EHR ID'} />}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ marginTop: 2 }} />
            <FormControlLabel
              label={`Include Patient Address Details`}
              control={<Checkbox checked={isAddressRequired} onChange={(_e, checked) => setIsAddressRequired(checked)} />}
            />
            <MuiAccordion
              options={[
                {
                  label: 'Patient Address Details',
                  defaultExpand: false,
                  content: (
                    <>
                      <Controller
                        control={control}
                        name="addressLine1"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'Address Line 1'} required={isAddressRequired} />
                        )}
                      />
                      <Controller
                        control={control}
                        name="addressLine2"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'Address Line 2'} required={false} />
                        )}
                      />
                      <Controller
                        control={control}
                        name="addressCity"
                        render={({ field: { onChange, value } }) => (
                          <TruentityTextField onChange={onChange} value={value} label={'Address City'} required={isAddressRequired} />
                        )}
                      />
                      <Controller
                        control={control}
                        name="addressState"
                        render={({ field: { onChange, value } }) => (
                          <SelectList
                            id={'addressState'}
                            label="Address State"
                            options={STATES}
                            placeholder="Select a state..."
                            required={isAddressRequired}
                            value={value}
                            clearFunction={() => {
                              setValue('addressState', '');
                            }}
                            MenuProps={{
                              sx: { maxHeight: 220 }
                            }}
                            onChange={onChange}
                          />
                        )}
                      />
                    </>
                  ),
                  expanded: isAddressRequired,
                  onChange: () => setIsAddressRequired(!isAddressRequired)
                }
              ]}
            />
          </Grid>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
              <Button type="submit" a11yLabel="Save" appearance="primary" isLoading={isAddAccountLoading} />
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  reset();
                  hideDialog();
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </DialogContent>
    </BaseDialog>
  );
};

export default PatientAddDialog;
