import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { PriorityColorMap } from '@/components/Priority/Priority';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import SideMenuContext from '@/context/sideMenuContext';
import { GET_CLIENT_ORGANIZATIONS_STANDARD, GET_RELYING_PARTY_PHARMACISTS } from '@/graphql/administration';
import { GET_TASKS_BY_REYLING_PARTY } from '@/graphql/taskEncounter';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import type { TaskType } from '@/types/graphql';
import { TASK_MODE_OF_RESOLUTION_TYPES, TASK_TYPES, TASK_WRAP_UP_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import type { TaskStatus } from '@/types/tasks';
import { TaskStatuses } from '@/types/tasks';
import { currentLoggedUserVar } from '@/util/apollo/cache';

import { toDateOrNull } from '@/util/date';
import { formatDate } from '@/util/format';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import AttemptedIcon from '@mui/icons-material/Done';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import PendingIcon from '@mui/icons-material/Pending';
import RefreshIcon from '@mui/icons-material/Refresh';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

enum PriorityTypesFilter {
  All = 'all',
  Urgent = 'urgent',
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

const tabs = [
  {
    a11yLabel: 'pending',
    label: 'Pending',
    value: TaskStatuses.Pending,
    icon: <PendingIcon />
  },
  {
    a11yLabel: 'attempted',
    label: 'Attempted',
    value: TaskStatuses.Attempted,
    icon: <AttemptedIcon />
  },
  {
    a11yLabel: 'completed',
    label: 'Completed',
    value: TaskStatuses.Completed,
    icon: <TaskAltIcon />
  }
];

const defaults: ClientStoreObject[] = [{ id: 'all', name: 'All' }];
const pharmacistDefaults: { id: string; name: string }[] = [
  { id: 'all', name: 'All' },
  { id: 'unassigned', name: 'Unassigned' }
];

const MyTasks = () => {
  const { roleType } = useToken();
  const [isPharmacistAdmin] = useState<boolean>(roleType === Role.PHARMACIST_ADMIN);
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);

  const { setContent } = useContext(SideMenuContext);
  const [getTasks, { loading: loadingTasks, data }] = useLazyQuery(GET_TASKS_BY_REYLING_PARTY);

  const [getUsers, usersResult] = useLazyQuery(GET_RELYING_PARTY_PHARMACISTS);

  const [rowCount, setRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [tasks, setTasks] = useState<TaskType[]>([]);

  const [relyingPartyAdmins, setRelyingPartyAdmins] = useState<any[]>([]);
  const [selectedRelyingPartyAdmin, setSelectedRelyingPartyAdmin] = useState<string>('unassigned');

  const currentUser = useReactiveVar(currentLoggedUserVar);
  const navigate = useNavigate();

  const [tab, setTab] = useState<number>(0);
  const [taskStatus, setTaskStatus] = useState<TaskStatus>(TaskStatuses.Pending);

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);

  const [noOfEncounters, setNoOfEncounters] = useState<number | undefined | null>(3);
  const [tasksReportLoading, setTasksReportLoading] = useState(false);
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const [selectedOrgId, setSelectedOrgId] = useState<string>('all');
  const [selectedClientStoreId, setSelectedClientStoreId] = useState<string>('');
  const [selectedPriority, setSelectedPriority] = useState<PriorityTypesFilter>(PriorityTypesFilter.All);

  const callGetTasks = useCallback(
    (resetEncounters?) => {
      setTasksReportLoading(true);

      // Date be sent with 000 time component
      // the backend uses beginning and end of the day to then compare only the date parts
      if (isSuperAdmin || isPharmacistAdmin) {
        getTasks({
          variables: {
            pageSize: DEFAULT_PAGE_SIZE,
            pageNum: currentPage + 1,
            filterOptions: {
              status: taskStatus,
              priority: selectedPriority !== PriorityTypesFilter.All ? selectedPriority : undefined,
              organization: selectedOrgId?.length > 0 && selectedOrgId !== 'all' ? selectedOrgId : undefined,
              orgStore: selectedClientStoreId?.length > 0 && selectedClientStoreId !== 'all' ? selectedClientStoreId : undefined,
              createdOnOrBefore: endDate ? formatDate(endDate, 'YYYY-MM-DD') : undefined,
              encountersCount: resetEncounters ? 0 : noOfEncounters,
              relyingPartyAdminId:
                relyingPartyAdmins.length > 0 && selectedRelyingPartyAdmin !== 'all' ? selectedRelyingPartyAdmin : undefined
            }
          }
        })
          .then(() => {
            setTasksReportLoading(false);
          })
          .catch(() => {
            setTasksReportLoading(false);
          });
      } else {
        getTasks({
          variables: {
            pageSize: DEFAULT_PAGE_SIZE,
            pageNum: currentPage + 1,
            // relyingPartyAdminId: currentUser?.id,
            filterOptions: {
              status: taskStatus,
              priority: selectedPriority !== PriorityTypesFilter.All ? selectedPriority : undefined,
              organization: selectedOrgId?.length > 0 && selectedOrgId !== 'all' ? selectedOrgId : undefined,
              orgStore: selectedClientStoreId?.length > 0 && selectedClientStoreId !== 'all' ? selectedClientStoreId : undefined,
              createdOnOrBefore: endDate ? moment(endDate).local().hours(0).minutes(0).seconds(0) : undefined,
              encountersCount: resetEncounters ? 0 : noOfEncounters,
              relyingPartyAdminId: currentUser?.id
            }
          }
        })
          .then(() => {
            setTasksReportLoading(false);
          })
          .catch(() => {
            setTasksReportLoading(false);
          });
      }
    },
    [
      currentPage,
      currentUser?.id,
      endDate,
      getTasks,
      isPharmacistAdmin,
      isSuperAdmin,
      noOfEncounters,
      relyingPartyAdmins.length,
      selectedClientStoreId,
      selectedOrgId,
      selectedPriority,
      selectedRelyingPartyAdmin,
      taskStatus
    ]
  );

  const handleTabChange = useCallback((event: SyntheticEvent, selectedTabIndex: number) => {
    setTab(selectedTabIndex);
    //Object.values(TimesheetStatuses).map(value => ({ value, label: value.toUpperCase() } as TextSelectOption))
    const selectedStatus: TaskStatus = tabs[selectedTabIndex].value;
    setTaskStatus(selectedStatus);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        minWidth: 160,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params.row.account.user.firstName + ' ' + params.row.account.user.lastName,
        renderCell: cellValues => {
          const name = cellValues.row.account.user.firstName + ' ' + cellValues.row.account.user.lastName;
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {name}
            </Stack>
          );
        }
      },
      {
        field: 'type',
        headerName: 'Type',
        minWidth: 30,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_TYPES.filter(type => type.value === cellValues.row.type);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'subType',
        headerName: 'Sub Type',
        minWidth: 160,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = UPDATED_SUB_TASK_TYPES.filter(type => type.value === cellValues.row.subType);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={6} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'priority',
        headerName: 'Priority',
        sortable: true,
        renderCell: params => {
          return <FlagCircleRoundedIcon fontSize="medium" color={PriorityColorMap[params.row.priority]} />;
        },
        headerAlign: 'left',
        align: 'center'
      },
      {
        field: 'medicationName',
        headerName: 'Medication',
        sortable: true,
        valueGetter: params => params.row.medicationName,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'billedAmount',
        headerName: 'Billed Amount',
        minWidth: 80,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueFormatter: params => `$ ${params.value || 0}`
      },
      {
        field: 'bonusAmount',
        headerName: 'Bonus Amount',
        minWidth: 80,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueFormatter: params => `$ ${params.value || 0}`
      },
      {
        field: 'modeOfResolution',
        headerName: 'Mode Of Resolution',
        sortable: true,
        flex: 1,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === cellValues.row.modeOfResolution);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'wrapUp',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        minWidth: 130,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_WRAP_UP_TYPES.filter(type => type.value === cellValues.row.wrapUpStatus);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      // {
      //     field: 'noOfEncounters',
      //     headerName: '# of Encounters ',
      //     sortable: true,
      //     valueGetter: (params) => params.row.encounters?.length,
      //     flex: 1,
      //     align: 'left',
      //     headerAlign: 'left',
      // },
      {
        field: 'createdAt',
        headerName: 'Created At',
        type: 'date',
        sortable: true,
        valueGetter: params => toDateOrNull(params.row.createdAt),
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          return moment(params.row.createdAt).format('MMM DD, YYYY');
        }
      }
    ],
    []
  );

  const encountersCountSelectItems = () => {
    const optionsArray: any[] = [];
    const maxEncounters = 100;
    optionsArray.push(
      <MenuItem key={0} value={3}>
        {' '}
        0 - 3{' '}
      </MenuItem>
    );
    optionsArray.push(
      <MenuItem key={4} value={maxEncounters}>
        {' '}
        3{' '}
      </MenuItem>
    );

    return optionsArray;
  };

  const isFiltersApplied = () => {
    let filterApplied = false;

    if (selectedPriority !== PriorityTypesFilter.All) {
      filterApplied = true;
    }

    if (selectedOrgId !== 'all') {
      filterApplied = true;
    }

    if (selectedClientStoreId !== '') {
      filterApplied = true;
    }

    const formattedNewDate = moment(new Date()).format('YYYY-MM-DD');
    if (moment(endDate).format('YYYY-MM-DD') !== formattedNewDate) {
      filterApplied = true;
    }

    if (noOfEncounters !== 3) {
      filterApplied = true;
    }

    if (selectedRelyingPartyAdmin !== 'unassigned') {
      filterApplied = true;
    }

    return filterApplied;
  };

  const onFiltersReset = () => {
    setTasks([]);
    setRowCount(0);
    setCurrentPage(0);
    setPageSize(DEFAULT_PAGE_SIZE);
    setSelectedPriority(PriorityTypesFilter.All);
    setSelectedOrgId('all');
    setSelectedClientStoreId('');
    setEndDate(new Date());
    setNoOfEncounters(3);
    setSelectedRelyingPartyAdmin('unassigned');
  };

  useEffect(() => {
    callGetTasks();
  }, [taskStatus, currentPage, callGetTasks]);

  useEffect(() => {
    if (usersResult.data?.relyingPartyAdminsAll?.relyingPartyAdmins) {
      const pharmacists: any[] = [];
      usersResult.data?.relyingPartyAdminsAll?.relyingPartyAdmins.filter(admin => {
        if (admin.roleType === 'PHARMACIST' || admin.roleType === 'PHARMACIST_ADMIN') {
          pharmacists.push(admin);
        }
      });
      setRelyingPartyAdmins([...pharmacistDefaults, ...pharmacists]);
    }
  }, [usersResult.data?.relyingPartyAdminsAll?.relyingPartyAdmins]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([...defaults, ...organizationData.clientOrganizations.clientOrganizations]);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled]);

  useEffect(() => {
    setFilteredClientStores([]);
    setSelectedClientStoreId('');

    const org = clientOrganizations?.find(o => o.id === selectedOrgId);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores([...defaults, ...org.clientStores]);
      setSelectedClientStoreId('all');
    }
  }, [clientOrganizations, selectedOrgId]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  // this useEffect is affecting the pagination
  // every time rowCountState changes, page number becomes 0
  // commenting for now (need to check why was this done)
  // useEffect(() => {
  //     setCurrentPage(0)
  // }, [rowCountState])

  useEffect(() => {
    getUsers({
      variables: {
        pageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    });

    setContent(<MainSideMenu />);
  }, [getUsers, setContent]);

  useEffect(() => {
    if (data) {
      setTasks(data?.tasksByFilter?.tasks || []);
      setRowCount(data?.tasksByFilter?.meta.totalCount || 0);
    }
  }, [data]);

  return (
    <Stack spacing={1}>
      <Stack>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={4}>
            <FormControl variant="outlined" margin="dense" size="medium" sx={{ width: '100%', marginRight: 1 }}>
              <InputLabel id={'priority-input'}>Priority</InputLabel>

              <Select
                labelId={'priority'}
                label={'Priority'}
                placeholder={'select a priority'}
                value={selectedPriority}
                defaultValue={selectedPriority}
                sx={{ textTransform: 'capitalize' }}
                onChange={event => {
                  setSelectedPriority(event.target.value as PriorityTypesFilter);
                }}
              >
                {Object.values(PriorityTypesFilter).map((value: any) => (
                  <MenuItem sx={{ textTransform: 'capitalize' }} key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TruentityDatePicker
              label="Added (on or before)"
              fullWidth={false}
              sx={{ width: '100%' }}
              value={endDate}
              format="MMM DD, YYYY"
              onChange={date => setEndDate(date as Date)}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl sx={{ width: '100%' }} variant="outlined" margin="dense" size="medium">
              <InputLabel id={'encounters-input'}>No of Encounters</InputLabel>

              <Select
                labelId={'enc'}
                label={'No of Encounters'}
                placeholder={'select encounter count'}
                value={noOfEncounters}
                onChange={event => {
                  setNoOfEncounters(event.target.value as any);
                }}
              >
                {encountersCountSelectItems()}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <FormControl sx={{ width: '100%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
              <InputLabel id={'organizations-input'}>Organization</InputLabel>

              <Select
                labelId={'organizations'}
                label={'Organizations'}
                placeholder={'select an organization'}
                value={selectedOrgId}
                onChange={event => {
                  setSelectedOrgId(event.target.value);
                }}
              >
                {clientOrganizations.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            {filteredClientStores.length > 0 && (
              <FormControl sx={{ width: '100%' }} variant="outlined" fullWidth margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Location</InputLabel>

                <Select
                  labelId={'stores'}
                  label={'Stores'}
                  placeholder={'select a store'}
                  value={selectedClientStoreId}
                  onChange={event => {
                    setSelectedClientStoreId(event.target.value);
                  }}
                >
                  {filteredClientStores.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={1} pb={1}>
          {(isPharmacistAdmin || isSuperAdmin) && (
            <Grid item xs={4}>
              <FormControl sx={{ width: '100%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Assignee</InputLabel>

                <Select
                  labelId={'pharmacists'}
                  label={'Pharmacists'}
                  placeholder={'select an Pharmacist'}
                  value={selectedRelyingPartyAdmin}
                  onChange={event => {
                    setSelectedRelyingPartyAdmin(event.target.value);
                  }}
                >
                  {relyingPartyAdmins &&
                    relyingPartyAdmins.length > 0 &&
                    relyingPartyAdmins.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems={'center'}>
              <Button
                isLoading={tasksReportLoading}
                type="submit"
                size="small"
                onClick={() => {
                  if (currentPage === 0) {
                    callGetTasks();
                  } else {
                    setCurrentPage(0);
                  }
                }}
              >
                Get Tasks
              </Button>

              <Button
                startIcon={<RefreshIcon />}
                disabled={!isFiltersApplied()}
                type="reset"
                size="small"
                variant="outlined"
                onClick={() => onFiltersReset()}
              >
                Reset
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      <Paper component={Stack} direction="column">
        <Stack
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            borderBottom: 1,
            borderColor: 'divider',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab iconPosition="start" icon={tab.icon} label={tab.label} key={`tab-${index}`} id={`tab-${index}`} />
            ))}
          </Tabs>
        </Stack>

        <TruentityDataGrid
          name={'dg-my-tasks'}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          autoHeight
          rows={tasks}
          rowCount={rowCountState}
          columns={columns}
          paginationMode="server"
          loading={loadingTasks}
          onCellClick={selectedCell => {
            navigate(`/patients/${selectedCell.row.account?.truentityId}/details/medications/tasks/list?taskId=${selectedCell.row.id}`);
          }}
        />
      </Paper>
    </Stack>
  );
};

export default MyTasks;
