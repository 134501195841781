import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import type React from 'react';
import type { AssignMultipleUsersAdminsResponse, ExistingAdminUserType } from '@/graphql/user';
import { ASSIGN_MULTIPLE_USERS_AS_ADMINS } from '@/graphql/user';

type UseAssignExistingUserType = {
  handleAssignUsersSave: (relyingPartyId?: string) => void;
  selectedUsers: ExistingAdminUserType[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<ExistingAdminUserType[]>>;
  loadingAssignMultipleAdmins: boolean;
};

type UseAssignExistingUserProps = {
  onExistingUserAdd?: () => void;
};

export const useAssignExistingUser: (props: UseAssignExistingUserProps) => UseAssignExistingUserType = ({ onExistingUserAdd }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [selectedUsers, setSelectedUsers] = useState<ExistingAdminUserType[]>([]);

  const [assignMultipleUsersAsAdmins, { loading: loadingAssignMultipleAdmins }] =
    useMutation<AssignMultipleUsersAdminsResponse>(ASSIGN_MULTIPLE_USERS_AS_ADMINS);

  const handleAssignUsersSave = async (relyingPartyId?: string) => {
    try {
      const response = await assignMultipleUsersAsAdmins({
        variables: {
          existingUsersInput: selectedUsers.map(user => ({
            email: user.email,
            roleType: user.roleType,
            title: user.title
          })),
          relyingPartyId
        }
      });

      if (response.data?.assignMultipleUsersRelyingPartyAdmins?.status === 'Success') {
        enqueueSnackbar(response.data?.assignMultipleUsersRelyingPartyAdmins?.message, { variant: 'success' });
        if (onExistingUserAdd) {
          onExistingUserAdd();
        }
      } else {
        enqueueSnackbar(response?.errors && response.errors?.length > 0 ? response?.errors[0]?.message : 'Failed to assign users', {
          variant: 'error'
        });
      }
    } catch (err) {
      enqueueSnackbar((err instanceof Error && err?.message) ?? 'Failed to assign users', { variant: 'error' });
    }
  };

  return {
    handleAssignUsersSave,
    selectedUsers,
    setSelectedUsers,
    loadingAssignMultipleAdmins
  };
};

export default useAssignExistingUser;
