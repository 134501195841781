import { TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ChecklistNodeDialog from '@/components/Dialogs/ChecklistNodeDialog';
import type { AccordionProp } from '@/components/MuiAccordion';
import MuiAccordion from '@/components/MuiAccordion';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { Body1, H3, H4 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import {
  CREATE_CHECKLISTS_FOR_RELYING_PARTY_ADMIN,
  GetRelyingPartyAdminsChecklistResponse,
  GET_RELYING_PARTY_ADMIN_CHECKLISTS,
  UPDATE_RELYING_PARTY_ADMIN_CHECKLIST
} from '@/graphql/checklists';
import { ChecklistByCategoryType, ChecklistsType } from '@/types/checklists';
import { unknown } from '@/util/string';
import { useMutation, useQuery } from '@apollo/client';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import { IconButton, Paper, Skeleton, Stack } from '@mui/material';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useContext, useEffect, useMemo, useState } from 'react';
import ResourceDialog from '../../components/Dialogs/ResourceDialog';

type ChecklistCountsType = {
  allChecklistCount: number;
  completedCount: number;
  pendingCount: number;
  mandatoryCount: number;
  completedMandatoryCount: number;
};

const Checklists = () => {
  const theme = useTheme();
  const { setContent } = useContext(SideMenuContext);

  const [categories, setCategories] = useState<ChecklistByCategoryType[]>([]);
  const [accordionProps, setAccordionProps] = useState<AccordionProp | null>(null);
  const [checklistCounts, setChecklistCounts] = useState<ChecklistCountsType>({
    allChecklistCount: 0,
    completedCount: 0,
    pendingCount: 0,
    mandatoryCount: 0,
    completedMandatoryCount: 0
  });

  const [createCheckListForAdmin] = useMutation(CREATE_CHECKLISTS_FOR_RELYING_PARTY_ADMIN);
  const [updateCheckListForAdmin] = useMutation(UPDATE_RELYING_PARTY_ADMIN_CHECKLIST);
  const { data: relyingPartyAdminChecklistsData, refetch: relyingPartyAdminChecklistsRefetch } =
    useQuery<GetRelyingPartyAdminsChecklistResponse>(GET_RELYING_PARTY_ADMIN_CHECKLISTS);

  const { showModal } = useModal();

  const showPdfDialog = (title: string, resourceType: string, convertedS3Url: string) => {
    const modal = showModal(ResourceDialog, {
      title,
      resourceType,
      convertedS3Url,
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const downloadFiles = () => {
    console.log('Download files');
  };

  const showNoteDialog = (id: string, checklistName: string, notes: string) => {
    const modal = showModal(ChecklistNodeDialog, {
      id: id,
      title: `${checklistName}: Note`,
      checklistNote: notes,
      hideDialog: () => {
        modal.hide();
      },
      reloadChecklist: () => {
        relyingPartyAdminChecklistsRefetch();
      }
    });
  };

  const handleChecklist = (id: GridRowId, isCompleted: boolean) => {
    updateCheckListForAdmin({
      variables: {
        relyingPartyAdminChecklistId: id,
        isChecked: isCompleted ? false : true
      }
    }).then(res => {
      const { status } = res.data.updateRelyingPartyAdminChecklist;
      if (status === 'Success') {
        relyingPartyAdminChecklistsRefetch();
      }
    });
  };

  const renderIcon = (isCompleted: boolean) => {
    if (isCompleted) return <CheckCircleIcon fontSize="small" />;
    return <CheckCircleOutlineIcon fontSize="small" />;
  };

  const columns: GridColDef<ChecklistsType>[] = useMemo(
    () => [
      {
        field: 'checklistId',
        headerAlign: 'left',
        width: 60,
        minWidth: 60,
        cellClassName: 'check-list-column',
        headerClassName: 'check-list-column',
        align: 'left',
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => null,
        renderCell: params => {
          const isCompleted = !!params.row.checkedOn;
          return (
            <IconButton
              size="small"
              className={isCompleted ? 'selected' : ''}
              sx={{
                color: isCompleted ? green[600] : theme.palette.grey[400],
                '&:hover': {
                  color: isCompleted ? green[600] : theme.palette.grey[600]
                }
              }}
              onClick={() => handleChecklist(params.id, isCompleted)}
            >
              {renderIcon(isCompleted)}
            </IconButton>
          );
        }
      },
      {
        field: 'checklistName',
        headerName: 'Name',
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const isMandatory = params.row.checklistItem.isMandatory ? true : false;
          return `${params.row.checklistItem.name} ${!isMandatory ? '(optional)' : ''}`;
        }
      },
      {
        field: 'description',
        headerName: 'Description',
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => params.row?.checklistItem?.description ?? unknown()
      },
      {
        field: 'action',
        headerName: 'Actions',
        width: 150,
        minWidth: 150,
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          const {
            id,
            checklistItem: { name, resourceMimeType, convertedS3Url },
            notes
          } = params.row;

          return (
            <Stack direction="row">
              <IconButton onClick={() => showPdfDialog(name, resourceMimeType, convertedS3Url)} disabled={!convertedS3Url} color="info">
                <AttachFileIcon fontSize="small" />
              </IconButton>
              <IconButton onClick={() => showNoteDialog(id, name, notes)}>
                <DescriptionIcon fontSize="small" />
              </IconButton>
            </Stack>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    if (relyingPartyAdminChecklistsData?.getRelyingPartyAdminChecklists) {
      const {
        totalPendingCount,
        totalCompletedCount,
        allChecklistCount,
        totalMandatoryCount,
        completedMandatoryCount,
        checklistsByCategory
      } = relyingPartyAdminChecklistsData?.getRelyingPartyAdminChecklists;
      setChecklistCounts({
        allChecklistCount: allChecklistCount,
        completedCount: totalCompletedCount,
        pendingCount: totalPendingCount,
        mandatoryCount: totalMandatoryCount,
        completedMandatoryCount: completedMandatoryCount
      });
      setCategories(checklistsByCategory);
    }
  }, [relyingPartyAdminChecklistsData]);

  useEffect(() => {
    if (checklistCounts.allChecklistCount !== checklistCounts.completedCount + checklistCounts.pendingCount) {
      createCheckListForAdmin().then(() => relyingPartyAdminChecklistsRefetch());
    }
  }, [checklistCounts]);

  useEffect(() => {
    setAccordionProps({
      options: categories?.map((category, index) => {
        return {
          label: category.categoryName,
          secondaryCustomLabel: (
            <Stack direction="row" alignItems="center" gap={2}>
              <Body1>{`${category.totalCompletedCount} / ${category.totalChecklistCount}`} Completed</Body1>
            </Stack>
          ),
          defaultExpand: category.totalCompletedCount !== category.totalChecklistCount,
          content: (
            <Stack padding={2} spacing={2}>
              <TruentityDataGrid
                name="checklist-data-grid"
                columns={columns}
                autoHeight
                rows={category.checklists}
                hideFooter
                disableRowSelectionOnClick
              />
            </Stack>
          )
        };
      })
    });
  }, [categories]);

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent]);

  return (
    <Stack spacing={2}>
      <Paper sx={{ padding: 2 }} elevation={0}>
        <H3>Resources</H3>
      </Paper>
      <Paper sx={{ padding: 2 }} elevation={0}>
        {!accordionProps && <Skeleton variant="rectangular" />}
        {accordionProps?.options && accordionProps?.options?.length <= 0 && <H4>No resources are available</H4>}
        {accordionProps && <MuiAccordion {...accordionProps} />}
      </Paper>
    </Stack>
  );
};

export default Checklists;
