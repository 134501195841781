import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { UPDATE_PROVIDER } from '@/graphql/remotePatientMonitoring';
import { useMutation } from '@apollo/client';
import { DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
};

type FormValues = {
  id: string;
  name: string;
};

const defaultValues: FormValues = {
  id: '',
  name: ''
};

const ProviderUpdateDialog = ({ title, hideDialog, ...props }: Props) => {
  const {
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();

  const [updateProvider, { data: updateProviderData, loading: updateProviderLoading, error: updateProviderError }] =
    useMutation(UPDATE_PROVIDER);

  const [firstNameValue, setFirstNameValue] = useState<string>('');
  const [lastNameValue, setLastNameValue] = useState<string>('');
  const [npiValue, setNpiValue] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [faxValue, setFaxValue] = useState<string>('');
  const [idValue, setIdValue] = useState<string>('');

  useEffect(() => {
    const fields = props.id?.split(',');
    if (fields) {
      setIdValue(fields[0]);
      setFirstNameValue(fields[1]);
      setLastNameValue(fields[2]);
      setNpiValue(fields[3]);
      setEmailValue(fields[4]);
      setPhoneValue(fields[5]);
      setFaxValue(fields[6]);
    }
  }, [props.id]);

  const onSubmit: SubmitHandler<FormValues> = () => handleSubmitImpl();

  const handleSubmitImpl = async () => {
    try {
      await updateProvider({
        variables: {
          id: idValue,
          input: {
            firstName: firstNameValue,
            lastName: lastNameValue,
            npiNumber: npiValue,
            email: emailValue ?? '',
            phone: phoneValue ?? '',
            fax: faxValue ?? ''
          }
        }
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to update provider', {
        variant: 'error'
      });
    }
  };

  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPhoneValue(event?.target?.value);
  };

  const handleFaxChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFaxValue(event?.target?.value);
  };

  useEffect(() => {
    if (updateProviderData?.updateProvider?.status === 'Success') {
      enqueueSnackbar('Successfully Updated Provider', {
        variant: 'success'
      });
      hideDialog();
    }
  }, [updateProviderData]);

  useEffect(() => {
    if (updateProviderError) {
      enqueueSnackbar(updateProviderError?.message ?? 'Unable to update provider', {
        variant: 'error'
      });
    }
  }, [updateProviderError]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack component={'form'} spacing={1} marginTop={'10px'} onSubmit={handleSubmit(onSubmit)}>
          <Stack direction={'row'} spacing={1}>
            <TruentityTextField
              autoFocus
              required
              onChange={input => setFirstNameValue(input?.target?.value)}
              value={firstNameValue}
              label={'First Name'}
            />
            <TruentityTextField
              required
              onChange={input => setLastNameValue(input?.target?.value)}
              value={lastNameValue}
              label={'Last Name'}
            />
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <TruentityTextField required onChange={input => setNpiValue(input?.target?.value)} value={npiValue} label={'NPI Number'} />
            <TruentityTextField type={'email'} onChange={input => setEmailValue(input?.target?.value)} value={emailValue} label={'Email'} />
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <TruentityPhoneNumber editable={true} onChange={handlePhoneChange} value={phoneValue} label={'Phone Number'} />
            <TruentityPhoneNumber editable={true} onChange={handleFaxChange} value={faxValue} label={'Fax'} />
          </Stack>
          <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} mt={2}>
            <Button type="submit" isLoading={updateProviderLoading} a11yLabel="Save" appearance="primary" />
            <Button
              type="reset"
              a11yLabel="Cancel"
              appearance="outline"
              onClick={() => {
                reset();
                hideDialog();
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default ProviderUpdateDialog;
