import { ADD_CLIENT_STORE } from '@/graphql/administration';
import { useMutation } from '@apollo/client';
import { DialogActions, DialogContent, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import BaseDialog, { BaseDialogProps } from './BaseDialog';

interface StoreAddDialogProps extends BaseDialogProps {
  hideDialog: () => void;
}

interface FormValues {
  name: string;
  nameTag: string;
}

const defaultValues: FormValues = {
  name: '',
  nameTag: ''
};

const StoreAddDialog: React.FC<StoreAddDialogProps> = ({ title, hideDialog, ...props }) => {
  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues });
  const { enqueueSnackbar } = useSnackbar();
  const [addStore] = useMutation(ADD_CLIENT_STORE);

  const onSubmit = async (formData: FormValues) => {
    try {
      const result = await addStore({
        variables: {
          name: formData.name,
          nameTag: formData.nameTag,
          clientOrgId: props.id
        }
      });

      const data = result.data!.addClientStore;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      enqueueSnackbar(data.message, {
        variant
      });
    } catch (err) {
      console.error(err);

      enqueueSnackbar('Unable to add location', {
        variant: 'error'
      });
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Stack>
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField autoFocus required onChange={onChange} value={value} label={'Name'} />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Controller
                  control={control}
                  name="nameTag"
                  render={({ field: { onChange, value } }) => (
                    <TruentityTextField required onChange={onChange} value={value} label={'Name Tag'} />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <DialogActions sx={{ justifyContent: 'start', padding: '30px 0 0 0' }}>
                <Button type="submit" a11yLabel="Save" appearance="primary">
                  Save
                </Button>
                <Button
                  type="button"
                  a11yLabel="Cancel"
                  appearance="outline"
                  onClick={() => {
                    reset();
                    hideDialog();
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default StoreAddDialog;
