import config from '@/config';
import {
  GET_ADMIN_CONTACT_NUMBERS,
  GET_MESSAGES_BY_CONTACT,
  GET_MESSAGE_TEMPLATES,
  READ_MESSAGES,
  SEND_MESSAGE
} from '@/graphql/accountMessages';
import type {
  AssistantActions,
  ConversationMessage,
  CurrentPatient,
  GetMessageTemplatesResponse,
  MessagesByDate,
  MessagesResponse,
  MessageTemplate,
  MessageTemplateItem,
  RelyingPartyAdminContactsResponse
} from '@/types/chat';
import { UserTypes } from '@/types/chat';
import { getAccountPrefix, getAccountTempAccessUrl, getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getDateStringForMessage, toDate, today } from '@/util/date';
import { convertToSentenceCase } from '@/util/string';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import type { SelectChangeEvent } from '@mui/material';
import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

interface State {
  isMessageDrawerOpen: boolean;
  messagesByDate: MessagesByDate;
  lastMessage: ConversationMessage | null;
  patient: CurrentPatient | null;
  error: string | null;
  messageTemplates: MessageTemplate[];
  currentMessageTemplate: MessageTemplate | null;
  userContactNumbersError: string | null;
  currentUserContactNumbers: { relyingPartyAdminContacts: Array<{ value: string }> } | undefined;
  selectedContactNumber: string;
}

interface MessageContextValue extends State {
  setIsMessageDrawerOpen: (val: boolean) => void;
  sendMessage: (message: SendMessageProps) => Promise<void>;
  setPatient: (val: CurrentPatient | null) => void;
  setError: (val: string | null) => void;
  setCurrentMessageTemplate: (val: MessageTemplate | null) => void;
  handleContactNumberSelect: (event: SelectChangeEvent) => void;
}

interface MessageProviderProps {
  children: ReactNode;
}

const initialValue: MessageContextValue = {
  isMessageDrawerOpen: false,
  setIsMessageDrawerOpen: () => {},
  messagesByDate: {},
  lastMessage: null,
  messageTemplates: [],
  currentUserContactNumbers: undefined,
  selectedContactNumber: '',
  currentMessageTemplate: null,
  userContactNumbersError: null,
  sendMessage: () => Promise.resolve(),
  setCurrentMessageTemplate: () => {},
  setPatient: () => {},
  handleContactNumberSelect: () => {},
  patient: null,
  error: null,
  setError: () => {}
};

interface SendMessageProps {
  message: string;
  createdAt: Date;
  timestamp: number;
  type: UserTypes.bot | UserTypes.user | UserTypes.otherUser;
  action?: AssistantActions;
}

const MessageContext = createContext<MessageContextValue>(initialValue);

export const MessageProvider: FC<MessageProviderProps> = ({ children }) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const [selectedContactNumber, setSelectedContactNumber] = useState<string>('');
  const [patient, setPatient] = useState<CurrentPatient | null>(null);
  const [lastMessage, setLastMessage] = useState<ConversationMessage | null>(null);
  const [messagesByDate, setMessagesByDate] = useState<MessagesByDate>({});
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplate[]>([]);
  const [userContactNumbersError, setUserContactNumbersError] = useState<string | null>(null);
  const [currentMessageTemplate, setCurrentMessageTemplate] = useState<MessageTemplate | null>(null);
  const [isMessageDrawerOpen, setIsMessageDrawerOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [sendMessageAPI] = useMutation(SEND_MESSAGE);
  const [readMessageAPI] = useMutation(READ_MESSAGES);

  const [
    getAdminContactNumbers,
    { data: currentUserContactNumbers, error: currentUserContactNumbersError, refetch: refetchAdminUserContactNumbers }
  ] = useLazyQuery<RelyingPartyAdminContactsResponse>(GET_ADMIN_CONTACT_NUMBERS);
  // query for messages from 5 seconds polling
  const { data: messagesResponse } = useQuery<MessagesResponse>(GET_MESSAGES_BY_CONTACT, {
    variables: {
      truentityId: patient?.patientId,
      contactNumber: selectedContactNumber
    },
    skip: !(patient && selectedContactNumber && currentUser),
    pollInterval: config.POLLING_INTERVAL_MS
  });

  const { data: messageTemplatesResponse } = useQuery<GetMessageTemplatesResponse>(GET_MESSAGE_TEMPLATES, {
    skip: !currentUser
  });

  const fetchAdminUserContactNumbers = async () => {
    try {
      await getAdminContactNumbers();
    } catch (err: any) {
      console.error(err);
      setUserContactNumbersError(err?.message ?? 'Could not load your contact numbers');
    }
  };

  const checkTemplatesDisabled = (category: string, patient: CurrentPatient | null) => {
    const jsonSetting = JSON.parse(patient?.setting || '{}');
    const tmpVitalReadingsAccess = jsonSetting.temporaryVitalReadingAccess || false;
    const disabled = category === 'TEMPORARY_RPM_DEVICE_READINGS_URL' && !tmpVitalReadingsAccess;
    if (disabled) {
      return {
        disabled,
        disabledText:
          'To enable this template, please activate the "Allow temporary access to recent vitals readings" setting under the Patient Settings configurations.'
      };
    }
    return false;
  };

  useMemo(() => {
    if (currentUserContactNumbers && currentUserContactNumbers?.relyingPartyAdminContacts?.length > 0) {
      setSelectedContactNumber(currentUserContactNumbers?.relyingPartyAdminContacts[0]?.value ?? '');
      setUserContactNumbersError(null);
    }
  }, [currentUserContactNumbers]);

  useEffect(() => {
    if (messageTemplatesResponse) {
      const fetchedMessageTemplates = messageTemplatesResponse.messageTemplateItemsByCategory.map((messageTemplate: MessageTemplate) => {
        const messageTemplatesItems: MessageTemplateItem[] = messageTemplate.messageTemplateItems.map(
          (templateItem): MessageTemplateItem => {
            const { text } = templateItem;
            const accountName = `${getAccountPrefix(patient)} ${patient?.lastName}` ?? '';
            const relyingPartyAdminName = `${currentUser?.user.firstName} ${currentUser?.user?.lastName}`;
            const tempAccessUrl = getAccountTempAccessUrl(patient?.patientId, currentUser?.relyingParty.orgHandle);
            const temporary_account_vital_access_url = `<a href='${tempAccessUrl}'>${tempAccessUrl}<a>`;
            const today = new Date().toLocaleDateString();

            const replacedText = text
              .replace(/{account.name}/g, accountName)
              .replace(/{relying_party_admin.name}/g, relyingPartyAdminName)
              .replace(/{today}/g, today)
              .replace(/{temporary_account_vital_access_url}/g, temporary_account_vital_access_url);

            return {
              ...templateItem,
              text: replacedText
            };
          }
        );
        return {
          ...messageTemplate,
          id: messageTemplate.category,
          category: convertToSentenceCase(messageTemplate.category),
          messageTemplateItems: messageTemplatesItems,
          isDisabled: checkTemplatesDisabled(messageTemplate.category, patient)
        };
      });
      setMessageTemplates(fetchedMessageTemplates);
    }
  }, [messageTemplatesResponse, patient]);

  useMemo(() => {
    if (messagesResponse?.accountMessagesByContact) {
      const mappedMessagesByDate: MessagesByDate = {};
      messagesResponse.accountMessagesByContact.forEach(message => {
        const messageDate = getDateStringForMessage(toDate(message.createdAt));
        const isAdmin = Boolean(message.sourceType === 'RelyingPartyAdmin');
        const mappedMessage = {
          timestamp: toDate(message.createdAt).getTime(),
          message: message.messageBody,
          id: message.id,
          user: isAdmin
            ? {
                name: getAccountUserFullName(currentUser?.user),
                avatar: getAccountUserFullName(currentUser?.user)
              }
            : {
                name: patient?.patientName ?? '',
                avatar: patient?.patientName ?? ''
              },
          type: isAdmin ? UserTypes.user : UserTypes.otherUser,
          createdAt: new Date(message.createdAt)
        };
        if (mappedMessagesByDate[messageDate]) {
          mappedMessagesByDate[messageDate].push(mappedMessage);
        } else {
          mappedMessagesByDate[messageDate] = [mappedMessage];
        }
      });
      setMessagesByDate(mappedMessagesByDate);
    }
    return {};
  }, [messagesResponse, selectedContactNumber, patient]);

  // read all messages when opening message drawer
  useEffect(() => {
    if (isMessageDrawerOpen && patient?.patientId) {
      const readAllMessages = async () => {
        try {
          await readMessageAPI({
            variables: {
              truentityId: patient?.patientId
            }
          });
        } catch (err) {
          console.error(err);
        }
      };

      readAllMessages();
    }
  }, [isMessageDrawerOpen, patient, messagesByDate]);

  useEffect(() => {
    const messages = Object.values(messagesByDate).flat();
    if (messages?.length > 0) {
      setLastMessage(messages[0]);
    } else {
      setLastMessage(null);
    }
  }, [messagesByDate]);

  const sendMessage = async (message: SendMessageProps) => {
    if (patient) {
      try {
        const todayDate = today().toDate();
        setMessagesByDate(messagesByDate => {
          const messageDate = getDateStringForMessage(todayDate);
          const messages = messagesByDate[messageDate] ?? [];
          const newMessage = {
            id: `${message.timestamp}`,
            user: {
              name: getAccountUserFullName(currentUser?.user),
              avatar: getAccountUserFullName(currentUser?.user)
            },
            ...message
          };
          if (messages?.length > 0) {
            return {
              ...messagesByDate,
              [messageDate]: [newMessage, ...messages]
            };
          } else {
            return {
              [messageDate]: [newMessage],
              ...messagesByDate
            };
          }
        });

        const response = await sendMessageAPI({
          variables: {
            truentityId: patient?.patientId,
            textMessage: message.message,
            toPhoneNumber: patient?.patientContactNo,
            fromPhoneNumber: selectedContactNumber
          }
        });

        if (response?.data?.sendMessage?.status === 'Failure') {
          setError('Error could not send message');
        }
      } catch (err) {
        setError('Error could not send message');
      }
    }
  };

  const handleContactNumberSelect = (event: SelectChangeEvent) => {
    setSelectedContactNumber(event.target.value);
  };

  useEffect(() => {
    if (currentUserContactNumbersError) {
      setUserContactNumbersError(currentUserContactNumbersError?.message ?? 'Could not load your contact numbers');
    } else {
      setUserContactNumbersError(null);
    }
  }, [userContactNumbersError]);

  useEffect(() => {
    if (currentUser) {
      fetchAdminUserContactNumbers();
    }
  }, [currentUser]);

  useEffect(() => {
    if (isMessageDrawerOpen) {
      refetchAdminUserContactNumbers();
    }
  }, [isMessageDrawerOpen]);

  return (
    <MessageContext.Provider
      value={{
        isMessageDrawerOpen,
        setIsMessageDrawerOpen,
        userContactNumbersError,
        messageTemplates,
        currentMessageTemplate,
        currentUserContactNumbers,
        selectedContactNumber,
        lastMessage,
        messagesByDate,
        sendMessage,
        patient,
        setPatient,
        handleContactNumberSelect,
        setCurrentMessageTemplate,
        error,
        setError
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export default MessageContext;
