import type React from 'react';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Alert, Chip, Stack } from '@mui/material';
import { H3 } from '@/components/Typography';
import { Controller, type SubmitHandler, useForm } from 'react-hook-form';
import TruentityTextField from '@/components/TruentityTextField';
import Button from '@/components/Button';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useLazyQuery } from '@apollo/client';
import type { ExistingAdminUserType, GetExistingUsersResponse } from '@/graphql/user';
import { GET_EXISTING_USERS } from '@/graphql/user';
import { useSnackbar } from 'notistack';
import type { GridRowSelectionModel } from '@mui/x-data-grid/models/gridRowSelectionModel';
import { mapRolesToText, mapTitlesToText } from '@/util/adminstration';
import parse from 'html-react-parser';
import { UserFormMode } from '@/types/utils';
import { Role } from '@/types/admin';

type LookupFormValuesType = {
  firstName?: string;
  lastName?: string;
};

const lookupFormDefault: LookupFormValuesType = {
  firstName: '',
  lastName: ''
};

type AssignUserFormProps = {
  hideHeader?: boolean;
  selectedUsers: ExistingAdminUserType[];
  setSelectedUsers: (users: ExistingAdminUserType[]) => void;
  companyName?: string;
  formMode: UserFormMode;
};

const AssignUserForm: React.FC<AssignUserFormProps> = ({ selectedUsers, setSelectedUsers, companyName, formMode, hideHeader = false }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(DEFAULT_PAGE_SIZE);

  const { getValues, control, reset: resetLookupForm, handleSubmit } = useForm<LookupFormValuesType>({ defaultValues: lookupFormDefault });

  const [getExistingUsers, { data: existingUsers, loading: loadingExistingUsers, error: errorExistingUsers }] =
    useLazyQuery<GetExistingUsersResponse>(GET_EXISTING_USERS, {
      fetchPolicy: 'cache-and-network'
    });

  const onSubmit: SubmitHandler<LookupFormValuesType> = data => fetchExistingUsers(data);

  const fetchExistingUsers = async (values: LookupFormValuesType) => {
    getExistingUsers({
      variables: {
        pageSize,
        pageNum: currentPage + 1,
        filterOptions: {
          firstName: values.firstName,
          lastName: values.lastName
        }
      }
    });
  };

  const handleResetForm = () => {
    resetLookupForm();
    setCurrentPage(0);
    fetchExistingUsers(lookupFormDefault);
  };

  const handleRowSelection = (e: GridRowSelectionModel) => {
    setRowSelectionModel(e);
    setSelectedUsers(
      ([...selectedUsers, ...(existingUsers?.getExistingUsers?.users ?? [])].filter(user => e.includes(user.id)) ?? []).map(
        ({ id, email, roleType, title }) => ({
          id,
          email,
          roleType,
          title
        })
      )
    );
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'firstName',
        headerName: 'First Name',
        sortable: true,
        flex: 1
      },
      {
        field: 'lastName',
        headerName: 'Last Name',
        sortable: true,
        flex: 1
      },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        flex: 2
      },
      {
        field: 'roleType',
        headerName: 'Role',
        sortable: true,
        renderCell: ({ value }) => <Chip label={mapRolesToText(value)} color="default" variant="filled" />,
        flex: 1
      },
      {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        renderCell: ({ value }) => <Chip label={mapTitlesToText(value)} color="default" variant="filled" />,
        flex: 1
      }
    ],
    []
  );

  const renderInfoBanner = useCallback(() => {
    if (companyName) {
      if (rowSelectionModel?.length > 0) {
        return (
          <Alert severity="info">{parse(`Selected ${rowSelectionModel.length} user(s) to assign to Company: <b>${companyName}</b>`)}</Alert>
        );
      } else {
        return <Alert severity="info">{parse(`Select one or more existing users to Company: <b>${companyName}</b>`)}</Alert>;
      }
    } else if (formMode === 'NEW_COMPANY') {
      return <Alert severity="error">Company not found. Please save a Company to assign users.</Alert>;
    } else {
      return <></>;
    }
  }, [companyName, rowSelectionModel]);

  useEffect(() => {
    fetchExistingUsers({
      firstName: getValues('firstName') ?? '',
      lastName: getValues('lastName') ?? ''
    });
  }, [currentPage, pageSize]);

  useEffect(() => {
    if (errorExistingUsers) {
      enqueueSnackbar(errorExistingUsers.message ?? 'Could not get existing users.', { variant: 'error' });
    }
  }, [errorExistingUsers]);

  useEffect(() => {
    if (
      formMode === UserFormMode.NEW_COMPANY &&
      existingUsers?.getExistingUsers?.users &&
      selectedUsers.length === 0 &&
      getValues('firstName') === ''
    ) {
      handleRowSelection(existingUsers.getExistingUsers.users.filter(user => user.roleType === Role.SUPER).map(user => user.id));
    }
  }, [existingUsers, formMode, getValues]);

  return (
    <Stack spacing={2} width={'100%'} height={'auto'}>
      {!hideHeader && <H3>Assign User(s)</H3>}
      <Stack
        display={'flex'}
        component={'form'}
        flexWrap={'wrap'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField
              sx={{ m: 1, width: 200 }}
              size={'medium'}
              autoFocus
              onChange={onChange}
              value={value}
              label={'First Name'}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onChange, value } }) => (
            <TruentityTextField sx={{ m: 1, width: 200 }} size={'medium'} onChange={onChange} value={value} label={'Last Name'} />
          )}
        />
        <Button sx={{ m: 1, p: 1 }} startIcon={<SearchIcon />} label={'Search'} type="submit" size="small" />
        <Button sx={{ m: 1, p: 1 }} startIcon={<RestartAltIcon />} label={'Reset'} onClick={handleResetForm} size="small" />
      </Stack>
      <Stack sx={{ m: 1 }}>{renderInfoBanner()}</Stack>
      <Stack spacing={2}>
        <TruentityDataGrid
          sx={{
            minHeight: 250
          }}
          checkboxSelection={true}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={handleRowSelection}
          name={'existing-users-dg'}
          columns={columns}
          rows={existingUsers?.getExistingUsers?.users ?? []}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={loadingExistingUsers}
          rowCount={existingUsers?.getExistingUsers?.meta?.totalCount ?? 0}
          paginationMode="server"
          keepNonExistentRowsSelected
        />
      </Stack>
    </Stack>
  );
};

export default AssignUserForm;
