import Button from '@/components/Button';
import RpmApprovalStatusChip from '@/components/ChipLabel/RpmApprovalStatusChip';
import { MEDIUM_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import InformationDialog from '@/components/Dialogs/InformationDialog';
import RpmReportPreviewDialog from '@/components/Dialogs/RpmReportPreviewDialog';
import ScheduleEnrollmentDialog from '@/components/Dialogs/ScheduleEnrollmentDialog';
import MuiMenu from '@/components/MuiMenu';
import StarredPatient from '@/components/StarredPatient';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, H1, H4 } from '@/components/Typography';
import type { GetPatientDetailResponse } from '@/graphql/account';
import { DELETE_ACCOUNT_FOLLOWUP, FollowUpTypes, GET_PATIENT_DETAIL, REFRESH_ENROLLED_ACCOUNTS_SNAPSHOT } from '@/graphql/account';
import type {
  GetAccountsByRpmStatusResponse,
  GetRpmAccountsCountByStatusResponse,
  GetRpmEnrolledAccountsResponse,
  ReviewAccountsRpmReportsResponse
} from '@/graphql/remotePatientMonitoring';
import {
  GET_RPM_ACCOUNTS_BY_RPM_STATUS,
  GET_RPM_ACCOUNTS_COUNT_BY_STATUS,
  GET_RPM_ENROLLED_ACCOUNTS,
  REVIEW_ACCOUNTS_RPM_REPORTS
} from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { AccountNameOrder } from '@/types/accountProfile';
import { MedicalServices } from '@/types/admin';
import type {
  AccountsMonitoringDeviceType,
  ParsedDeviceType,
  RpmAccountSnapshotType,
  RpmStatusTypeTab
} from '@/types/remotePatientMonitoring';
import { ContactedType, ReviewSubmitTypeEnum, RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { addOneYearToDate, today } from '@/util/date';
import { formatDate, formatDateAndTime, formatSecondsToHMS, formatTime, getCurrentDate } from '@/util/format';
import { getCustomRowHeight, getReadingTypeValue } from '@/util/get';
import {
  DEFAULT_DEVICE_LENGTH,
  extractValueFromJson,
  getColorForMonthlyTimeSpent,
  getColorForTotalReadings,
  mapEnrolledAccounts
} from '@/util/rpm';
import { useLazyQuery, useMutation } from '@apollo/client';
import { TapAndPlayOutlined } from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { Box, Chip, IconButton, Stack, Tab, Tabs, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import type {
  GridCellParams,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowId,
  GridSortItem,
  GridSortModel
} from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import type { PatientsData } from '../Patients/patients';
import RpmPatientInfoDrawer from './RpmPatientDrawer';

const rpmPatientStatusTabBase: RpmStatusTypeTab[] = [
  {
    status: RpmStatusTypes.IS_CANDIDATE,
    label: 'Candidates',
    path: 'candidate',
    icon: <PersonIcon />
  },
  {
    status: RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT,
    label: 'Scheduled for Enrollment',
    path: 'scheduledForEnrollment',
    icon: <PendingActionsIcon />
  },
  {
    status: RpmStatusTypes.ENROLLED,
    label: 'Enrolled',
    path: 'enrolled',
    icon: <AssignmentTurnedInIcon />
  }
];

const columnVisibility = {
  candidate: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: false,
    dateUnenrolled: false,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: true,
    cancelScheduledForEnrollmentAction: false,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true
  },
  scheduledForEnrollment: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: false,
    dateUnenrolled: false,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: true,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true
  },
  enrolled: {
    devices: true,
    hardwareId: true,
    lastReading: true,
    lastReadingAt: true,
    lastReadingValue: true,
    dateOfLastReview: true,
    lastContacted: true,
    dateEnrolled: true,
    dateUnenrolled: false,
    rpmApprovalStatus: true,
    reportActions: true,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: false,
    lastContactedAt: true,
    monthlyTotalReadings: true,
    monthlyTimeSpent: true,
    dateOfBirth: false,
    phone: false,
    zipcode: false,
    meds: false
  },
  unenrolled: {
    devices: false,
    hardwareId: false,
    lastReading: false,
    lastReadingAt: false,
    lastReadingValue: false,
    dateOfLastReview: false,
    dateEnrolled: true,
    dateUnenrolled: true,
    lastContacted: false,
    reportActions: false,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: false,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    rpmApprovalStatus: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true
  }
};

type SortModalDefaultsKeyTypes =
  | RpmStatusTypes.ENROLLED
  | RpmStatusTypes.UNENROLLED
  | RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT
  | RpmStatusTypes.IS_CANDIDATE;

type DefaultSortModelsTypes = {
  [key in SortModalDefaultsKeyTypes]: GridSortItem;
};

const defaultSortModels: DefaultSortModelsTypes = {
  [RpmStatusTypes.ENROLLED]: {
    field: 'dateEnrolled',
    sort: 'desc'
  },
  [RpmStatusTypes.UNENROLLED]: {
    field: 'dateUnenrolled',
    sort: 'desc'
  },
  [RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT]: {
    field: 'onboardedOn',
    sort: 'desc'
  },
  [RpmStatusTypes.IS_CANDIDATE]: {
    field: 'onboardedOn',
    sort: 'desc'
  }
};

type RenderDeviceReadingsProps = {
  device: ParsedDeviceType;
  truentityId: string;
};

const RpmPatients = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const currentMonthYear = useMemo(() => formatTime(getCurrentDate(), 'MMM YYYY'), []);

  const [tab, setTab] = useState<number>(2);
  const [rows, setRows] = useState<RpmAccountSnapshotType[]>([]);
  const [selectedCandidate, setSelectedCandidate] = useState<PatientsData>({} as PatientsData);
  const [monthAndYear, setMonthAndYear] = useState<string>(currentMonthYear);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const [totalRowCount, setTotalRowCount] = useState(MEDIUM_PAGE_SIZE);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([defaultSortModels[RpmStatusTypes.ENROLLED]]);

  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] });

  const [unenrolledAccountsCount, setUnenrolledAccountsCount] = useState<number>(0);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    devices: false,
    hardwareId: false,
    lastReading: false,
    scheduledForEnrollmentAction: false,
    cancelScheduledForEnrollmentAction: false,
    dateOfLastReview: false,
    dateEnrolled: false,
    dateUnenrolled: false,
    lastContacted: false,
    monthlyTotalReadings: false,
    monthlyTimeSpent: false,
    dateOfBirth: true,
    phone: true,
    zipcode: true,
    meds: true
  });

  const startDate = useMemo(() => '2020-01-01', []);
  const endDate = useMemo(() => addOneYearToDate(today()), []);
  const isCurrentMonth = useMemo(() => monthAndYear === currentMonthYear, [currentMonthYear, monthAndYear]);

  const [getRpmAccountsCountByStatus] = useLazyQuery<GetRpmAccountsCountByStatusResponse>(GET_RPM_ACCOUNTS_COUNT_BY_STATUS, {
    fetchPolicy: 'cache-and-network'
  });

  const [
    callGetAccountByRpmStatus,
    { data: rpmAccountsByStatusQueryData, loading: rpmAccountsByStatusQueryLoading, refetch: rpmAccountsByStatusQueryRefetch }
  ] = useLazyQuery<GetAccountsByRpmStatusResponse>(GET_RPM_ACCOUNTS_BY_RPM_STATUS, {
    fetchPolicy: 'cache-and-network'
  });
  const [
    callGetRpmEnrolledAccounts,
    { data: rpmEnrolledAccountsData, loading: rpmEnrolledAccountsLoading, refetch: refetchEnrolledAccounts }
  ] = useLazyQuery<GetRpmEnrolledAccountsResponse>(GET_RPM_ENROLLED_ACCOUNTS, { fetchPolicy: 'cache-and-network' });
  const [getPatientDetail] = useLazyQuery<GetPatientDetailResponse>(GET_PATIENT_DETAIL);

  const [refreshEnrolledAccountsSnapshots, { loading: refreshSnapshotLoading }] = useMutation(REFRESH_ENROLLED_ACCOUNTS_SNAPSHOT, {
    onCompleted: () => refetchEnrolledAccounts()
  });

  const [reviewAccountsRpmReports, { loading: reviewRpmReportsLoading }] =
    useMutation<ReviewAccountsRpmReportsResponse>(REVIEW_ACCOUNTS_RPM_REPORTS);

  const [deleteFollowup] = useMutation(DELETE_ACCOUNT_FOLLOWUP);

  const handleRowClick = (param: GridCellParams) => {
    if (!['reportActions', 'scheduledForEnrollmentAction', 'cancelScheduledForEnrollmentAction', '__check__'].includes(param.field)) {
      onSelectPatient(param.row as PatientsData);
    }
  };

  const handleScheduleForEnrollment = (data: RpmAccountSnapshotType) => {
    const patientName = getAccountUserFullName(data.user, AccountNameOrder.LAST_FIRST);

    const modal = showModal(ScheduleEnrollmentDialog, {
      title: `Schedule for Enrollment: ${patientName}`,
      patientInfo: data,
      hideDialog: () => modal.hide(),
      refetch: rpmAccountsByStatusQueryRefetch
    });
  };

  const handleCancelScheduleForEnrollment = useCallback(
    (data: RpmAccountSnapshotType) => {
      const allInitialVisitFollowupIds =
        data?.accountsFollowupReminders
          ?.filter(followup => followup.type === FollowUpTypes.RPM_INITIAL_VISIT)
          .map(followup => followup.id) ?? [];

      const modal = showModal(ConfirmDialog, {
        title: 'Confirmation Required',
        message: `There ${
          allInitialVisitFollowupIds?.length > 1 ? `are ${allInitialVisitFollowupIds.length} visits` : 'is 1 visit'
        } scheduled for ${getAccountUserFullName(data?.user)}. This action will modify the calendar items. Are you sure?`,
        onAgree: async () => {
          await deleteFollowup({ variables: { followUpIds: allInitialVisitFollowupIds } })
            .then(response => {
              const data = response.data.removeAccountFollowup;
              const variant = data?.status === 'Success' ? 'success' : 'error';
              const message = data?.status === 'Success' ? 'Visit(s) deleted successfully' : data?.message;
              enqueueSnackbar(message, { variant });
              modal.hide();
              rpmAccountsByStatusQueryRefetch();
            })
            .catch(() => {
              enqueueSnackbar('Failed to delete visit(s)', {
                variant: 'error'
              });
              modal.hide();
            });
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [deleteFollowup, enqueueSnackbar, rpmAccountsByStatusQueryRefetch, showModal]
  );

  const onSelectPatient = useCallback(
    async (data: PatientsData) => {
      if (data?.truentityId) {
        try {
          const patientResponse = await getPatientDetail({
            variables: {
              truentityId: data?.truentityId
            }
          });

          if (!patientResponse.data?.accountGet) {
            throw Error('Unable to find patient');
          } else {
            sendPatientDataToDrawer({ ...patientResponse.data?.accountGet });
          }
        } catch (err) {
          console.error(err);
          enqueueSnackbar('Unable to retrieve patient data', {
            variant: 'error'
          });
        }
      }
    },
    [enqueueSnackbar, getPatientDetail]
  );

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const onRPMReportClick = useCallback(
    (truentityId: string) => {
      const modal = showModal(RpmReportPreviewDialog, {
        truentityId: truentityId,
        filterMonthYear: monthAndYear,
        hideDialog: () => {
          modal.hide();
        },
        title: 'RPM Report Preview'
      });
    },
    [showModal, monthAndYear]
  );

  const renderDeviceReadingValues = useCallback(
    ({ device, truentityId }: RenderDeviceReadingsProps) => {
      return (
        <Stack
          flexDirection="column"
          flex={1}
          flexWrap={'nowrap'}
          key={`${device?.id}`}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          width={'100%'}
        >
          {device?.latestReadings?.length === 0
            ? 'N/A'
            : device?.latestReadings?.map((reading, index) => (
                <Box key={`${monthAndYear}-${truentityId}-${device?.id}-${index}-value`}>
                  {reading ? `${getReadingTypeValue(reading)}` : 'N/A'}
                </Box>
              ))}
        </Stack>
      );
    },
    [monthAndYear]
  );

  const renderDeviceReadingDates = useCallback(
    ({ device, truentityId }: RenderDeviceReadingsProps) => {
      return (
        <Stack
          flex={1}
          flexDirection="column"
          flexWrap={'nowrap'}
          key={`${device?.id}`}
          justifyContent={'flex-start'}
          alignItems={'stretch'}
          width={'100%'}
        >
          {device?.latestReadings?.length === 0
            ? 'N/A'
            : device?.latestReadings?.map((reading, index) => (
                <Box key={`${monthAndYear}-${truentityId}-${device?.id}-${index}-date`} textTransform="capitalize">
                  {reading?.recordedAt ? `${formatDateAndTime(reading?.recordedAt)}` : 'N/A'}
                </Box>
              ))}
        </Stack>
      );
    },
    [monthAndYear]
  );

  const tempVitalAccessEnabled = (settings: string) => {
    const extractedValue = extractValueFromJson(settings, 'temporaryVitalReadingAccess');
    if (!extractedValue) return false;
    return extractedValue;
  };

  const columns: GridColDef<RpmAccountSnapshotType>[] = useMemo(
    () => [
      {
        field: 'truentityId',
        headerAlign: 'left',
        width: 30,
        minWidth: 30,
        cellClassName: 'star-column',
        headerClassName: 'star-column',
        align: 'left',
        sortable: false,
        disableColumnMenu: true,
        renderHeader: () => null,
        renderCell: params => {
          return <StarredPatient truentityId={params.value} />;
        }
      },
      {
        field: 'patientName',
        headerName: 'Patient Name (Last, First)',
        sortable: true,
        flex: 2,
        minWidth: 135,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          const name = getAccountUserFullName(params.row.user, AccountNameOrder.LAST_FIRST);
          const status = params.row.rpmStatus;
          const accountMonitoringDevices = params.row.devices as AccountsMonitoringDeviceType[];
          const redirectUrl = getAccountRedirectUrl(params?.row?.truentityId, MedicalServices.RPM, status, accountMonitoringDevices) ?? '';

          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="center" justifyContent={'start'}>
              <Box flex={1} sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <Link to={redirectUrl}>{name}</Link>
              </Box>
              {tempVitalAccessEnabled(params?.row?.setting ?? '{}') && (
                <Box flexBasis={'5px'}>
                  <TapAndPlayOutlined sx={{ fontSize: '1rem' }} />
                </Box>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.birthDate)
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        sortable: true,
        flex: 2,
        minWidth: 110,
        width: 110,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => (params.row.healthPlan ? params.row.healthPlan.orgName : '')
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.phone,
        renderCell: params => <TruentityPhoneNumber value={params.value} />
      },
      {
        field: 'zipcode',
        headerName: 'Zipcode',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.zipcode
      },
      {
        field: 'devices',
        headerName: 'Devices',
        sortable: false,
        flex: 3,
        minWidth: 150,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.devices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack
              paddingY={1}
              width="100%"
              height="100%"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="stretch"
              flexWrap="nowrap"
              gap=".3125rem"
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device => (
                    <Box
                      width="100%"
                      key={device?.id}
                      textTransform="capitalize"
                      sx={{
                        textOverflow: 'ellipsis',
                        height: getCustomRowHeight(device?.latestReadings?.length ?? 0)
                      }}
                    >
                      <Chip size="small" label={device?.monitoringDevice?.name} />
                    </Box>
                  ))}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'lastReadingAt',
        headerName: 'Last Reading Date',
        sortable: false,
        minWidth: 180,
        flex: 3,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.devices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack
              paddingY={1}
              width="100%"
              height="100%"
              flexDirection={'column'}
              justifyContent={'flex-start'}
              alignItems={'stretch'}
              gap=".3125rem"
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device =>
                    renderDeviceReadingDates({
                      device,
                      truentityId: params.row.truentityId
                    })
                  )}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'lastReadingValue',
        headerName: 'Last Reading',
        sortable: false,
        flex: 3,
        minWidth: 130,
        maxWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.devices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack paddingY={1} width="100%" height="100%" flexDirection={'column'} justifyContent={'flex-start'} alignItems={'stretch'}>
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device =>
                    renderDeviceReadingValues({
                      device,
                      truentityId: params.row.truentityId
                    })
                  )}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'dateEnrolled',
        headerName: 'Date Enrolled',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmEnrolledAt = params.row.rpmEnrolledAt;
          return rpmEnrolledAt ? new Date(rpmEnrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'dateUnenrolled',
        headerName: 'Date Unenrolled',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmUnenrolledAt = params.row.rpmUnenrolledAt;
          return rpmUnenrolledAt ? new Date(rpmUnenrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'onboardedOn',
        headerName: 'Onboarded On',
        sortable: true,
        flex: 2,
        minWidth: 110,
        align: 'center',
        headerAlign: 'center',
        type: 'date',
        valueGetter: params => {
          const onboardedAt = params.row.onboardedAt;
          return onboardedAt ? new Date(onboardedAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'rpmApprovalStatus',
        headerName: 'Provider Approvals',
        sortable: true,
        flex: 2,
        minWidth: 100,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          if (params?.value) {
            return <RpmApprovalStatusChip approvalStatus={params.value} />;
          } else {
            return <span>-</span>;
          }
        }
      },
      {
        field: 'lastContacted',
        headerName: 'Last Contacted At',
        sortable: true,
        minWidth: 130,
        flex: 2.5,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          if (params?.value?.type === ContactedType.PHONE) {
            return (
              <Stack padding={0} margin={0} flexDirection={'row'} alignItems={'center'}>
                <Body1>{formatDate(params?.value?.date)}&nbsp;</Body1>
                <PhoneIcon fontSize={'small'} />
              </Stack>
            );
          } else if (params?.value?.type === ContactedType.MESSAGE) {
            return (
              <Stack padding={0} margin={0} flexDirection={'row'} alignItems={'center'}>
                <Body1>{formatDate(params?.value?.date)}&nbsp;</Body1>
                <ChatBubbleIcon fontSize={'small'} />
              </Stack>
            );
          } else {
            return <span>-</span>;
          }
        }
      },
      {
        field: 'monthlyTotalReadings',
        headerName: 'Monthly Total Readings',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Chip size="small" sx={{ minWidth: '40px' }} label={params.value} color={getColorForTotalReadings(params.value)} />
        ),
        valueGetter: params => params?.row?.monthlyTotalReadings ?? 'N/A'
      },
      {
        field: 'monthlyTimeSpent',
        headerName: 'Monthly Time Spent',
        sortable: true,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params?.row?.monthlyTimeSpent,
        renderCell: params => (
          <Chip
            size="small"
            sx={{ minWidth: '50px' }}
            label={params?.value ? formatSecondsToHMS(params?.value) : '0s'}
            color={getColorForMonthlyTimeSpent(params.value)}
          />
        )
      },
      {
        field: 'reportActions',
        headerName: 'Report',
        flex: 1,
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Tooltip title="View RPM Report">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      onRPMReportClick(params?.row?.truentityId);
                    }}
                    disabled={!params?.row?.isRpmReportAvailable}
                  >
                    <ArticleIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        }
      },
      {
        field: 'scheduledForEnrollmentAction',
        headerName: 'Action',
        sortable: false,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Tooltip title="Schedule for Enrollment">
            <IconButton color="success" onClick={() => handleScheduleForEnrollment(params.row)}>
              <ScheduleSendIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      },
      {
        field: 'cancelScheduledForEnrollmentAction',
        headerName: 'Action',
        sortable: false,
        flex: 1,
        minWidth: 80,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Tooltip title="Cancel Schedule for Enrollment">
            <IconButton color="success" onClick={() => handleCancelScheduleForEnrollment(params.row)}>
              <CancelScheduleSendIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        )
      }
    ],
    [renderDeviceReadingDates, renderDeviceReadingValues, onRPMReportClick, handleScheduleForEnrollment, handleCancelScheduleForEnrollment]
  );

  const rpmPatientStatusTab = useMemo(() => {
    const tabs = [...rpmPatientStatusTabBase];
    if (unenrolledAccountsCount > 0) {
      tabs.push({
        status: RpmStatusTypes.UNENROLLED,
        label: 'Unenrolled',
        path: 'unenrolled',
        icon: <GroupRemoveIcon />
      });
    }
    return tabs;
  }, [unenrolledAccountsCount]);

  const setDefaultPageOptions = () => {
    setCurrentPage(0);
  };

  const sendPatientDataToDrawer = (patientData: PatientsData) => {
    setSelectedCandidate(patientData);
  };

  const redirectToRpmPatientOnboardScreen = () => {
    navigate('/global-lookup');
  };

  const submitRpmReportsForReview = async ({
    truentityIds,
    monthYear,
    reviewSubmitType
  }: {
    truentityIds: string[];
    monthYear: string;
    reviewSubmitType: ReviewSubmitTypeEnum;
  }) => {
    try {
      const response = await reviewAccountsRpmReports({
        variables: {
          truentityIds: truentityIds,
          reviewSubmitType: reviewSubmitType,
          monthYear: monthYear
        }
      });
      if (response?.data?.reviewAccountsRpmReports?.status === 'Success') {
        enqueueSnackbar(response?.data?.reviewAccountsRpmReports?.message ?? 'Patients RPM reports submitted successfully', {
          variant: 'success'
        });
      } else {
        const modal = showModal(InformationDialog, {
          title: (
            <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
              <ErrorOutlineIcon color="error" />
              <H4>&nbsp;Failed to Submit Patients RPM Reports</H4>
            </Stack>
          ),
          customTitleHeader: true,
          message: response?.data?.reviewAccountsRpmReports?.message ?? 'Failed to submit patients RPM reports',
          actions: [
            {
              name: 'Close',
              action: () => {
                modal.hide();
              }
            }
          ]
        });
      }
    } catch (error) {
      enqueueSnackbar('Failed to submit patients RPM reports', { variant: 'error' });
    }
  };

  const handleRefreshButton = () => {
    refreshEnrolledAccountsSnapshots();
  };

  const onSubmitRpmReportsAsReady = useCallback(() => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Submit RPM Reports as Ready',
      message: `This will submit the RPM reports for the selected patients for the month of ${monthAndYear} as ready to send. Are you sure you want to proceed?`,
      onAgree: () => {
        submitRpmReportsForReview({
          truentityIds: selectionModel.map(id => id.toString().split('-')[0]),
          monthYear: monthAndYear,
          reviewSubmitType: ReviewSubmitTypeEnum.READY_TO_SEND
        })
          .then(r => r)
          .catch(e => e);
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      fullWidth: true,
      maxWidth: 'md'
    });
  }, [showModal, selectionModel, monthAndYear]);

  const onSubmitRpmReportsSendNow = useCallback(() => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Instantly Submit RPM Reports For Review',
      message: `This will <b>instantly send</b> the RPM reports related to selected patients for the month of ${monthAndYear}. Are you sure you want to proceed?`,
      onAgree: () => {
        submitRpmReportsForReview({
          truentityIds: selectionModel.map(id => id.toString().split('-')[0]),
          monthYear: isCurrentMonth ? 'current' : monthAndYear,
          reviewSubmitType: ReviewSubmitTypeEnum.SEND_NOW
        })
          .then(r => r)
          .catch(e => e);
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      fullWidth: true,
      maxWidth: 'md'
    });
  }, [showModal, selectionModel, monthAndYear]);

  const resetStates = useCallback(() => {
    setRows([]);
  }, [setRows]);

  const getCustomRowId = useCallback(
    (row: PatientsData) => {
      return `${row.truentityId}-${rpmPatientStatusTab[tab]?.label}-${
        rpmPatientStatusTab[tab]?.status === RpmStatusTypes.ENROLLED ? monthAndYear : ''
      }`;
    },
    [tab, monthAndYear]
  );

  const getAccountByRpmStatus = useCallback(
    async (status: string) => {
      if (status === RpmStatusTypes.ENROLLED) {
        await callGetRpmEnrolledAccounts({
          variables: {
            pageSize: MEDIUM_PAGE_SIZE,
            pageNum: currentPage + 1,
            filterMonthYear: monthAndYear,
            sortModel: sortModel
          }
        });
      } else {
        await callGetAccountByRpmStatus({
          variables: {
            pageSize: MEDIUM_PAGE_SIZE,
            pageNum: currentPage + 1,
            filterOptions: {
              rpmStatus: rpmPatientStatusTab[tab]?.status,
              startDate: formatDate(startDate),
              endDate: formatDate(endDate),
              sortModel: sortModel
            },
            relyingPartyAdminId: 'all'
          }
        });
      }
    },
    [tab, currentPage, startDate, endDate, sortModel, monthAndYear, callGetRpmEnrolledAccounts, callGetAccountByRpmStatus]
  );

  const changeSortModelWithTabChange = useCallback(
    (selectedTabIndex: number) => {
      const { status } = rpmPatientStatusTab[selectedTabIndex];

      if (
        [RpmStatusTypes.ENROLLED, RpmStatusTypes.IS_CANDIDATE, RpmStatusTypes.SCHEDULED_FOR_ENROLLMENT, RpmStatusTypes.UNENROLLED].includes(
          status as SortModalDefaultsKeyTypes
        )
      ) {
        setSortModel([defaultSortModels[status]]);
      }
    },
    [rpmPatientStatusTab, setSortModel]
  );

  const handleTabChange = useCallback(
    (_event: SyntheticEvent, selectedTabIndex: number): void => {
      setTab(selectedTabIndex);
      changeSortModelWithTabChange(selectedTabIndex);
      if (monthAndYear !== currentMonthYear) setMonthAndYear(currentMonthYear);
    },
    [currentMonthYear, monthAndYear, setTab, changeSortModelWithTabChange, setMonthAndYear]
  );

  useEffect(() => {
    if (rpmAccountsByStatusQueryData?.getAccountsByRpmStatus && !rpmAccountsByStatusQueryLoading) {
      const { rpmAccounts, meta } = rpmAccountsByStatusQueryData?.getAccountsByRpmStatus;

      if (rpmAccounts) {
        setRows(rpmAccounts);
      }
      if (meta) {
        setTotalRowCount(meta.totalCount ?? 0);
      } else {
        setTotalRowCount(0);
      }
    } else {
      resetStates();
    }
  }, [rpmAccountsByStatusQueryData, rpmAccountsByStatusQueryLoading]);

  useEffect(() => {
    if (rpmEnrolledAccountsData?.getRpmEnrolledAccounts && !rpmEnrolledAccountsLoading) {
      const { rpmEnrolledAccounts, meta } = rpmEnrolledAccountsData.getRpmEnrolledAccounts;

      if (rpmEnrolledAccounts) {
        setRows(mapEnrolledAccounts(rpmEnrolledAccounts));
      }
      if (meta) {
        setTotalRowCount(meta.totalCount ?? 0);
      } else {
        setTotalRowCount(0);
      }
    } else {
      resetStates();
    }
  }, [rpmEnrolledAccountsData, rpmEnrolledAccountsLoading]);

  useEffect(() => {
    getRpmAccountsCountByStatus({
      variables: {
        status: RpmStatusTypes.UNENROLLED
      }
    })
      .then(response => {
        if (response.data?.getRpmAccountsCountByStatus) {
          setUnenrolledAccountsCount(response.data?.getRpmAccountsCountByStatus.count);
        } else {
          enqueueSnackbar('No data', { variant: 'warning' });
        }
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Failed to get Unenrolled Patients count', { variant: 'error' });
      });
  }, [getRpmAccountsCountByStatus, rpmEnrolledAccountsData, rpmAccountsByStatusQueryData]);

  useEffect(() => {
    const selectedTab = rpmPatientStatusTab[tab];
    const visibility = columnVisibility[selectedTab.path];
    const newVisibilityModel = { ...columnVisibilityModel, ...visibility };
    setColumnVisibilityModel(newVisibilityModel);
    setDefaultPageOptions();
  }, [tab]);

  useEffect(() => {
    const selectedTab = rpmPatientStatusTab[tab];
    getAccountByRpmStatus(selectedTab?.status).catch(err => console.error(err));
  }, [tab, currentPage, monthAndYear, sortModel]);

  return (
    <Stack>
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        sx={{
          padding: 3,
          backgroundColor: color.paper,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          RPM Patients
        </H1>
        <Button label="Onboard Patients" onClick={() => redirectToRpmPatientOnboardScreen()} />
      </Stack>
      <Stack
        sx={{
          marginTop: '16px',
          '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '24px 16px' }
        }}
      >
        <Tabs value={tab} onChange={handleTabChange}>
          {rpmPatientStatusTab?.map((tab: RpmStatusTypeTab, index: number) => (
            <Tab
              style={{ minWidth: 'auto' }}
              icon={tab.icon}
              iconPosition={'start'}
              label={<Body1 textTransform="Capitalize">{tab.label}</Body1>}
              key={tab.label}
              id={`tab-${index}`}
            />
          ))}
        </Tabs>
        <Stack sx={{ pt: 2 }}>
          {rpmPatientStatusTab[tab]?.status === RpmStatusTypes.ENROLLED && (
            <Stack
              flexDirection="column"
              justifyContent="stretch"
              alignItems="stretch"
              sx={{
                width: '100%',
                height: 'auto',
                paddingY: 1
              }}
            >
              <Stack
                flexDirection={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                sx={{
                  flex: 1,
                  width: '100%',
                  height: 'auto',
                  pt: 0,
                  pb: 2,
                  px: 2
                }}
              >
                <Box>
                  <TruentityDatePicker
                    showMonth={true}
                    showYear={true}
                    slotProps={{
                      actionBar: {
                        actions: ['accept']
                      }
                    }}
                    openTo="month"
                    views={['month', 'year']}
                    value={monthAndYear}
                    onChange={handleMonthYear}
                  />
                </Box>
              </Stack>
              <Stack
                flexDirection={'row'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                gap={2}
                sx={{
                  flex: 1,
                  width: '100%',
                  height: 'auto',
                  px: 2
                }}
              >
                <Box>
                  <Tooltip
                    title={
                      isCurrentMonth
                        ? 'Refreshing enrolled accounts will update and re-calculate the data, which may take some time to complete.'
                        : 'You cannot refresh accounts that are not in the current month.'
                    }
                  >
                    <Box>
                      <Button
                        title=""
                        variant="outlined"
                        startIcon={<RefreshIcon />}
                        onClick={handleRefreshButton}
                        isLoading={refreshSnapshotLoading}
                        disabled={!isCurrentMonth}
                      >
                        Refresh
                      </Button>
                    </Box>
                  </Tooltip>
                </Box>
                <Box sx={{ position: 'relative' }}>
                  <MuiMenu
                    variant="contained"
                    disabled={reviewRpmReportsLoading || selectionModel?.length === 0}
                    label="RPM Reports"
                    options={[
                      {
                        label: 'Schedule for Later',
                        onAction: onSubmitRpmReportsAsReady,
                        disabled: isCurrentMonth
                      },
                      {
                        label: 'Send Now',
                        onAction: onSubmitRpmReportsSendNow,
                        disabled: !isCurrentMonth
                      }
                    ]}
                  />
                  {reviewRpmReportsLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  )}
                </Box>
              </Stack>
            </Stack>
          )}
          <TruentityDataGrid
            name={`dg-rpm-patients-${rpmPatientStatusTab[tab]?.label}`}
            autoHeight
            getRowId={getCustomRowId}
            getRowHeight={() => 'auto'}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={newModel => setColumnVisibilityModel(newModel)}
            onCellClick={handleRowClick}
            rows={rows}
            disableColumnMenu={false}
            columns={columns}
            paginationModel={{ pageSize: MEDIUM_PAGE_SIZE, page: currentPage }}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            sortModel={sortModel}
            onSortModelChange={newModel => {
              setSortModel(newModel);
            }}
            filterModel={filterModel}
            onFilterModelChange={newModel => {
              setFilterModel(newModel);
            }}
            loading={rpmAccountsByStatusQueryLoading || rpmEnrolledAccountsLoading}
            rowCount={totalRowCount}
            paginationMode="server"
            sortingMode="server"
            hideFooterSelectedRowCount
            keepNonExistentRowsSelected
            checkboxSelection={rpmPatientStatusTab[tab]?.status === RpmStatusTypes.ENROLLED}
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={newSelection => {
              setSelectionModel(newSelection);
            }}
            isRowSelectable={params => {
              return params.row?.rpmStatus === RpmStatusTypes.ENROLLED && params?.row?.isRpmReportAvailable;
            }}
            disableRowSelectionOnClick
            pinnedColumns={{ left: ['patientName'] }}
            initialState={{
              sorting: {
                sortModel
              }
            }}
            autoPageSize={false}
            sx={{
              '& .MuiDataGrid-cell': {
                paddingY: 1
              },
              // wrap data grid header text
              '& .MuiDataGrid-columnHeaderTitle': {
                whiteSpace: 'normal',
                lineHeight: 'normal'
              }
            }}
          />
        </Stack>
      </Stack>
      <RpmPatientInfoDrawer patientsData={selectedCandidate} />
    </Stack>
  );
};

export default RpmPatients;
