import IdleDialog from '@/components/Dialogs/IdleDialog';
import config from '@/config';
import useSettings from '@/hooks/useSettings';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

type Props = {
  handleLogout: (timedOut?: boolean) => void;
  timeoutOverrideInSeconds?: number;
  promptTimeoutOverrideInSeconds?: number;
  disablePinPrompt?: boolean;
};

export const useIdleModal = ({
  handleLogout,
  timeoutOverrideInSeconds,
  promptTimeoutOverrideInSeconds,
  disablePinPrompt = false
}: Props) => {
  const { settings } = useSettings();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [timeoutInSeconds, setTimeoutInSeconds] = useState<number>(
    timeoutOverrideInSeconds && timeoutOverrideInSeconds > 0 ? timeoutOverrideInSeconds : config.INACTIVITY_TIMEOUT_S
  );
  const [promptTimeoutInSeconds, setPromptTimeoutInSeconds] = useState<number>(
    promptTimeoutOverrideInSeconds && promptTimeoutOverrideInSeconds > 0
      ? promptTimeoutOverrideInSeconds
      : config.INACTIVITY_PROMPT_TIMEOUT_S
  );

  console.warn('timeout', timeoutInSeconds, 'prompt', promptTimeoutInSeconds);

  const showIdleModal = () => {
    const modalRef = showModal(IdleDialog, {
      title: 'You are about to be logged out',
      countFrom: promptTimeoutInSeconds,
      askForPin: (settings['inactivityPinPromptTimeoutSeconds'] ?? 0) > 0 && !disablePinPrompt,
      hideDialog: () => {
        idleTimer.activate();
        modalRef.hide();
      },
      logout: ({ timedOut = true }: { timedOut?: boolean }) => {
        if (timedOut) {
          enqueueSnackbar('Your session has timed out', { variant: 'warning' });
        }
        idleTimer.reset();
        handleLogout(timedOut);
        modalRef.hide();
      }
    });
  };

  const onPrompt = () => {
    showIdleModal();
  };

  const onIdle = () => {
    handleLogout(true);
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    timeout: (timeoutInSeconds + promptTimeoutInSeconds) * 1000,
    promptBeforeIdle: promptTimeoutInSeconds * 1000,
    disabled: ((settings['inactivityTimeoutSeconds'] as number) ?? 1) <= 0
  });

  // Update the timeouts based on settings
  useState(() => {
    const inactivityTimeoutSeconds =
      timeoutOverrideInSeconds && timeoutOverrideInSeconds > 0
        ? timeoutOverrideInSeconds
        : (settings['inactivityTimeoutSeconds'] as number);
    const inactivityPinPromptTimeoutSeconds =
      promptTimeoutOverrideInSeconds && promptTimeoutOverrideInSeconds > 0
        ? promptTimeoutOverrideInSeconds
        : (settings['inactivityPinPromptTimeoutSeconds'] as number);

    setTimeoutInSeconds(inactivityTimeoutSeconds > 0 ? inactivityTimeoutSeconds : config.INACTIVITY_TIMEOUT_S);
    setPromptTimeoutInSeconds(
      inactivityPinPromptTimeoutSeconds > 0 ? inactivityPinPromptTimeoutSeconds : config.INACTIVITY_PROMPT_TIMEOUT_S
    );
  }, [settings]);

  return { idleTimer, showIdleModal }; // Return the idleTimer for control outside of the hook if necessary
};
