export function getKeyByValue(object: object, value: string) {
  return Object.keys(object).find(key => object[key] === value);
}

export function addIfExists(prop: string | undefined | null) {
  return prop && prop.length > 0 ? prop : undefined;
}

export function stripTypenames(obj: object, propToDelete: string) {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj[property];
      const newData = stripTypenames(obj[property] as object, propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
}
