import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import TruentityTextField from '@/components/TruentityTextField';
import {
  ADD_ACCOUNT_MONITORING_DEVICE,
  GetDeviceDetailsByGatewayIdTypes,
  GET_DEVICE_DETAILS_BY_GATEWAY_ID,
  RpmDeviceDetails
} from '@/graphql/remotePatientMonitoring';
import { RpmDeviceTypes } from '@/types/remotePatientMonitoring';
import { formatErrorMessage } from '@/util/rpm';
import { useLazyQuery, useMutation } from '@apollo/client';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';

type Params = {
  id: string | undefined;
  hideDialog: () => void;
};

const AddDeviceByGatewayId = ({ id, hideDialog }: Params) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [deviceDetails, setDeviceDetails] = useState<RpmDeviceDetails[]>([]);
  const [gatewayId, setGatewayId] = useState<string>('');

  const [saveDevice, { loading: saveDeviceLoading }] = useMutation(ADD_ACCOUNT_MONITORING_DEVICE);
  const [getDeviceDetails, { data: deviceDetailsData, error: deviceDetailsError, loading: deviceDetailsLoading }] =
    useLazyQuery<GetDeviceDetailsByGatewayIdTypes>(GET_DEVICE_DETAILS_BY_GATEWAY_ID, { fetchPolicy: 'cache-and-network' });

  const isSearchButtonDisabled = useMemo(() => gatewayId.trim().length <= 0, [gatewayId]);

  const handleTextFieldChange = useCallback(e => {
    const { value } = e.target;
    const preProcessedValue = value.replace(/\s/g, '').toUpperCase();
    setGatewayId(preProcessedValue);
  }, []);

  const handleAddButtonClick = useCallback(
    async selectedRowDetails => {
      const deviceObject = {
        deviceBrand: selectedRowDetails.deviceBrand,
        deviceType: selectedRowDetails.deviceType,
        hardwareId: selectedRowDetails.id,
        gatewayId: gatewayId,
        truentityId: id
      };
      try {
        const res = await saveDevice({ variables: deviceObject });
        const data = res?.data?.addAccountDevice;
        const variant = data?.status === 'Success' ? 'success' : 'error';
        enqueueSnackbar(data?.message, { variant });
        if (variant === 'success') hideDialog();
      } catch (error) {
        enqueueSnackbar(formatErrorMessage(error), { variant: 'error' });
      }
    },
    [id, gatewayId, hideDialog, saveDevice, enqueueSnackbar]
  );

  const handleSearchButtonClick = useCallback(async () => {
    try {
      getDeviceDetails({
        variables: {
          gatewayId,
          truentityId: id,
          filterDeviceTypes: [RpmDeviceTypes.BPM.toLowerCase(), RpmDeviceTypes.GLUCOMETER.toLowerCase()]
        }
      });
    } catch (error) {
      enqueueSnackbar(formatErrorMessage(error), { variant: 'error' });
    }
  }, [gatewayId, id, getDeviceDetails, enqueueSnackbar]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') handleSearchButtonClick();
    },
    [handleSearchButtonClick]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        minWidth: 80,
        sortable: true,
        flex: 0.5,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'id',
        headerName: 'Device Hardware Id',
        minWidth: 200,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 0.3,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        minWidth: 50,
        renderCell: cellValues => {
          const { row } = cellValues;

          if (row?.isDeviceUsedByAccountOwner) {
            return (
              <IconButton title="Already used device" color="primary" sx={{ cursor: 'default' }}>
                <CheckCircleIcon fontSize="small" color="success" />
              </IconButton>
            );
          }
          if (row?.isDeviceUsedByOtherUsers) {
            return (
              <IconButton title="Cannot assign device to user" color="primary" sx={{ cursor: 'not-allowed' }}>
                <CancelIcon fontSize="small" color="error" />
              </IconButton>
            );
          }
          return (
            <IconButton title="Assign device to user" color="primary" onClick={() => handleAddButtonClick(cellValues.row)}>
              <AddCircleIcon fontSize="small" />
            </IconButton>
          );
        }
      }
    ],
    [handleAddButtonClick]
  );

  useEffect(() => {
    if (deviceDetailsData?.getDeviceDetailsByGatewayId) {
      setDeviceDetails(deviceDetailsData.getDeviceDetailsByGatewayId);
    }
  }, [deviceDetailsData]);

  useEffect(() => {
    if (deviceDetailsError) {
      enqueueSnackbar(formatErrorMessage(deviceDetailsError), { variant: 'error' });
    }
  }, [deviceDetailsError]);

  return (
    <Stack gap={1}>
      <Stack direction="row" columnGap={1} alignItems="center">
        <TruentityTextField
          autoFocus
          label="Enter Gateway Id"
          value={gatewayId}
          onChange={handleTextFieldChange}
          onKeyDown={handleKeyPress}
        />
        <Button title="Search" startIcon={<SearchIcon />} disabled={isSearchButtonDisabled} onClick={handleSearchButtonClick}>
          Search
        </Button>
      </Stack>
      <TruentityDataGrid
        name="device-details"
        autoHeight
        columns={columns}
        rows={deviceDetails ?? []}
        rowCount={deviceDetails?.length ?? 0}
        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
        paginationMode="client"
        onPaginationModelChange={({ page }) => {
          setCurrentPage(page);
        }}
        loading={deviceDetailsLoading || saveDeviceLoading}
        disableRowSelectionOnClick
      />
    </Stack>
  );
};

export default AddDeviceByGatewayId;
