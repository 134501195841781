import Button from '@/components/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { Card, CardHeader, Fab, Stack, TextField } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useState } from 'react';

type Template = {
  title: string;
  enabled: boolean;
};

type Message = {
  sender: string;
  text: string;
};

type Props = {
  title: string;
};

const CoPilotWindow = ({ title }: Props) => {
  const theme = useTheme();
  const [messages, setMessages] = useState<Message[]>([]);
  const [showWindow, setShowWindow] = useState(false);
  const [templates] = useState<Template[]>([
    { title: 'Summarize', enabled: true },
    {
      title: 'Ask AI',
      enabled: true
    }
  ]);

  const [input, setInput] = useState('');

  const generateResponse = (inbound: string) => {
    switch (inbound) {
      case 'Summarize':
        return 'Do you want a summary of this page?  I can provide you with a summary of the page';
      case 'Ask AI':
        return 'Welcome back!  Feel free to ask me anything about this page';
    }

    return `You asked for ${inbound}.  I can't help you with that.`;
  };

  const handleSend = () => {
    if (input.trim()) {
      const newMessages = [...messages, { sender: 'user', text: input }];
      setMessages(newMessages);
      setInput('');

      // Simulate bot response
      setTimeout(() => {
        const botResponse = { sender: 'bot', text: generateResponse(input) }; // Replace this with actual bot response logic
        setMessages(prevMessages => [...prevMessages, botResponse]);
      }, 1000);
    }
  };

  const handleClose = () => {
    console.warn('handle close');
    setShowWindow(false);
  };

  const onTemplateSelected = (template: Template) => {
    console.warn('onTemplateSelected', template);
    setInput(template.title);
    handleSend();
    setShowWindow(true);
  };

  return (
    <>
      {!showWindow && (
        <Stack alignItems={'flex-end'} direction={'column'} spacing={1}>
          <Stack direction={'column'} spacing={1} sx={{ position: 'absolute', bottom: 60, right: 0 }} alignItems={'flex-end'}>
            {templates.map((template, index) => (
              <Button
                onClick={() => onTemplateSelected(template)}
                variant={'outlined'}
                startIcon={<AutoAwesomeIcon />}
                key={index}
                disabled={!template.enabled}
              >
                {template.title}
              </Button>
            ))}
          </Stack>

          <Fab
            variant="extended"
            color="secondary"
            onClick={() => {
              setShowWindow(!showWindow);
            }}
          >
            <AutoAwesomeIcon sx={{ mr: 1 }} />
          </Fab>
        </Stack>
      )}

      {showWindow && (
        <Card
          elevation={14}
          sx={{
            zIndex: theme => theme.zIndex.drawer + 2,
            position: 'fixed',
            bottom: 50,
            right: 50,
            width: 450,
            background: '#fff'
          }}
        >
          <CardHeader
            title={title}
            action={
              <IconButton aria-label="Close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
            sx={{ '&.MuiCardHeader-root': { pb: 0 }, padding: 2 }}
          />

          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 0 auto',
              maxHeight: 500,
              overflow: 'auto',
              padding: 1,
              margin: 0
            }}
          >
            <Stack
              direction={'column'}
              sx={{ border: '1px solid', borderColor: theme.palette.grey[300], borderRadius: 2, height: '300px', overflowY: 'auto' }}
              spacing={2}
              my={1}
              p={1}
            >
              {messages.map((msg, index) => (
                <Stack
                  key={index}
                  direction={msg.sender === 'bot' ? 'row-reverse' : 'row'}
                  spacing={1}
                  alignItems={'flex-start'}
                  justifyContent={'flex-start'}
                >
                  <Box
                    component="div"
                    sx={{
                      display: 'inline-block',
                      padding: 1,
                      borderRadius: 2,
                      backgroundColor: msg.sender === 'bot' ? theme.palette.secondary.dark : theme.palette.primary.dark,
                      color: '#fff',
                      width: 'fit-content',
                      maxWidth: '70%'
                    }}
                  >
                    {msg.text}
                  </Box>
                </Stack>
              ))}
            </Stack>
            <Stack
              direction={'row'}
              component={'form'}
              onSubmit={e => {
                e.preventDefault();
                handleSend();
              }}
            >
              <TextField
                value={input}
                onChange={e => setInput(e.target.value)}
                placeholder="Type a message..."
                variant="outlined"
                fullWidth
                sx={{ marginRight: '8px' }}
              />
              <IconButton type={'submit'}>
                <SendIcon />
              </IconButton>
            </Stack>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default CoPilotWindow;
