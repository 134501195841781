import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import MedicalReconciliation from './MedicalReconciliation';

const MedReconciliation = () => {
  const { isReadOnly } = useRpmWorkflowStore();
  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <MedicalReconciliation />
    </BaseRpmWorkflowTabContent>
  );
};

export default MedReconciliation;
