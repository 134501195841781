import SelectList from '@/components/SelectList';
import { H4 } from '@/components/Typography';
import type { AccountProviderType, ProviderMethodsTypes, SelectedProvidersType } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { ContactType } from '@/types/graphql';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Checkbox, Divider, FormControlLabel, FormHelperText, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { FormValues } from '../../ReportCommunication';
import ContactInfoPopover from './ContactInfoPopover';

interface Props {
  index: number;
  accountsProviders: AccountProviderType[];
  removeElement: (prop1: number) => void;
  control: Control<FormValues, any>;
  setValue: UseFormSetValue<FormValues>;
  providerConfig: SelectedProvidersType[] | undefined;
  watch: UseFormWatch<FormValues>;
}

const ProviderConfigElement: React.FC<Props> = ({ index, accountsProviders, removeElement, control, setValue, providerConfig, watch }) => {
  const providerId = watch(`providerConfig.${index}.providerId`);

  const [providerContacts, setProviderContacts] = useState<ContactType[]>([]);
  const [isProviderOption, setIsProviderOption] = useState<boolean>(false);
  const [checkboxDisabled, setCheckboxDisabled] = useState<Partial<ProviderMethodsTypes>>({
    isRpmReportProviderByEmail: false,
    isRpmReportProviderByText: false,
    isRpmReportProviderByFax: false
  });
  const [providerContactInfoPopoverDetails, setProviderContactInfoPopoverDetails] = useState<{
    anchorEl: null | HTMLElement;
    popoverContent: string[];
  }>({
    anchorEl: null,
    popoverContent: []
  });

  const open = Boolean(providerContactInfoPopoverDetails.anchorEl);

  const isFaxDisabled = !isProviderOption || checkboxDisabled.isRpmReportProviderByFax;
  const isEmailDisabled = true; // Upto current implementation, only fax is required
  const isTextDisabled = true;

  const checkElementIsHaveProvider = () => {
    return providerId.length === 0;
  };

  const isDisabledOption = (
    currentSelectedProviderId: string,
    providerConfigs: SelectedProvidersType[] | undefined,
    providerId: string
  ) => {
    const selectedProviderIds =
      providerConfigs &&
      providerConfigs
        .filter(providerConfig => currentSelectedProviderId !== providerConfig.providerId && providerConfig.providerId !== '')
        .map(providerConfig => providerConfig.providerId);

    return selectedProviderIds?.includes(providerId);
  };

  const checkChildElementsAreAvailable = async (event, providerContacts: ContactType[]) => {
    const checked = event.target.checked;
    setIsProviderOption(checked);

    if (checked) {
      const hasTelephone = providerContacts.some(contact => contact.type === 'Telephone');
      const hasEmail = providerContacts.some(contact => contact.type === 'Email');
      const hasFax = providerContacts.some(contact => contact.type === 'Fax');
      console.log(hasTelephone);

      setCheckboxDisabled({
        isRpmReportProviderByEmail: !hasEmail,
        isRpmReportProviderByFax: !hasFax,
        isRpmReportProviderByText: !hasTelephone
      });

      // Mark the checkbox as checked if the Fax is available for the provider
      if (hasFax) {
        setValue(`providerConfig.${index}.isRpmReportProviderByFax`, true, { shouldDirty: true });
      }
    } else {
      setValue(`providerConfig.${index}.isRpmReportProviderByEmail`, false, { shouldDirty: true });
      setValue(`providerConfig.${index}.isRpmReportProviderByText`, false, { shouldDirty: true });
      setValue(`providerConfig.${index}.isRpmReportProviderByFax`, false, { shouldDirty: true });
    }
  };

  const resetValues = () => {
    setIsProviderOption(false);
    setValue(`providerConfig.${index}.isRpmReportProviderByEmail`, false, { shouldDirty: true });
    setValue(`providerConfig.${index}.isRpmReportProviderByText`, false, { shouldDirty: true });
    setValue(`providerConfig.${index}.isRpmReportProviderByFax`, false, { shouldDirty: true });
  };

  const getProviderContactList = (contactType: string, defaultMessage?: boolean) => {
    const filteredContacts = providerContacts.filter(
      contact => contact.type === contactType && contact.value && contact.value?.trim().length > 0
    );

    if (filteredContacts.length > 0 && !defaultMessage) {
      return filteredContacts.map(contact => contact?.value ?? '');
    } else {
      if (contactType === 'Email') return ['Email not assigned'];
      else if (contactType === 'Telephone') return ['Phone number not assigned'];
      else if (contactType === 'Fax') return ['Fax number not assigned'];
      else return ['No Data available'];
    }
  };

  const handleLabelPopover = (event: React.MouseEvent<HTMLButtonElement>, content: string[]) => {
    setProviderContactInfoPopoverDetails({ anchorEl: event.currentTarget, popoverContent: content });
  };

  const handleClosePopover = () => {
    setProviderContactInfoPopoverDetails({ ...providerContactInfoPopoverDetails, anchorEl: null });
  };

  const popperTransitionExited = () => {
    setProviderContactInfoPopoverDetails({ anchorEl: null, popoverContent: [] });
  };

  useEffect(() => {
    if (providerId !== '') {
      const contacts = accountsProviders.find(provider => provider.provider.id === providerId)?.provider.contacts;
      if (contacts) setProviderContacts(contacts);
    }
  }, [providerId]);

  useEffect(() => {
    if (providerConfig && providerConfig?.length > 0 && providerId && providerContacts.length > 0) {
      const hasTelephone = providerContacts.some(contact => contact.type === 'Telephone');
      const hasEmail = providerContacts.some(contact => contact.type === 'Email');
      const hasFax = providerContacts.some(contact => contact.type === 'Fax');

      setCheckboxDisabled({
        isRpmReportProviderByEmail: !hasEmail,
        isRpmReportProviderByFax: !hasFax,
        isRpmReportProviderByText: !hasTelephone
      });
      setIsProviderOption(
        providerConfig?.some(
          config =>
            config.providerId === providerId &&
            (config.isRpmReportProviderByEmail || config.isRpmReportProviderByFax || config.isRpmReportProviderByText)
        )
      );
    }
  }, [providerConfig, providerId, providerContacts]);

  return (
    <>
      <Box key={index}>
        <Divider sx={{ mb: 2, display: index === 0 ? 'none' : 'block' }} />
        <Stack direction="row" gap={2} alignItems="center">
          <Controller
            control={control}
            name={`providerConfig.${index}.providerId`}
            render={({ field: { onChange, value } }) => (
              <SelectList
                id="type"
                label="Select Provider"
                options={accountsProviders?.map(provider => {
                  return {
                    label: `${provider.provider.individualFirstName} ${provider.provider.individualLastName}`,
                    value: provider.provider.id,
                    disabled: isDisabledOption(value, providerConfig, provider.provider.id)
                  };
                })}
                placeholder="Select an option..."
                value={value}
                required
                defaultValue={''}
                sx={{ flex: 1 }}
                onChange={e => {
                  onChange(e);
                  resetValues();
                }}
              />
            )}
          />
          <Box sx={{ flex: 1 }}>
            <IconButton title="Remove Element" color="error" onClick={() => removeElement(index)}>
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </Stack>
        <FormControlLabel
          control={<Checkbox checked={isProviderOption} />}
          label="Monthly reports will be sent to the provider"
          disabled={checkElementIsHaveProvider()}
          onChange={e => checkChildElementsAreAvailable(e, providerContacts)}
        />
        <Stack direction={'column'} marginTop={1}>
          <H4>Send By</H4>
          <Stack
            direction={'row'}
            spacing={2}
            pt={1}
            sx={{
              justifyContent: 'start'
            }}
          >
            <Box minWidth={'6.25rem'}>
              <Stack direction="row" alignItems="center">
                <Controller
                  name={`providerConfig.${index}.isRpmReportProviderByFax`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} />}
                      label={
                        <span
                          style={{ cursor: isFaxDisabled ? 'not-allowed' : 'default' }}
                          onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                            !isProviderOption ? undefined : handleLabelPopover(e, getProviderContactList('Fax'));
                          }}
                          onMouseLeave={handleClosePopover}
                        >
                          Fax
                        </span>
                      }
                      disabled={isFaxDisabled}
                    />
                  )}
                />
              </Stack>
              {isProviderOption && checkboxDisabled.isRpmReportProviderByFax && (
                <FormHelperText sx={{ color: color.grey600 }}>Fax unavailable</FormHelperText>
              )}
            </Box>
            <Box minWidth={'6.25rem'}>
              <Stack direction="row" alignItems="center">
                <Controller
                  name={`providerConfig.${index}.isRpmReportProviderByEmail`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} />}
                      label={
                        <span
                          style={{ cursor: isEmailDisabled ? 'not-allowed' : 'default' }}
                          onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                            !isProviderOption ? undefined : handleLabelPopover(e, getProviderContactList('Email'));
                          }}
                          onMouseLeave={handleClosePopover}
                        >
                          Email
                        </span>
                      }
                      disabled={isEmailDisabled}
                    />
                  )}
                />
              </Stack>
            </Box>
            <Box minWidth={'6.25rem'}>
              <Stack direction="row" alignItems="center">
                <Controller
                  name={`providerConfig.${index}.isRpmReportProviderByText`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} />}
                      label={
                        <span
                          style={{ cursor: isTextDisabled ? 'not-allowed' : 'default' }}
                          onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {
                            !isProviderOption ? undefined : handleLabelPopover(e, getProviderContactList('Telephone', true));
                          }}
                          onMouseLeave={handleClosePopover}
                        >
                          Text
                        </span>
                      }
                      disabled={isTextDisabled}
                    />
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <ContactInfoPopover
        anchorEl={providerContactInfoPopoverDetails.anchorEl}
        open={open}
        onClose={handleClosePopover}
        contents={providerContactInfoPopoverDetails.popoverContent}
        popperTransitionExited={popperTransitionExited}
      />
    </>
  );
};

export default ProviderConfigElement;
