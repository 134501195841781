import Button from '@/components/Button';
import { DialogTypes } from '@/components/Dialogs/MedicationReconciliationDetailDialog';
import { ADD_NOTE_TO_ACCOUNT_MEDICATION, GetMedrecSnapshotDetailResponse } from '@/graphql/medication';
import { medicationType } from '@/types/remotePatientMonitoring';
import { ApolloQueryResult, OperationVariables, useMutation } from '@apollo/client';
import { Box, DialogActions, FormControl, Paper, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

type Props = {
  medicationDetails: medicationType;
  setMedicationDetails: Dispatch<SetStateAction<medicationType>>;
  switchToModal: (modalType: DialogTypes) => void;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetMedrecSnapshotDetailResponse>>;
};

type FormValues = {
  note: string;
};

const defaultValues: FormValues = {
  note: ''
};
const AddMedicationNote = ({ medicationDetails, setMedicationDetails, switchToModal, refetch }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [saveMedicationNote] = useMutation(ADD_NOTE_TO_ACCOUNT_MEDICATION);

  const {
    control,
    setValue,
    getValues,
    watch,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>({ defaultValues });

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      saveMedicationNote({
        variables: {
          accountMedicationId: medicationDetails.id,
          note: values.note
        }
      })
        .then(response => {
          const data = response.data!.addNoteToAccountMedication;
          const variant = data!.status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(data.message, {
            variant
          });
          switchToModal(DialogTypes.PREVIEW);
          setMedicationDetails({ ...medicationDetails, note: values.note });
          refetch();
        })
        .catch(error => {
          console.log(error);

          enqueueSnackbar('Unable to add medication note', {
            variant: 'error'
          });
        });
    } catch (err) {
      console.log(err);

      const failMessage: string = 'Failed to add a medication note';
      enqueueSnackbar(failMessage, {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (medicationDetails.note && medicationDetails.note.trim().length !== 0) setValue('note', medicationDetails.note);
  }, [medicationDetails]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Paper elevation={0} sx={{ p: 1 }}>
        <FormControl fullWidth size="small">
          <Controller
            control={control}
            name="note"
            render={({ field: { onChange, value } }) => (
              <TextField onChange={onChange} value={value} multiline rows={6} placeholder="Type your note here" id="note" label="Note" />
            )}
          />
        </FormControl>
      </Paper>
      <DialogActions sx={{ justifyContent: 'end', pt: 2, px: 0, pb: 0 }}>
        <Button type="submit" a11yLabel="Save" appearance="primary" />
        <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={() => switchToModal(DialogTypes.PREVIEW)} />
      </DialogActions>
    </Box>
  );
};

export default AddMedicationNote;
