export type ConversationMessage = {
  id: string;
  user: {
    avatar?: string;
    name: string;
  } | null;
  message: string;
  createdAt: Date;
  timestamp: number;
  type: UserTypes.bot | UserTypes.user | UserTypes.otherUser;
  action?: AssistantActions;
};

export type MessageTemplate = {
  id?: string;
  category: string;
  messageTemplateItems: MessageTemplateItem[];
  isDisabled: { disabled: boolean; disabledText: string } | boolean;
};

export type MessageTemplateItem = {
  id: string;
  createdAt: string;
  updatedAt: string;
  relyingPartyId: number;
  text: string;
};

export type CurrentPatient = {
  id: string;
  patientId: string;
  firstName: string;
  lastName: string;
  patientName: string;
  gender: string;
  patientContactNo: string;
  setting: string;
};

export enum AssistantActions {
  showVisit,
  showSummary,
  showDrugConditionSummary,
  failed
}

export enum UserTypes {
  bot = 'bot',
  user = 'user',
  otherUser = 'otherUser'
}

export interface AccountMessage {
  id: string;
  createdAt: string;
  messageBody: string;
  isRead: boolean;
  messageDirection: string;
  messageFrom: string;
  messageTo: string;
  sourceType: string;
}

export interface PatientConversation {
  id: string;
  accountPhoneNumber: string;
  adminPhoneNumber: string;
  account: {
    id: string;
    truentityId: string;
    user: {
      firstName: string;
      lastName: string;
    };
  };
  relyingPartyAdmin: {
    id: string;
    user: {
      firstName: string;
      lastName: string;
    };
  };
  name: string;
  description: string;
  isActive: boolean;
  lastMessageAt: string;
  accountLastMessageAt?: string;
  adminLastMessageAt?: string;
  createdAt: string;
}

export interface MessagesResponse {
  accountMessagesByContact: AccountMessage[];
}

export interface RelyingPartyAdminContactsResponse {
  relyingPartyAdminContacts: [
    {
      value: string;
    }
  ];
}

export interface GetMessageTemplatesResponse {
  messageTemplateItemsByCategory: [MessageTemplate];
}

export type MessagesByDate = { [key: string]: ConversationMessage[] };

export type DialpadUsersResponse = {
  getDialpadUsers: {
    dialpadUserFirstName: string;
    dialpadUserLastName: string;
    dialpadUserId: string;
    dialpadUserPhoneNumbers: string[];
  }[];
};
