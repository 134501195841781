import Button from '@/components/Button';
import { PieChart } from '@/components/Charts/charts';
import DashboardSection from '@/components/Dashboard/DashboardSection';
import type { KpiSectionProps } from '@/components/KpiComponents/KpiSection';
import { KpiSection } from '@/components/KpiComponents/KpiSection';
import OrganizationSelectList from '@/components/OrganizationSelectList';
import { H1 } from '@/components/Typography';
import type { GetRpmStatisticsResponse, RpmStatistics } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_STATISTICS, REFRESH_RPM_STATISTICS } from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { useLazyQuery, useMutation } from '@apollo/client';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PeopleIcon from '@mui/icons-material/People';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Paper, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const StyledKpiSection = ({ ...props }: KpiSectionProps) => {
  return <KpiSection icon={<PeopleIcon fontSize="large" />} {...props} />;
};

const defaultStatisticData: RpmStatistics = {
  activitiesStats: {
    accountsTimeSpentBrackets: [],
    accountsDeviceTypeBrackets: [],
    totalDevicesCount: 0,
    totalUnreadAlertsCount: 0
  },
  enrollmentStats: {
    candidateAccountsCount: 0,
    candidateAccountsPercentage: 0,
    scheduledForEnrollmentAccountsCount: 0,
    scheduledForEnrollmentAccountsPercentage: 0,
    enrolledAccountsCount: 0,
    unenrolledAccountsCount: 0,
    enrolledAccountsPercentage: 0,
    totalCandidateAccountsCount: 0,
    totalEnrolledAccountsCount: 0
  },
  readingsStats: {
    activeAccountsCount: 0,
    inactiveAccountsCount: 0,
    monthlyReadingsBrackets: [],
    todayAccountsReadingsCount: 0
  }
};

const RpmDashboard = () => {
  const { roleType } = useToken();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOrgId, setSelectedOrgId] = useState<string>('all');
  const [data, setData] = useState<RpmStatistics>(defaultStatisticData);
  const [getRpmStatistics, { refetch: refetchRpmStatistics, data: refetchRpmStatisticsData, loading: isRefetchRpmStatisticsLoading }] =
    useLazyQuery<GetRpmStatisticsResponse>(GET_RPM_STATISTICS, {
      fetchPolicy: 'cache-and-network'
    });

  const [refreshEnrolledAccountsSnapshots, { loading: refreshSnapshotLoading }] = useMutation(REFRESH_RPM_STATISTICS, {
    onCompleted: () => refetchRpmStatistics()
  });

  const handleRefreshButton = () => {
    refreshEnrolledAccountsSnapshots({
      variables: {
        filterOptions: {
          organization: selectedOrgId
        }
      }
    });
  };

  const isSuperAdmin = useMemo(() => roleType === Role.SUPER, [roleType]);

  const resetStatData = useCallback(() => {
    setData(defaultStatisticData);
  }, [setData]);

  useEffect(() => {
    resetStatData();
    getRpmStatistics({
      variables: {
        filterOptions: {
          organization: selectedOrgId
        }
      }
    })
      .then(response => {
        if (!response.data?.getRpmStatistics) {
          resetStatData();
          enqueueSnackbar('No data', { variant: 'warning' });
        }
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Failed to retrieve data', { variant: 'error' });
      });
  }, [getRpmStatistics, selectedOrgId, resetStatData, enqueueSnackbar]);

  useEffect(() => {
    if (refetchRpmStatisticsData?.getRpmStatistics && !isRefetchRpmStatisticsLoading) {
      setData(refetchRpmStatisticsData.getRpmStatistics);
    }
  }, [refetchRpmStatisticsData, isRefetchRpmStatisticsLoading, setData]);

  return (
    <Stack spacing={2}>
      <Paper padding={2} component={Stack} direction="row" alignItems={'center'} justifyContent={'space-between'}>
        <H1>RPM Dashboard</H1>
        <Stack direction="row" spacing={1}>
          <OrganizationSelectList selectedOrgId={selectedOrgId} setSelectedOrgId={setSelectedOrgId} />
          {isSuperAdmin && (
            <Tooltip title="Refreshing RPM dashboard data will update and re-calculate the data, which may take some time to complete.">
              <Button variant="outlined" startIcon={<RefreshIcon />} onClick={handleRefreshButton} isLoading={refreshSnapshotLoading}>
                Refresh
              </Button>
            </Tooltip>
          )}
        </Stack>
      </Paper>
      <Stack
        spacing={2}
        sx={{
          '& .apexcharts-tooltip': {
            color: '#FFFFFF !important'
          }
        }}
      >
        <DashboardSection title={'Enrollment'}>
          <Stack direction={'row'} spacing={1}>
            <Stack flex={1} spacing={1}>
              <StyledKpiSection
                label={'No of Patients Enrolled'}
                value={data?.enrollmentStats?.enrolledAccountsCount ?? 0}
                icon={<AssignmentTurnedInIcon fontSize="large" />}
              />
              <StyledKpiSection
                label={'No of Patients Scheduled For Enrollments'}
                value={data?.enrollmentStats.scheduledForEnrollmentAccountsCount ?? 0}
                icon={<AccountBoxIcon fontSize="large" />}
              />
              <StyledKpiSection label={'No of Patients Candidates'} value={data?.enrollmentStats.candidateAccountsCount ?? 0} />
            </Stack>
            <Stack flex={1} spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ background: 'white' }}>
              <PieChart
                title={'No of Patients Candidates vs Scheduled For Enrollments vs Enrolled'}
                width={400}
                series={[
                  data?.enrollmentStats?.enrolledAccountsCount ?? 0,
                  data?.enrollmentStats?.candidateAccountsCount ?? 0,
                  data?.enrollmentStats?.scheduledForEnrollmentAccountsCount ?? 0
                ]}
                options={{
                  labels: ['Enrolled', 'Candidates', 'Scheduled For Enrollments']
                }}
              />
            </Stack>
          </Stack>
        </DashboardSection>
        <DashboardSection title={'Readings'}>
          <Stack direction={'row'} spacing={1}>
            <Stack flex={1} spacing={1}>
              <StyledKpiSection label={'No of Patients Active (with readings)'} value={data?.readingsStats?.activeAccountsCount ?? 0} />
              <StyledKpiSection label={'No of Patients Inactive (no readings)'} value={data?.readingsStats?.inactiveAccountsCount ?? 0} />
              <StyledKpiSection
                label={'No of Patients  with new readings received today'}
                value={data?.readingsStats?.todayAccountsReadingsCount ?? 0}
              />
            </Stack>
            <Stack flex={1} spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ background: 'white' }}>
              <PieChart
                title={'No of Patients w/ of number of days with Readings'}
                width={400}
                series={data?.readingsStats?.monthlyReadingsBrackets.map(v => v.count)}
                options={{
                  labels: data?.readingsStats?.monthlyReadingsBrackets.map(v => v.name)
                }}
              />
            </Stack>
          </Stack>
        </DashboardSection>
        <Stack direction={'row'} spacing={1} sx={{ width: '100%' }}>
          <DashboardSection flex={1} title={'Devices'} spacing={1}>
            <StyledKpiSection label={'No of Devices supplied'} value={data?.activitiesStats?.totalDevicesCount ?? 0} />
            <Stack flex={1} spacing={1} alignItems={'center'}>
              <PieChart
                title={'No of Patients w/ BPM, BGM, and other devices'}
                width={400}
                series={data?.activitiesStats?.accountsDeviceTypeBrackets.map(v => v.count)}
                options={{
                  labels: data?.activitiesStats?.accountsDeviceTypeBrackets.map(v => v.name)
                }}
              />
            </Stack>
          </DashboardSection>
          <DashboardSection flex={1} title={'Alerts and Time Spent'} spacing={1}>
            <StyledKpiSection label={'No of unread Alerts'} value={data?.activitiesStats?.totalUnreadAlertsCount ?? 0} />
            <Stack flex={1} spacing={1} alignItems={'center'} justifyContent={'center'} sx={{ background: 'white' }}>
              <PieChart
                title={'Patients with Time Spent'}
                width={400}
                series={data?.activitiesStats?.accountsTimeSpentBrackets.map(v => v.count)}
                options={{
                  labels: data?.activitiesStats?.accountsTimeSpentBrackets.map(v => v.name)
                }}
              />
            </Stack>
          </DashboardSection>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RpmDashboard;
