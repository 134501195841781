import PatientDetailContext from '@/context/patientDetailContext';
import type { GetEncounterResponse } from '@/graphql/taskEncounter';
import { GET_ENCOUNTER } from '@/graphql/taskEncounter';
import { useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EncounterDetailsSection from './EncounterDetailsSection';

const EncounterDetails = () => {
  const { encounterId, id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { setReloadPatientInfo, reloadEncounters } = useContext(PatientDetailContext);

  const { data, error } = useQuery<GetEncounterResponse>(GET_ENCOUNTER, {
    variables: {
      truentityId: id,
      encounterId: encounterId
    }
  });

  const refetch = () => {
    setReloadPatientInfo(true);
    reloadEncounters();
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Unable to retrieve encounter', { variant: 'error' });
    }
  }, [enqueueSnackbar, error]);

  return <EncounterDetailsSection encounterDetail={data?.encounter} refetch={refetch} showTasks={true} usedInTCM={false} />;
};

export default EncounterDetails;
