import Button from '@/components/Button';
import { ADD_UPDATE_CLIENT_ORG } from '@/graphql/administration';
import { handleMutationFormErrorCb } from '@/util/error';
import { useMutation } from '@apollo/client';
import { DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type FormValues = { name: string };

const defaultValues: FormValues = {
  name: ''
};

type Props = BaseDialogProps & {
  hideDialog: () => void;
  id: string;
  data: { name: string };
  editMode: boolean;
};

const OrganizationAddDialog = ({ title, hideDialog, id, data, editMode, ...props }: Props) => {
  const [addUpdateOrganization] = useMutation(ADD_UPDATE_CLIENT_ORG);

  const { register, handleSubmit, setValue } = useForm<FormValues>({ defaultValues });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);
  const { enqueueSnackbar } = useSnackbar();

  const showError = err => {
    console.error('err', err);
    enqueueSnackbar(err.message, { variant: 'error' });
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      let orgId = '';

      if (editMode) {
        orgId = id;
      }

      const result = await addUpdateOrganization({
        variables: {
          organizationData: {
            name: values.name
          },
          organizationId: orgId
        }
      });

      const { clientOrganization } = result.data!.saveOrganization;

      if (clientOrganization) {
        enqueueSnackbar('Successfully added Organization', { variant: 'success' });
      } else {
        enqueueSnackbar('Error adding Organization', { variant: 'error' });
      }
      hideDialog();
    } catch (err) {
      handleMutationFormErrorCb(err, {
        cb: showError,
        errorMap: {
          all: gqlError => ({
            title: gqlError.name,
            message: gqlError.message,
            status: 'danger'
          })
        }
      });
    }
  };

  useEffect(() => {
    if (editMode) {
      setValue('name', data?.name, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  }, [data, editMode]);

  return (
    <BaseDialog canNavigateBack={false} title={title} hideDialog={hideDialog} {...props} maxWidth={'xs'} fullWidth={true}>
      <DialogContent>
        <Stack margin={2} spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <TruentityTextField
            autoFocus
            required
            {...register('name')}
            label="Organization name "
            type="text"
            fullWidth
            variant="outlined"
            margin="dense"
          />
          <Stack direction={'row'} spacing={1}>
            <Button type="submit" a11yLabel={editMode ? 'Edit' : 'Add'} fullWidth />
            <Button a11yLabel="Cancel" type="reset" appearance="outline" fullWidth onClick={hideDialog} />
          </Stack>
        </Stack>
      </DialogContent>
    </BaseDialog>
  );
};

export default OrganizationAddDialog;
