import { H1 } from '@/components/Typography';
import { GetPioneerEventsResponseById, GET_PIONEER_RX_EVENT_BY_ID, PioneerRxEventTypes } from '@/graphql/account';
import { capitalizeLetterBeforeCharacter } from '@/util/string';
import { useLazyQuery } from '@apollo/client';
import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';

type Attribute = {
  label: string | undefined;
  value: string | undefined;
  type: string | undefined;
};

const PioneerImports = () => {
  const { id, eventId } = useParams();
  const [pioneerRxData, setPioneerRxData] = useState<PioneerRxEventTypes>();

  const [getPioneerEvents, { loading: pioneerEventsLoading, data: pioneerEvents, called: pioneerEventsCalled, refetch }] =
    useLazyQuery<GetPioneerEventsResponseById>(GET_PIONEER_RX_EVENT_BY_ID);

  useEffect(() => {
    getPioneerEvents({
      variables: {
        truentityId: id,
        eventId: eventId
      }
    });
  }, [eventId]);

  useEffect(() => {
    if (pioneerEventsCalled && !pioneerEventsLoading && pioneerEvents) {
      console.log(pioneerEvents.getPioneerrxById);

      setPioneerRxData(pioneerEvents.getPioneerrxById);
    }
  }, [pioneerEventsLoading, pioneerEvents, pioneerEventsCalled, eventId]);

  const attributes: Attribute[] = [
    { label: 'Account ID', value: pioneerRxData?.accountId ?? '', type: 'string' },
    { label: 'Org ID', value: pioneerRxData?.clientOrgId ?? '', type: 'string' },
    { label: 'Event Name', value: capitalizeLetterBeforeCharacter(pioneerRxData?.eventName ?? ''), type: 'string' },
    { label: 'Message Body', value: pioneerRxData?.messageBody ?? '', type: 'object' },
    { label: 'Message Header', value: pioneerRxData?.messageHeader ?? '', type: 'object' }
  ];

  return (
    <>
      <Box mb={2}>
        <H1 textAlign="left" mb={2}>
          Event Received from PioneerRx {pioneerRxData?.clientOrg?.name && `(${pioneerRxData?.clientOrg?.name})`}
        </H1>
      </Box>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '10%', whiteSpace: 'nowrap' }}>Type</TableCell>
            <TableCell>Values</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pioneerEventsLoading ? (
            <TableRow>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            attributes?.map((attribute, index) => (
              <TableRow key={index}>
                <TableCell style={{ width: '10%', whiteSpace: 'nowrap' }}>{attribute.label}</TableCell>
                <TableCell>
                  {attribute.type === 'object' ? (
                    <ReactJson collapsed={true} src={attribute.value !== '' ? JSON.parse(attribute.value as string) : {}} />
                  ) : (
                    attribute.value
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default PioneerImports;
