import Alert from '@/components/Alert';
import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import ConfirmHealthCarePatientAttributesDialog from '@/components/Dialogs/ConfirmHealthCarePatientAttributesDialog';
import ConnectiveJsonDialog from '@/components/Dialogs/ConnectiveJsonDialog';
import PdfDialog from '@/components/Dialogs/PdfDialog';
import MuiMenu from '@/components/MuiMenu';
import { H3, H4, H5 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type { GetAccountDiagnosisResponseType, UpdateRpmSetupResponse } from '@/graphql/remotePatientMonitoring';
import { GET_ACCOUNT_DIAGNOSES, UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import type {
  AccountCareSummaryRequest,
  CreatePdfRpmEncounterReportResponse,
  GetAccountCareSummariesResponse,
  GetConnectiveJsonPreviewResponse,
  GetDocumentPreviewResponse,
  GetRpmHealthTrackingsResponse,
  ImportAccountCareSummaryResponse,
  SubmitAccountCareSummaryForReviewResponse
} from '@/graphql/rpmWorkflow';
import {
  AccountCareSummaryStatus,
  CONNECTIVE_JSON_PREVIEW,
  CREATE_PDF_RPM_ENCOUNTER_REPORT,
  GET_ACCOUNT_CARE_SUMMARIES,
  GET_DOCUMENT_PREVIEW,
  GET_RPM_HEALTH_TRACKINGS,
  IMPORT_ACCOUNT_CARE_SUMMARY,
  SUBMIT_ACCOUNT_CARE_SUMMARY_FOR_REVIEW
} from '@/graphql/rpmWorkflow';
import useToken from '@/hooks/useToken';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import PatientA1CReadings from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ClinicalSummary/PatientA1CReadings';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import { Role } from '@/types/admin';
import {
  DiagnosisSystems,
  ReviewSubmitTypeEnum,
  RpmApprovalStatusTypes,
  RpmHealthTrackingKeys,
  RpmStatusTypes,
  RpmWorkflowTab
} from '@/types/remotePatientMonitoring';
import { tomorrow } from '@/util/date';
import { formatDate, formatDateAndTime } from '@/util/format';
import { updateRpmTabStatus } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CachedIcon from '@mui/icons-material/Cached';
import DataObjectIcon from '@mui/icons-material/DataObject';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Box, Checkbox, Chip, Divider, FormControlLabel, LinearProgress, Stack, Tooltip } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccountsDiagnoses from './Components/ClinicalSummary/AccountsDiagnoses';

export interface FetchDocumentPreviewProps {
  s3Key: string;
  type: 'RpmEncounter' | 'AccountCareSummary';
}

const ClinicalSummary = () => {
  const { id: truentityId } = useParams();
  const { roleType } = useToken();
  const navigate = useNavigate();
  const [isSuperAdmin] = useState<boolean>(roleType === Role.SUPER);

  const { showModal } = useModal();
  const { isReadOnly } = useRpmWorkflowStore();
  const { enqueueSnackbar } = useSnackbar();
  const [isClinicalDocPresent, setIsClinicalDocPresent] = useState<boolean>(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [rpmEncounterSelected, setRpmEncounterSelected] = useState<boolean>(true);
  const [currentA1CPage, setCurrentA1CPage] = useState(1);

  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const { rpmSetupTabs, editRpmStatus } = useRpmSetupStore();
  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);
  const [accountCareSummaries, setAccountCareSummaries] = useState<AccountCareSummaryRequest[]>([]);

  const [
    importAccountCareSummary,
    { loading: importAccountCareSummaryLoading, error: importAccountCareSummaryError, data: importAccountCareSummaryResponse }
  ] = useMutation<ImportAccountCareSummaryResponse>(IMPORT_ACCOUNT_CARE_SUMMARY);
  const [reviewAccountCareSummary, { loading: reviewAccountCareSummaryLoading }] = useMutation<SubmitAccountCareSummaryForReviewResponse>(
    SUBMIT_ACCOUNT_CARE_SUMMARY_FOR_REVIEW
  );

  const [getAccountDiagnoses, { data: accountDiagnosis, loading: accountDiagnosisLoading, refetch: accountDiagnosisRefetch }] =
    useLazyQuery<GetAccountDiagnosisResponseType>(GET_ACCOUNT_DIAGNOSES, {
      variables: { truentityId: truentityId, filterOptions: { isMonitorable: true, prioritySystem: DiagnosisSystems.ICD } },
      fetchPolicy: 'cache-and-network'
    });

  const [
    getAccountHealthTracking,
    { data: rpmHealthTrackingsData, loading: rpmHealthTrackingsLoading, error: rpmHealthTrackingsError, refetch: refetchHealthTracking }
  ] = useLazyQuery<GetRpmHealthTrackingsResponse>(GET_RPM_HEALTH_TRACKINGS, {
    variables: {
      truentityId: truentityId,
      pageNum: currentA1CPage,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        healthDataType: RpmHealthTrackingKeys.HEMOGLOBIN_A1C
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const {
    data: getAccountCareSummaries,
    error: getAccountCareSummariesError,
    refetch: refetchAccountCareSummaries,
    loading: loadingAccountCareSummaries
  } = useQuery<GetAccountCareSummariesResponse>(GET_ACCOUNT_CARE_SUMMARIES, {
    variables: {
      truentityId,
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network',
    skip: !truentityId,
    onCompleted: async data => {
      setAccountCareSummaries(data?.accountCareSummaries?.accountCareSummaries ?? []);
      await getAccountDiagnoses();
      await getAccountHealthTracking();
      await updateRpmStatus(data);
    }
  });

  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW, {
    fetchPolicy: 'cache-and-network'
  });
  const [getConnectiveJsonPreview] = useLazyQuery<GetConnectiveJsonPreviewResponse>(CONNECTIVE_JSON_PREVIEW);
  const [createRpmEncounterReport, { loading: loadingCreateRpmEncounterReport }] = useMutation<CreatePdfRpmEncounterReportResponse>(
    CREATE_PDF_RPM_ENCOUNTER_REPORT,
    {
      variables: {
        truentityId
      }
    }
  );

  const updateRpmStatus = async (data: GetAccountCareSummariesResponse) => {
    const accountSummaries = data.accountCareSummaries.accountCareSummaries;
    const selectedData = selectionModel.map(id => accountSummaries?.find(accountSummary => accountSummary.id === id));
    const isAccountSummariesAvailable = selectedData
      ? selectedData.some(accountSummary => accountSummary && accountSummary.status === AccountCareSummaryStatus.Available)
      : false;

    const isRpmApproved = patientInfo?.rpmApprovalStatus !== null || patientInfo?.rpmApprovalStatus !== RpmApprovalStatusTypes.PENDING;

    if (isAccountSummariesAvailable && isRpmApproved) {
      ///TODO:  why is there a need for the isOverrideAvailable property?
      await updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CLINICAL_SUMMARY, editRpmStatus, updateRpmSetup, true);
      await updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CARE_PLAN, editRpmStatus, updateRpmSetup, true);
    }
  };

  const getAccountCareSummaryChip = useCallback((value: AccountCareSummaryStatus) => {
    if (!value) {
      return <span>-</span>;
    }
    let color: 'success' | 'error' | 'warning' = 'success';
    if (value === AccountCareSummaryStatus.Requested) {
      color = 'warning';
    } else if (value === AccountCareSummaryStatus.Unavailable) {
      color = 'error';
    }

    return <Chip variant="filled" color={color} label={value} />;
  }, []);

  const fetchDocumentPreview = useCallback(
    async ({ type, s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: s3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          if (type === 'RpmEncounter') {
            fetch(`${documentResponse?.data?.documentPreview?.documentUrl}`)
              .then(response => response.blob())
              .then(blob => {
                const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
                const modal = showModal(PdfDialog, {
                  title: 'New Patient Encounter Document Preview',
                  pdfUrl: url,
                  hideDialog: () => {
                    modal.hide();
                  }
                });
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            const modal = showModal(PdfDialog, {
              title: 'Clinical Document Preview',
              pdfUrl: documentResponse?.data?.documentPreview?.documentUrl,
              hideDialog: () => {
                modal.hide();
              }
            });
          }
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getDocumentPreview, showModal]
  );

  const fetchConnectiveJsonPreview = useCallback(
    async ({ s3Key }: FetchDocumentPreviewProps) => {
      try {
        const documentResponse = await getConnectiveJsonPreview({
          variables: {
            jsonS3Key: s3Key
          }
        });
        if (documentResponse?.data?.connectiveJsonPreview?.documentUrl) {
          const jsonUrl = documentResponse?.data?.connectiveJsonPreview?.documentUrl;
          const modal = showModal(ConnectiveJsonDialog, {
            title: 'Connective health document Preview',
            jsonUrl: jsonUrl,
            hideDialog: () => {
              modal.hide();
            }
          });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getConnectiveJsonPreview, showModal]
  );

  const columns: GridColDef<AccountCareSummaryRequest>[] = useMemo(
    () => [
      {
        field: 'requestedOn',
        headerName: 'Requested On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDateAndTime(params.value)
      },
      {
        field: 'importedOn',
        headerName: 'Imported On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDateAndTime(params.value)
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        renderCell: params => {
          return getAccountCareSummaryChip(params.value);
        }
      },
      {
        field: 's3Keys',
        headerName: 'Documents',
        sortable: true,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          if (params.row.status === AccountCareSummaryStatus.Available) {
            return (
              <>
                <Button
                  size="small"
                  variant={'text'}
                  startIcon={<FileOpenIcon />}
                  disableElevation
                  onClick={() => fetchDocumentPreview({ type: 'AccountCareSummary', s3Key: params.row.s3Key })}
                />
                {params.row.jsonS3Key !== '' && params.row.jsonS3Key !== null && isSuperAdmin && (
                  <Button
                    size="small"
                    variant={'text'}
                    startIcon={<DataObjectIcon />}
                    disableElevation
                    onClick={() => fetchConnectiveJsonPreview({ type: 'AccountCareSummary', s3Key: params.row.jsonS3Key })}
                  />
                )}
              </>
            );
          } else {
            return <span>-</span>;
          }
        }
      },
      {
        field: 'submittedForReviewOn',
        headerName: 'Last Submitted For Review On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDate(params.value)
      }
    ],
    [getAccountCareSummaryChip, isSuperAdmin, fetchDocumentPreview, fetchConnectiveJsonPreview]
  );

  const handleAccountReview = useCallback(
    async (reviewType: ReviewSubmitTypeEnum) => {
      if (selectionModel.length === 0) {
        enqueueSnackbar('Please select at least one account care summary to submit for review', { variant: 'error' });
        return;
      }

      try {
        const response = await reviewAccountCareSummary({
          variables: {
            truentityId,
            accountCareSummaryIds: selectionModel,
            isRpmEncounterSubmitted: rpmEncounterSelected,
            reviewSubmitType: reviewType
          }
        });
        if (response?.data?.reviewAccountCareSummary?.status === 'Success') {
          enqueueSnackbar('Successfully submitted account for review', { variant: 'success' });
          setReloadPatientInfo(true);
          navigate('/remote-patient-monitoring/provider-approvals');
        } else if (response?.errors && response?.errors?.length > 0) {
          enqueueSnackbar(response?.errors[0]?.message, { variant: 'error' });
        } else {
          enqueueSnackbar('Failed to submit account care summary for review', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to submit account care summary for review', { variant: 'error' });
      }
    },
    [rpmEncounterSelected, enqueueSnackbar, reviewAccountCareSummary, selectionModel, setReloadPatientInfo, truentityId]
  );

  const confirmPatientInfo = () => {
    const modal = showModal(ConfirmHealthCarePatientAttributesDialog, {
      title: 'Please confirm the Patient Details',
      onPatientUpdated: () => {
        handleImport();
        modal.hide();
      },
      skipDialog: () => {
        handleImport();
        modal.hide();
      },
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const handleImport = () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Submit Request',
      message: `Are you sure you want to submit a request to import ${
        patientInfo?.user?.firstName ? patientInfo?.user?.firstName + "'s" : ''
      } clinical documents?`,
      onAgree: () => {
        importAccountCareSummary({
          variables: {
            truentityId,
            appointmentDate: formatDate(tomorrow(), 'YYYY-MM-DD')
          }
        }).catch(err => console.error(err));
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      }
    });
  };

  const isCarePlanDocumentAvailable = useMemo(
    () => !!getAccountCareSummaries?.accountCareSummaries?.carePlanS3Key,
    [getAccountCareSummaries]
  );

  const isRpmEncounterDocumentAvailable = useMemo(
    () => !!getAccountCareSummaries?.accountCareSummaries?.rpmEncounterS3Key,
    [getAccountCareSummaries]
  );

  const reviewBtnTooltipTitle = useMemo((): string => {
    if (selectionModel.length === 0) {
      return 'Patient Encounter document and at least one Clinical Summary document must be selected';
    } else if (reviewAccountCareSummaryLoading) {
      return 'Submitting Patient Care Summary for review';
    } else if (!getAccountCareSummaries?.accountCareSummaries?.rpmEncounterS3Key) {
      return 'Patient Encounter document and at least one Clinical Summary document must be selected';
    } else if (!isClinicalDocPresent) {
      return 'At least one clinical document must be available';
    } else if (!rpmEncounterSelected) {
      return 'Patient Encounter document must be selected';
    } else {
      return '';
    }
  }, [selectionModel, reviewAccountCareSummaryLoading, getAccountCareSummaries, isClinicalDocPresent, rpmEncounterSelected]);

  const getReviewLabel = useCallback(() => {
    return patientInfo?.rpmApprovalStatus === RpmStatusTypes.IN_REVIEW ||
      patientInfo?.rpmApprovalStatus === RpmStatusTypes.SCHEDULE_FOR_REVIEW
      ? 'Re-Schedule For Review'
      : 'Schedule For Review';
  }, [patientInfo?.rpmApprovalStatus]);

  const regenerateRpmEncounterReport = useCallback(async () => {
    try {
      const rpmEncounterReportResponse = await createRpmEncounterReport();

      if (rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.status === 'Success') {
        refetchAccountCareSummaries();
      } else {
        if (
          rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.errors &&
          rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.errors?.length > 0
        ) {
          enqueueSnackbar(rpmEncounterReportResponse?.data?.createPdfRpmAccountEncounterReport?.errors[0], { variant: 'error' });
        } else {
          enqueueSnackbar('Failed to generate Patient Encounter Document. Please try again later.', { variant: 'error' });
        }
      }
    } catch (err) {
      enqueueSnackbar('Failed to generate Patient Encounter Document. Please try again later.', { variant: 'error' });
      console.error(err);
    }
  }, [createRpmEncounterReport]);

  const loadClinicalSummaryAlert = () => {
    if (loadingAccountCareSummaries) {
      return <Alert status="info" description="Loading clinical summary documents..." />;
    }
    if (!isCarePlanDocumentAvailable) {
      return (
        <Alert
          status="info"
          description="Care Plan Document must be filled before submitting patient for review"
          hidden={isRpmEncounterDocumentAvailable}
        />
      );
    }
    if (!isRpmEncounterDocumentAvailable) {
      return (
        <Alert
          status="info"
          description="New Patient Encounter Document must be generated before submitting patient for review"
          hidden={isRpmEncounterDocumentAvailable}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (getAccountCareSummariesError) {
      console.error(getAccountCareSummariesError);
      enqueueSnackbar('Failed to get patient care summaries ', { variant: 'error' });
    }
  }, [enqueueSnackbar, getAccountCareSummariesError]);

  useEffect(() => {
    if (importAccountCareSummaryError) {
      enqueueSnackbar('Failed to import patient care summary ', { variant: 'error' });
    } else if (importAccountCareSummaryResponse?.importAccountCareSummary?.status === 'Success') {
      enqueueSnackbar('Successfully requested patient care summary import ', { variant: 'success' });
      refetchAccountCareSummaries().catch(err => console.error(err));
    }
  }, [importAccountCareSummaryError, importAccountCareSummaryResponse, enqueueSnackbar, refetchAccountCareSummaries]);

  useEffect(() => {
    const isOneClinicalDocPresent =
      getAccountCareSummaries?.accountCareSummaries?.accountCareSummaries?.some(
        summary => summary.status === AccountCareSummaryStatus.Available
      ) ?? false;
    setIsClinicalDocPresent(isOneClinicalDocPresent);
  }, [getAccountCareSummaries]);

  useEffect(() => {
    refetchHealthTracking();
  }, [currentA1CPage]);

  useEffect(() => {
    if (rpmHealthTrackingsError) {
      enqueueSnackbar('Failed to get Patient HbA1c data', { variant: 'error' });
    }
  }, [rpmHealthTrackingsError]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <Stack sx={{ background: theme.palette.background.default, padding: theme.spacing(2) }}>
        <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center" marginY={1}>
          <H3>Clinical Summary</H3>
          <Stack p={1} direction={'row'} alignItems={'center'} spacing={1}>
            <Tooltip title={reviewBtnTooltipTitle} placement={'top'}>
              <Box>
                <MuiMenu
                  variant="contained"
                  disabled={
                    selectionModel.length === 0 ||
                    reviewAccountCareSummaryLoading ||
                    !isClinicalDocPresent ||
                    !isRpmEncounterDocumentAvailable ||
                    !isCarePlanDocumentAvailable ||
                    !rpmEncounterSelected
                  }
                  label="Review"
                  options={[
                    // {
                    //   label: patientInfo?.rpmApprovalStatus === RpmStatusTypes.READY_FOR_REVIEW ? 'Re-Add For Review' : 'Add For Review',
                    //   disabled: patientInfo?.rpmApprovalStatus === RpmStatusTypes.IN_REVIEW,
                    //   onAction: () => hanadleAccountReview('ADD_FOR_REVIEW')
                    // },
                    {
                      label: getReviewLabel(),
                      onAction: () => handleAccountReview(ReviewSubmitTypeEnum.SCHEDULE_FOR_REVIEW)
                    }
                  ]}
                />
              </Box>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack spacing={4} sx={{ height: 'auto', minHeight: '200px', width: '100%' }}>
          <Box>
            {loadClinicalSummaryAlert()}
            <Stack
              flexDirection="row"
              alignItems="center"
              sx={{
                width: '100%',
                height: 'auto',
                my: 2,
                p: 1,
                border: '1px solid',
                borderColor: theme => theme.palette.divider,
                backgroundColor: color.grey50,
                borderRadius: 1
              }}
            >
              <Box flex={'1'} display={'flex'} flexDirection="row" justifyContent="flex-start" alignItems="center">
                {isRpmEncounterDocumentAvailable ? (
                  <>
                    <FormControlLabel
                      label={''}
                      control={
                        <Checkbox
                          value={rpmEncounterSelected}
                          onChange={e => {
                            setRpmEncounterSelected(e.target.checked);
                          }}
                          defaultChecked
                        />
                      }
                    />
                    <Box
                      sx={{ width: '66px' }}
                      component={'img'}
                      src={'https://truentity-general.s3.amazonaws.com/Indycare-CarePlan-Thumbnail.png'}
                    />
                    <H5
                      sx={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: color.black100,
                        marginLeft: 2
                      }}
                    >
                      Preview New Patient Encounter Document
                    </H5>
                  </>
                ) : (
                  <H5
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: color.black100,
                      marginLeft: 2
                    }}
                  >
                    New Patient Encounter Document
                  </H5>
                )}
              </Box>
              <Stack flexDirection="row" justifyContent="flex-end" columnGap={2}>
                <Button
                  variant="outlined"
                  label={isRpmEncounterDocumentAvailable ? 'Regenerate' : 'Generate'}
                  disabled={loadingAccountCareSummaries || !isCarePlanDocumentAvailable}
                  isLoading={loadingCreateRpmEncounterReport}
                  onClick={regenerateRpmEncounterReport}
                />

                {isRpmEncounterDocumentAvailable && (
                  <Button
                    sx={{ width: '86px' }}
                    label="View"
                    disabled={loadingCreateRpmEncounterReport}
                    onClick={() => {
                      if (getAccountCareSummaries?.accountCareSummaries?.rpmEncounterS3Key) {
                        fetchDocumentPreview({
                          s3Key: getAccountCareSummaries?.accountCareSummaries?.rpmEncounterS3Key,
                          type: 'RpmEncounter'
                        });
                      }
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Box>
          <Divider />
          <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
            <H4>Clinical Summary Requests</H4>
            <Stack direction={'row'} spacing={2} alignItems={'center'}>
              <Button
                disabled={
                  importAccountCareSummaryLoading ||
                  reviewAccountCareSummaryLoading ||
                  patientInfo?.rpmStatus === 'ENROLLED' ||
                  patientInfo?.rpmStatus === 'UNENROLLED'
                }
                label={'Refresh'}
                variant={'outlined'}
                startIcon={<CachedIcon />}
                onClick={() => {
                  refetchAccountCareSummaries().catch(err => console.error(err));
                  accountDiagnosisRefetch().catch(err => console.error(err));
                }}
              />
              <Button
                label={'Import'}
                disabled={
                  importAccountCareSummaryLoading || patientInfo?.rpmStatus === 'ENROLLED' || patientInfo?.rpmStatus === 'UNENROLLED'
                }
                onClick={confirmPatientInfo}
              />
            </Stack>
          </Stack>
          <TruentityDataGrid
            name={'dg-review-for-enrolment'}
            pagination={false}
            disabled={isReadOnly}
            slots={{
              loadingOverlay: LinearProgress
            }}
            loading={loadingAccountCareSummaries || importAccountCareSummaryLoading || reviewAccountCareSummaryLoading}
            checkboxSelection
            disableRowSelectionOnClick={true}
            disableMultipleRowSelection={true}
            rowSelectionModel={selectionModel}
            hideFooterSelectedRowCount
            onRowSelectionModelChange={newSelection => {
              setSelectionModel(newSelection);
            }}
            isRowSelectable={params => params.row.status === AccountCareSummaryStatus.Available}
            autoHeight
            columns={columns}
            rows={accountCareSummaries}
          />
        </Stack>

        <AccountsDiagnoses accountDiagnosis={accountDiagnosis} accountDiagnosisLoading={accountDiagnosisLoading} />
        <PatientA1CReadings
          healthTrackings={rpmHealthTrackingsData?.getAccountHealthTrackings?.healthTrackings ?? []}
          healthTrackingsLoading={rpmHealthTrackingsLoading}
          currentA1CPage={currentA1CPage}
          setCurrentA1CPage={setCurrentA1CPage}
          totalHealthTrackingsCount={rpmHealthTrackingsData?.getAccountHealthTrackings?.meta?.totalCount ?? 0}
        />
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default ClinicalSummary;
