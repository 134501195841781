import type { RpmSetupTypes } from '@/graphql/remotePatientMonitoring';
import { create } from 'zustand';

type RpmSetupStore = {
  rpmSetupTabs: RpmSetupTypes[];
  isEnrollmentEligibility: boolean;
  setRpmSetupTabs: (value: RpmSetupTypes[]) => void;
  setIsEnrollmentEligibility: (value: boolean) => void;
  editRpmStatus: (value: RpmSetupTypes) => void;
};

export const useRpmSetupStore = create<RpmSetupStore>(set => ({
  rpmSetupTabs: [],
  isEnrollmentEligibility: false,
  setRpmSetupTabs: value => set(() => ({ rpmSetupTabs: value })),
  setIsEnrollmentEligibility: value => set(() => ({ isEnrollmentEligibility: value })),
  editRpmStatus: value =>
    set(state => {
      const updateTab = state.rpmSetupTabs.map(tab => {
        if (tab.id === value.id) {
          return { ...tab, ...value };
        }
        return tab;
      });

      return { rpmSetupTabs: updateTab };
    })
}));
