import MuiAccordion from '@/components/MuiAccordion';
import { H3 } from '@/components/Typography';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import ClinicalRangesConfiguration from '@/routes/PatientDetails/RemotePatientMonitoring/ClinicalRangesConfiguration';
import { RpmConfigurationsParams } from '@/types/remotePatientMonitoring';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useTheme } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import MedicalServices from './MedicalServices';
import PatientSettings from './PatientSettings';
import ReportCommunication from './ReportCommunication';

const Configuration = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const { isReadOnly } = useRpmWorkflowStore();

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <H3>Configuration</H3>
      <MuiAccordion
        options={[
          {
            label: 'Reports Communication',
            defaultExpand: false,
            content: <ReportCommunication />
          },
          {
            label: 'Medical Services Provided',
            defaultExpand: false,
            content: <MedicalServices />
          },
          {
            label: 'Clinical Ranges',
            defaultExpand: searchParams?.get('configure') === RpmConfigurationsParams.CLINICAL_RANGES,
            content: <ClinicalRangesConfiguration />
          },
          {
            label: 'Patient Settings',
            defaultExpand: false,
            content: <PatientSettings />
          }
        ]}
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: theme.palette.background.default,
          '& .MuiAccordionDetails-root': {
            backgroundColor: theme.palette.background.default
          }
        }}
      />
    </BaseRpmWorkflowTabContent>
  );
};

export default Configuration;
