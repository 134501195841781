import { CustomTimezone, GeneralTimezone, TruentityDateInput } from '@/types/date';
import type { DateInput } from '@fullcalendar/core';
import type { Moment, MomentInput } from 'moment';
import moment from 'moment';

export const toISOString = (date: Date | null) => {
  return date?.toISOString() || null;
};

export const getFirstDateOfPreviousMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
};

export const getLastDateOfPreviousMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 0);
};

export const secondsToDate = (seconds: number) => {
  return moment.utc(seconds * 1000);
};

export const addTwoMonthsFromDate = (date: Date) => {
  return moment(date).add(2, 'months');
};

export const getDateStringForMessage = (messageDate: DateInput) => {
  try {
    const adminTimezone = getGeneralTimezone(CustomTimezone.ADMIN_TIMEZONE);
    const adminToday = toTimezoneDate({ date: moment().startOf('day'), timezone: adminTimezone });
    const adminYesterday = toTimezoneDate({ date: moment().subtract(1, 'days').startOf('day'), timezone: adminTimezone });
    const adminMessageDay = toTimezoneDate({ date: moment(messageDate).startOf('day'), timezone: adminTimezone });

    if (adminMessageDay.isSame(adminToday, 'd')) {
      return 'Today';
    } else if (adminMessageDay.isSame(adminYesterday, 'd')) {
      return 'Yesterday';
    } else if (adminMessageDay.isSame(adminToday, 'w')) {
      return adminMessageDay.format('dddd');
    } else {
      return adminMessageDay.format('MMM D YYYY');
    }
  } catch (error) {
    return '';
  }
};

export const toDate = (date: MomentInput) => {
  return moment(date).toDate();
};

export const addOneYearToDate = (date: MomentInput) => {
  return moment(date).add(1, 'year');
};

export const isSameOrBefore = (date1: MomentInput, date2: MomentInput) => {
  return moment(date1).isSameOrBefore(date2);
};

export const getDaysBeforeDate = (date: MomentInput, days: number): Date => {
  return moment(date).subtract(days, 'days').toDate();
};

export const incrementMonths = (date: MomentInput, months: number) => {
  return moment(date).clone().add(months, 'months');
};

export const incrementYears = (date: MomentInput, years: number) => {
  return moment(date).clone().add(years, 'years');
};

export const getFirstDateOfCurrentMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getYesterdayDateOfCurrentMonth = (date: Date): Date => {
  const yesterday = new Date(date);
  yesterday.setDate(date.getDate() - 1);
  return yesterday;
};

export const toDateOrNull = (date?: unknown) => {
  if (moment.isMoment(date) || moment.isDate(date)) {
    return date;
  }

  if (date && typeof date === 'string' && Date.parse(date)) {
    return new Date(date);
  }

  return null;
};

export const toDateOrDefault = (date: unknown, def: MomentInput) => {
  if (moment.isMoment(date) || moment.isDate(date)) {
    return date;
  }

  if (date && typeof date === 'string' && Date.parse(date)) {
    return new Date(date);
  }

  return def;
};

export const toMomentDateOrDefault = (date: unknown, def: Moment) => {
  if (moment.isMoment(date)) {
    return date;
  }

  if (date && typeof date === 'string' && Date.parse(date)) {
    return moment(date);
  }

  return def;
};

export const toUTC = (date: TruentityDateInput) => {
  return moment.utc(date);
};

export const today = (): Moment => {
  return moment.utc();
};

export const getDateDifferenceFromNow = (date: MomentInput): string => {
  return moment(date).fromNow();
};

export const tomorrow = () => {
  return moment.utc().add(1, 'day');
};

export const toTruentityDateInput = (date: MomentInput) => {
  return date ? moment(date) : null;
};

export const dateToSeconds = (date: MomentInput) => {
  if (!date) {
    return 0;
  }
  const hour = moment(date).hour();
  const minute = moment(date).minute();
  const second = moment(date).second();
  return hour * 3600 + minute * 60 + second;
};

export const getMidnightUTC = () => {
  const now = new Date();
  const localMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
  return localMidnight;
};

type ToTimezoneDateInput = {
  date: MomentInput;
  timezone: GeneralTimezone;
};

export const toTimezoneDate = ({ date, timezone = GeneralTimezone.EST }: ToTimezoneDateInput): Moment => {
  const localeString = toDate(date).toLocaleString('en-GB', { timeZone: timezone });
  return moment(localeString, 'DD/MM/YYYY HH:mm:ss');
};

export const getGeneralTimezone = (customTimezone: CustomTimezone): GeneralTimezone => {
  switch (customTimezone) {
    case CustomTimezone.ACCOUNT_TIMEZONE:
      return GeneralTimezone.EST;
    case CustomTimezone.ADMIN_TIMEZONE:
      return GeneralTimezone.EST;
    default:
      throw new Error('Invalid timezone');
  }
};
