import { gql } from '@apollo/client';

export type ConsultQuestion = {
  id?: string;
  primaryText: string;
  secondaryText: string;
  responseText?: string;
  suggestedText?: string;
  required?: boolean;
};

export type Consult = {
  id: string;
  title: string;
  description?: string;
  startTime: string;
  endTime?: string;
  status?: string;
  items: ConsultQuestion[];
  relyingPartyAdmin?: {
    user: {
      firstName: string;
      lastName: string;
    };
  };
  reportS3Key: string;
  medRec: any[];
  type?: string;
};

export type MedConsultTemplate = {
  id: string;
  name: string;
  category: string;
};

export type StartConsultResponse = {
  startMedConsult: {
    medConsult: Consult;
  };
};
export const START_CONSULT = gql`
  mutation StartMedConsult($truentityId: String!, $templateId: String!) {
    startMedConsult(truentityId: $truentityId, templateId: $templateId) {
      medConsult {
        id
        title
        description
        status
        startTime
        endTime
        items {
          id
          primaryText
          secondaryText
        }
      }
    }
  }
`;

export type GetMedConsultResponse = {
  medConsult: Consult;
};

export const GET_MED_CONSULT = gql`
  query GetMedConsultQuery($medConsultId: String!) {
    medConsult(medConsultId: $medConsultId) {
      id
      title
      description
      status
      startTime
      endTime
      items {
        id
        primaryText
        secondaryText
        responseText
      }
    }
  }
`;

export type SaveConsultItemResponse = {
  saveMedConsultItem: {
    medConsultItem: ConsultQuestion;
  };
};
export const SAVE_CONSULT_ITEM = gql`
  mutation SaveMedConsultItem($medConsultId: String!, $medConsultItemData: MedConsultItemInput!) {
    saveMedConsultItem(medConsultId: $medConsultId, medConsultItemData: $medConsultItemData) {
      medConsultItem {
        id
        primaryText
        responseText
        secondaryText
        orderNum
      }
    }
  }
`;

export type EndConsultResponse = {
  endMedConsult: {
    status: string;
  };
};

export const END_CONSULT = gql`
  mutation EndMedConsult($medConsultId: ID!) {
    endMedConsult(medConsultId: $medConsultId) {
      status
    }
  }
`;

export type GetMedConsultsResponse = {
  medConsults: {
    medConsults: Consult[];
  };
};

export const GET_MED_CONSULTATIONS = gql`
  query GetMedConsultsQuery($truentityId: String!, $pageNum: Int!, $pageSize: Int!) {
    medConsults(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      medConsults {
        id
        title
        description
        status
        startTime
        endTime
        reportS3Key
        relyingPartyAdmin {
          user {
            firstName
            lastName
          }
        }
        items {
          id
          primaryText
          responseText
          secondaryText
        }
      }
    }
  }
`;

export type GetMedConsultTemplatesResponse = {
  medConsultTemplates: MedConsultTemplate[];
};

export const GET_MED_CONSULT_TEMPLATES = gql`
  query GetMedConsultTemplatesQuery {
    medConsultTemplates {
      id
      name
      category
    }
  }
`;

export type GetMedConsultTemplateItemsByIdResponse = {
  medConsultTemplateItemsById: ConsultQuestion[];
};

export const GET_MED_CONSULT_ITEMS_BY_TEMPLATE_ID = gql`
  query MedConsultTemplateItemsByIdQuery($templateId: String!) {
    medConsultTemplateItemsById(templateId: $templateId) {
      id
      primaryText
      secondaryText
      suggestedText
      orderNum
    }
  }
`;

export const CHANGE_MED_CONSULT_STATUS = gql`
  mutation UpdateMedConsultStatus($medConsultId: ID!, $medConsultStatus: String!) {
    updateMedConsultStatus(medConsultId: $medConsultId, medConsultStatus: $medConsultStatus) {
      status
      message
    }
  }
`;
