import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import MuiTabs from '@/components/MuiTabs';
import TruentityTextField from '@/components/TruentityTextField';
import type { GetAccountFollowupsResponse } from '@/graphql/account';
import { FollowUpStatusTypes, GET_ACCOUNT_FOLLOWUPS } from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import FollowUpForm from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatTime } from '@/util/format';
import { useQuery } from '@apollo/client';
import SearchIcon from '@mui/icons-material/Search';
import { Chip, DialogContent, Grid, Stack } from '@mui/material';
import { GridLogicOperator } from '@mui/x-data-grid';
import type { GridColDef, GridFilterModel } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  handleRefetchAndClose?: () => void;
  truentityId: string;
  isRpmEnrolled: boolean;
  doNotCall: boolean;
  followupsData?: FollowUpRemindersTypes[] | undefined;
  isUpdateModal?: boolean;
  accountAssignments?: {
    relyingPartyAdmin: RelyingPartyAdminData;
  }[];
  isInitialVisitFollowupForm?: boolean;
};

export enum FollowupFormModeTypes {
  UPDATE = 'UPDATE',
  ADD = 'ADD'
}

export const renderFollowUpStatusChip = (status: FollowUpStatusTypes) => {
  if (status === FollowUpStatusTypes.PENDING) {
    return <Chip variant="filled" color="info" label="Pending" />;
  } else if (status === FollowUpStatusTypes.COMPLETED) {
    return <Chip variant="filled" color="success" label="Completed" />;
  } else {
    return <Chip variant="filled" color="error" label="Unknown" />;
  }
};

const FollowUpAddEditDialog = ({
  title,
  hideDialog,
  handleRefetchAndClose,
  truentityId,
  isRpmEnrolled,
  doNotCall,
  followupsData,
  isUpdateModal = false,
  accountAssignments = [],
  isInitialVisitFollowupForm = false,
  ...props
}: Props) => {
  const location = useLocation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [formMode, setFormMode] = useState<FollowupFormModeTypes>(FollowupFormModeTypes.ADD);
  const [accountAssignees, setAccountAssignees] = useState<RelyingPartyAdminData[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        field: 'type',
        operator: 'contains',
        value: searchValue
      },
      {
        field: 'subType',
        operator: 'contains',
        value: searchValue
      },
      {
        field: 'status',
        operator: 'contains',
        value: searchValue
      },
      {
        field: 'assignedTo',
        operator: 'contains',
        value: searchValue
      },
      {
        field: 'notes',
        operator: 'contains',
        value: searchValue
      }
    ],
    logicOperator: GridLogicOperator.Or
  });

  const { data: accountFollowUpData, loading: accountFollowUpLoading } = useQuery<GetAccountFollowupsResponse>(GET_ACCOUNT_FOLLOWUPS, {
    variables: {
      truentityId,
      filterOptions: {
        relyingPartyAdminId: 'ALL'
      }
    },
    fetchPolicy: 'no-cache'
  });

  const isModalOpenInFollowUpPage = useMemo(() => location.pathname.toLowerCase().endsWith('/follow-ups'), [location]);

  const columns: GridColDef<FollowUpRemindersTypes>[] = useMemo(() => {
    return [
      {
        field: 'followupDate',
        headerName: 'Date',
        valueGetter: params => formatDate(params.row.followUpOn),
        flex: 1
      },
      {
        field: 'followupTime',
        headerName: 'Time',
        valueGetter: params => formatTime(params.row.followUpOn),
        flex: 1
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        renderCell: params => renderFollowUpStatusChip(params.value as FollowUpStatusTypes)
      },
      {
        field: 'subType',
        headerName: 'Sub Type',
        flex: 1
      },
      {
        field: 'assignedTo',
        headerName: 'Assigned To',
        valueGetter: params => `${getAccountUserFullName(params.row.relyingPartyAdmin?.user)}`,
        flex: 1
      },
      {
        field: 'notes',
        headerName: 'Note',
        flex: 1
      }
    ];
  }, []);

  useEffect(() => {
    if (isUpdateModal) return setFormMode(FollowupFormModeTypes.UPDATE);
    return setFormMode(FollowupFormModeTypes.ADD);
  }, [isUpdateModal]);

  useEffect(() => {
    setFilterModel(prevFilterModel => ({
      ...prevFilterModel,
      items: [
        {
          field: 'type',
          operator: 'contains',
          value: searchValue
        },
        {
          field: 'subType',
          operator: 'contains',
          value: searchValue
        },
        {
          field: 'assignedTo',
          operator: 'contains',
          value: searchValue
        },
        {
          field: 'notes',
          operator: 'contains',
          value: searchValue
        }
      ],
      logicOperator: GridLogicOperator.Or
    }));
  }, [searchValue]);

  useEffect(() => {
    if (accountAssignments && accountAssignments?.length > 0) {
      const accountAssignmentAdmins = accountAssignments.map(assignment => assignment && assignment.relyingPartyAdmin);
      setAccountAssignees(accountAssignmentAdmins);
    }
  }, [accountAssignments]);

  return (
    <BaseDialog
      canNavigateBack={false}
      hideDialog={hideDialog}
      {...props}
      title={isModalOpenInFollowUpPage || isInitialVisitFollowupForm ? title : ''}
      maxWidth={'md'}
      fullWidth={true}
    >
      <DialogContent>
        <MuiTabs
          tabVariant="standard"
          sx={{
            '& .MuiTabs-flexContainer': {
              width: '100% !important'
            },
            '& .MuiBox-root': {
              padding: '0.5rem'
            }
          }}
          tabs={[
            {
              label: `${formMode === FollowupFormModeTypes.ADD ? 'Add New' : 'Update'} Follow-Up`,
              children: (
                <FollowUpForm
                  truentityId={truentityId}
                  isRpmEnrolled={isRpmEnrolled}
                  formMode={formMode}
                  doNotCall={doNotCall}
                  accountAssignees={accountAssignees}
                  followupsData={followupsData}
                  hideDialog={hideDialog}
                  handleRefetchAndClose={handleRefetchAndClose}
                  isInitialVisitFollowupForm={isInitialVisitFollowupForm}
                />
              )
            },
            {
              label: 'All Follow-Ups',
              children: (
                <Stack alignItems="center" sx={{ marginBottom: theme => theme.spacing(2) }}>
                  <Grid container rowSpacing={1}>
                    <Grid item xs={6}>
                      <TruentityTextField
                        icon={<SearchIcon />}
                        autoFocus={true}
                        label={'Search'}
                        fullWidth
                        margin="normal"
                        value={searchValue}
                        onChange={e => setSearchValue(e.currentTarget.value)}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        minHeight: '250px'
                      }}
                      item
                      xs={12}
                    >
                      <TruentityDataGrid
                        filterModel={filterModel}
                        onFilterModelChange={newFilterModel => setFilterModel(newFilterModel)}
                        name={'account-followups'}
                        columns={columns}
                        rows={accountFollowUpData?.accountFollowups?.accountFollowups ?? []}
                        loading={accountFollowUpLoading}
                        rowCount={accountFollowUpData?.accountFollowups?.accountFollowups?.length ?? 0}
                        editMode="row"
                        pagination
                        paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                        onPaginationModelChange={({ page }) => {
                          setCurrentPage(page);
                        }}
                        paginationMode="client"
                      />
                    </Grid>
                  </Grid>
                </Stack>
              ),
              disabled: isModalOpenInFollowUpPage || isInitialVisitFollowupForm
            }
          ]}
          hideDisabled
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default FollowUpAddEditDialog;
