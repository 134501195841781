import type { TextSelectOption } from '@/components/SelectList';
import { FollowUpStatusTypes, FollowUpTypes } from '@/graphql/account';
import { MedicalServices } from '@/types/admin';

export function followUpTypes(doNotCall?: boolean, isEnrolled?: boolean, currentMedicalServices?: string[]): TextSelectOption[] {
  const options = [
    { label: FollowUpTypes.CALL_PATIENT, value: FollowUpTypes.CALL_PATIENT, disabled: doNotCall ?? false },
    { label: FollowUpTypes.CALL_PROVIDER, value: FollowUpTypes.CALL_PROVIDER },
    { label: FollowUpTypes.TEXT_PATIENT, value: FollowUpTypes.TEXT_PATIENT, disabled: doNotCall ?? false },
    { label: FollowUpTypes.REVIEW_CHART, value: FollowUpTypes.REVIEW_CHART },
    { label: FollowUpTypes.REVIEW, value: FollowUpTypes.REVIEW }
  ];

  if (currentMedicalServices?.includes(MedicalServices.RPM)) {
    options.push({ label: FollowUpTypes.RPM_INITIAL_VISIT, value: FollowUpTypes.RPM_INITIAL_VISIT, disabled: isEnrolled ?? true });
  }

  return options;
}

export function followUpSubTypes(): TextSelectOption[] {
  return [
    { label: 'None', value: 'None' },
    { label: 'RPM', value: 'RPM' },
    { label: 'CMR', value: 'CMR' },
    { label: 'TIP', value: 'TIP' },
    { label: 'Adherence', value: 'Adherence' }
  ];
}

export function followUpStatuses(): TextSelectOption[] {
  return [
    { label: 'Pending', value: FollowUpStatusTypes.PENDING },
    { label: 'Completed', value: FollowUpStatusTypes.COMPLETED }
  ];
}
