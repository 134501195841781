import type React from 'react';
import type { BaseSyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import type { Control, UseFormWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import TruentityTextField from '@/components/TruentityTextField';
import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Button from '@/components/Button';
import { useNavigate } from 'react-router-dom';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_ORGANIZATIONS_STANDARD, GET_CLIENT_STORES_STANDARD } from '@/graphql/administration';
import { DEFAULT_PAGE_SIZE, HIGH_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import { H3 } from '@/components/Typography';

export type AddCompanyFormValuesType = {
  companyName: string;
  rpm: boolean;
  mtm: boolean;
  tcm: boolean;
  clientOrganization: string;
  clientStore: string;
};

export const addCompanyFormDefault: AddCompanyFormValuesType = {
  companyName: '',
  rpm: false,
  mtm: false,
  tcm: false,
  clientOrganization: '',
  clientStore: ''
};

export type AddCompanyFormProps = {
  control: Control<AddCompanyFormValuesType>;
  watch: UseFormWatch<AddCompanyFormValuesType>;
  onSubmit: (e?: BaseSyntheticEvent | undefined) => Promise<void>;
};

export const AddCompanyForm: React.FC<AddCompanyFormProps> = ({ control, watch, onSubmit }) => {
  const navigate = useNavigate();
  const clientOrganization = watch('clientOrganization');

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [clientStores, setClientStores] = useState<ClientStoreObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);

  const { data: organizationData } = useQuery(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  const { data: clientStoresData } = useQuery(GET_CLIENT_STORES_STANDARD, {
    variables: {
      pageNum: 0,
      pageSize: HIGH_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    setClientStores(clientStoresData?.clientStores?.clientStores ?? []);
  }, [clientStoresData]);

  useEffect(() => {
    setClientOrganizations(organizationData?.clientOrganizations?.clientOrganizations ?? []);
  }, [organizationData]);

  useEffect(() => {
    if (clientStores.length > 0) {
      const storesBelongingToSelectedOrg = clientStores.filter(store => store.clientOrg?.id === clientOrganization);
      setFilteredClientStores(storesBelongingToSelectedOrg);
    }
  }, [clientOrganization, clientStores]);

  return (
    <Stack component={'form'} spacing={2} p={3} onSubmit={onSubmit}>
      <H3>Company Details</H3>
      <Controller
        control={control}
        name="companyName"
        rules={{ required: 'Company Name is required' }}
        render={({ field: { onChange, value } }) => (
          <TruentityTextField fullWidth={true} maxLength={128} required onChange={onChange} value={value} label={'Company Name'} />
        )}
      />

      <Grid container m={1}>
        <Grid item xs={6}>
          <FormControl required component={'fieldset'}>
            <FormLabel component="legend">Medical Services</FormLabel>
            <FormGroup>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="RPM" />
                )}
                name={'rpm'}
                control={control}
              />

              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="MTM" />
                )}
                name={'mtm'}
                control={control}
              />

              <Controller
                render={({ field: { onChange, value } }) => (
                  <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="TCM" />
                )}
                name={'tcm'}
                control={control}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'stretch'
          }}
          item
          xs={6}
        >
          <Controller
            control={control}
            name="clientOrganization"
            render={({ field: { onChange, value } }) => (
              <FormControl variant="outlined" fullWidth margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Client Organization</InputLabel>
                <Select
                  labelId={'organizations'}
                  label={'Organizations'}
                  placeholder={'select an organization'}
                  value={value}
                  onChange={event => {
                    onChange(event);
                  }}
                >
                  {clientOrganizations.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="clientStore"
            render={({ field: { onChange, value } }) => (
              <FormControl variant="outlined" fullWidth margin="dense" size="medium">
                <InputLabel id={'organizations-input'}>Client Location</InputLabel>
                <Select labelId={'stores'} label={'Stores'} placeholder={'select a store'} value={value} onChange={onChange}>
                  {filteredClientStores.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Box marginY={1}>
            <Button
              type="button"
              variant="outlined"
              a11yLabel="Add New"
              size={'small'}
              sx={{
                fontSize: 'small'
              }}
              onClick={() => {
                navigate('/administration/organizations');
              }}
              appearance="outlined"
            />
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default AddCompanyForm;
