import Button from '@/components/Button';
import { Body1 } from '@/components/Typography';
import type { Timer } from '@/hooks/useTimer';
import { secondsToTime } from '@/util/format';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import type { StackProps } from '@mui/material';
import { Chip, Stack } from '@mui/material';

type Props = StackProps & {
  timer: Timer;
  disabled?: boolean;
  showTimer?: boolean;
  showStopButton?: boolean;
  showProgressText?: boolean;
};
const Stopwatch = ({ timer, disabled, showTimer, showStopButton, showProgressText, ...props }: Props) => {
  const ButtonIcon = timer.isRunning ? <PauseIcon /> : <PlayArrowIcon />;
  const ButtonLabel = timer.isRunning ? 'Pause' : timer.timeElapsed > 0 ? 'Resume' : 'Start';
  const ButtonColor = 'primary';
  const TimerAction = timer.isRunning ? timer.stopTimer : timer.startTimer;

  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'} {...props}>
      <Body1 fontWeight={'600'}>
        {timer.label} {showProgressText && timer.isRunning ? 'in progress' : ''}
      </Body1>
      {showTimer && <Chip color={'error'} variant={'outlined'} label={secondsToTime(timer.timeElapsed)} />}
      <Stack direction={'row'} spacing={1}>
        <Button
          sx={{ width: 100 }}
          disabled={disabled}
          startIcon={ButtonIcon}
          color={ButtonColor}
          label={ButtonLabel}
          variant="outlined"
          onClick={TimerAction}
        />
        {showStopButton && (
          <Button
            sx={{ width: 100 }}
            disabled={!timer.isRunning}
            startIcon={<StopIcon />}
            color={'error'}
            label={'Stop'}
            variant={'outlined'}
            onClick={() => timer.resetTimer()}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default Stopwatch;
