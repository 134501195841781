import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import LoadingOverlay from '@/components/LoadingOverlay';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import type { GetRpmPerformanceReportResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_PHARMACY_PERFORMANCE_REPORT } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { RpmPharmacyPerformanceReportType } from '@/types/remotePatientMonitoring';
import { formatDate, formatTime, getCurrentDate } from '@/util/format';
import { getCustomRowHeight } from '@/util/get';
import { DEFAULT_DEVICE_LENGTH } from '@/util/rpm';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { Box, Chip, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

const csvHeaders = [
  { label: 'COMPANY', key: 'company' },
  { label: 'TRUENTITY ID', key: 'truentityId' },
  { label: 'RPM ENROLLED AT', key: 'rpmEnrolledAt' },
  { label: 'DEVICES', key: 'devices' },
  { label: 'MONTHLY READINGS COUNT', key: 'monthlyReadingsCount' },
  { label: 'MONTHLY TIME SPENT (FORMAT)', key: 'monthlyTimeSpentFormat' },
  { label: 'MONTHLY TIME SPENT (MIN)', key: 'monthlyTimeSpentInMinutes' },
  { label: 'BASELINE READINGS', key: 'baselineReadings' },
  { label: 'AVERAGE READINGS', key: 'averageReadings' }
];

const RpmCompanies = () => {
  const [totalRowCount, setTotalRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const currentMonthYear = useMemo(() => formatTime(getCurrentDate(), 'MMM YYYY'), []);

  const [monthAndYear, setMonthAndYear] = useState<string>(currentMonthYear);
  const [pharmacyReportData, setPharmacyReportData] = useState<RpmPharmacyPerformanceReportType[]>([]);

  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const [callGetRpmPharmacyPerformanceReport, { data: rpmPharmacyPerformanceReportData, loading: rpmPharmacyPerformanceReportLoading }] =
    useLazyQuery<GetRpmPerformanceReportResponse>(GET_RPM_PHARMACY_PERFORMANCE_REPORT, {
      fetchPolicy: 'cache-and-network'
    });

  const callPharmacyQuery = async (isExport: boolean) => {
    await callGetRpmPharmacyPerformanceReport({
      variables: {
        monthYear: formatTime(monthAndYear, 'MMM YYYY'),
        pageNum: currentPage + 1,
        pageSize: DEFAULT_PAGE_SIZE,
        isExport: isExport
      }
    }).catch((error: ApolloError) => {
      console.error(error);
    });
  };

  const exportDataToCsv = () => {
    callPharmacyQuery(true);
  };

  const onFetchExportButtonClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv();
  };

  const generateFilename = monthAndYear => {
    const formattedDate = formatTime(monthAndYear, 'MMM, yyyy');

    return `RPM Company - ${formattedDate}.csv`;
  };

  const getRpmCompaniesDataFiltered = () => {
    const DEFAULT_STRING = 'Not Provided';

    const activitiesDataObject = pharmacyReportData?.map((data: RpmPharmacyPerformanceReportType) => {
      const devices = data.account?.accountsMonitoringDevices?.map(device => device.monitoringDevice.name).join(', ') || DEFAULT_STRING;
      const baselineReadings =
        data?.baselineReadings
          ?.map(reading => `${reading.value} (${reading.unit}) at ${formatDate(reading.recordedAt, 'MM/DD/YYYY HH:mm')}`)
          .join(', ') || DEFAULT_STRING;
      const averageReadings = data?.averageReadings?.map(reading => `${reading.value} (${reading.unit})`).join(', ') || DEFAULT_STRING;

      return {
        company: data?.relyingParty.name || DEFAULT_STRING,
        truentityId: data?.account.truentityId || DEFAULT_STRING,
        rpmEnrolledAt: data?.account?.rpmEnrolledAt || DEFAULT_STRING,
        devices: devices,
        monthlyReadingsCount: data?.monthlyReadingsCount || DEFAULT_STRING,
        monthlyTimeSpentFormat: data?.monthlyTimeSpentFormat || DEFAULT_STRING,
        monthlyTimeSpentInMinutes: data?.monthlyTimeSpentInMinutes || DEFAULT_STRING,
        baselineReadings: baselineReadings,
        averageReadings: averageReadings
      };
    });

    return activitiesDataObject;
  };

  useEffect(() => {
    if (rpmPharmacyPerformanceReportData?.getPharmacyPerformanceReport?.accountsPerformance) {
      setPharmacyReportData(rpmPharmacyPerformanceReportData?.getPharmacyPerformanceReport.accountsPerformance ?? []);
      setTotalRowCount(rpmPharmacyPerformanceReportData?.getPharmacyPerformanceReport?.meta?.totalCount || 0);
    }
  }, [rpmPharmacyPerformanceReportData]);

  useEffect(() => {
    callPharmacyQuery(false);
    setFetchDataButtonVisible(true);
  }, [monthAndYear, currentPage]);

  const columns: GridColDef<RpmPharmacyPerformanceReportType>[] = useMemo(
    () => [
      {
        field: 'company',
        headerName: 'Company',
        flex: 2,
        headerAlign: 'left',
        align: 'left',
        valueGetter: params => params.row.relyingParty.name ?? '',
        sortable: false
      },
      {
        field: 'truentityId',
        headerName: 'Truentity ID',
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        valueGetter: params => params.row.account.truentityId ?? '',
        sortable: false
      },
      {
        field: 'enrolledAt',
        headerName: 'RPM Enrolled At',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => {
          const rpmEnrolledAt = params.row.account?.rpmEnrolledAt;
          return rpmEnrolledAt ? new Date(rpmEnrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'devices',
        headerName: 'Devices',
        sortable: false,
        flex: 1,
        maxWidth: 150,
        align: 'left',
        headerAlign: 'left',
        renderCell: params => {
          const devices = params.row?.account?.accountsMonitoringDevices?.slice(0, DEFAULT_DEVICE_LENGTH);
          return (
            <Stack
              paddingY={1}
              height="100%"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="stretch"
              flexWrap="nowrap"
              gap=".3125rem"
            >
              {devices?.length === 0 ? (
                <Box>N/A</Box>
              ) : (
                <>
                  {devices?.map(device => (
                    <Box
                      width="100%"
                      key={device?.id}
                      textTransform="capitalize"
                      sx={{
                        textOverflow: 'ellipsis',
                        height: getCustomRowHeight(device?.latestReadings?.length ?? 0)
                      }}
                    >
                      <Chip size="small" label={device?.monitoringDevice?.name} />
                    </Box>
                  ))}
                </>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'monthlyReadingsCount',
        headerName: 'Monthly Readings Count',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params.row.monthlyReadingsCount ?? '',
        sortable: false
      },
      {
        field: 'monthlyTimeSpentFormat',
        headerName: 'Monthly Time Spent (formatted)',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params.row.monthlyTimeSpentFormat ?? '',
        sortable: false
      },
      {
        field: 'monthlyTimeSpentInMinutes',
        headerName: 'Monthly Time Spent (mins)',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params.row.monthlyTimeSpentInMinutes ?? '',
        sortable: false
      }
    ],
    []
  );

  const getCustomRowId = useCallback((row: RpmPharmacyPerformanceReportType) => {
    return `${row.account.truentityId}`;
  }, []);

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '16px',
        '&.MuiStack-root': { backgroundColor: color.paper, borderRadius: '8px', padding: '24px 16px' }
      }}
    >
      <Stack
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
        sx={{
          width: '100%',
          height: 'auto',
          paddingY: 1
        }}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          sx={{
            width: '100%',
            height: 'auto',
            mb: 3
          }}
        >
          <Box>
            <TruentityDatePicker
              showMonth={true}
              showYear={true}
              slotProps={{
                actionBar: {
                  actions: ['accept']
                }
              }}
              openTo="month"
              views={['month', 'year']}
              value={monthAndYear}
              onChange={handleMonthYear}
            />
          </Box>

          {fetchDataButtonVisible ? (
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={pharmacyReportData.length === 0}
              onClick={() => {
                onFetchExportButtonClicked();
              }}
            >
              Load for Export
            </Button>
          ) : (
            <Button isLoading={rpmPharmacyPerformanceReportLoading} color="primary" variant="contained" size="small">
              {pharmacyReportData && pharmacyReportData.length > 0 && (
                <CSVLink
                  headers={csvHeaders}
                  data={getRpmCompaniesDataFiltered()}
                  filename={generateFilename(monthAndYear)}
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  {rpmPharmacyPerformanceReportLoading ? 'Loading csv...' : 'Export'}
                </CSVLink>
              )}
            </Button>
          )}
        </Stack>

        <TruentityDataGrid
          name={'rpm-companies'}
          getRowHeight={() => 'auto'}
          autoHeight
          rows={pharmacyReportData}
          rowCount={totalRowCount}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          getRowId={getCustomRowId}
          loading={rpmPharmacyPerformanceReportLoading}
          paginationMode="server"
          sortingMode="server"
          columns={columns}
          disableRowSelectionOnClick
          sx={{ backgroundColor: '#ffffff' }}
        />
      </Stack>
      <LoadingOverlay active={rpmPharmacyPerformanceReportLoading && fetchDataButtonVisible} text="Loading Rpm Companies" />
    </Stack>
  );
};

export default RpmCompanies;
