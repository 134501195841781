import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import DeviceManagement from './DeviceManagement';

const RpmDevices = () => {
  const { isReadOnly } = useRpmWorkflowStore();
  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnly}>
      <DeviceManagement />
    </BaseRpmWorkflowTabContent>
  );
};

export default RpmDevices;
