import type { GridProps } from '@mui/material';
import { Grid, Stack } from '@mui/material';
import type { ReactNode } from 'react';
import GridItemLabel from './GridItemLabel';
import { Body1 } from './Typography';

type RowProps = GridProps & {
  icon?: ReactNode;
  label: ReactNode;
  value: ReactNode;
  justifyContent?: string;
  spacing?: number;
};

export const GridItem = ({ icon, label, value, justifyContent, spacing, ...props }: RowProps) => {
  value = value || '---';
  justifyContent = justifyContent || 'space-between';

  return (
    <Stack component={Grid} item direction={'row'} alignItems="center" spacing={spacing} justifyContent={justifyContent} {...props}>
      <GridItemLabel icon={icon} label={label} />
      <Body1 sx={{ width: '50%', wordWrap: 'break-word' }} textAlign="left">
        {value}
      </Body1>
    </Stack>
  );
};
