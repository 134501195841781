import type { RelyingPartyAdminData } from '@/routes/Administration';
import type { FollowUpFormHandles } from '@/routes/PatientDetails/FollowUps/Components/FollowUpForm';
import FollowUpStep from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/FollowUpStep';
import type { RequestExternalStepsHandles } from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/RequestExternalServiceStep';
import RequestExternalServiceStep from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/RequestExternalServiceStep';
import type { VerifyUserFormHandles } from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/VerifyUserStep';
import VerifyUserStep from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/VerifyUserStep';
import type { PatientsData } from '@/routes/Patients/patients';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import type { RpmAccountSnapshotType } from '@/types/remotePatientMonitoring';
import { RpmStatusTypes } from '@/types/remotePatientMonitoring';
import { DialogContent } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useRef, useState } from 'react';
import Button from '../Button';
import HorizontalStepper from '../Stepper/HorizontalStepper';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import ConfirmDialog from './ConfirmDialog';

type Props = BaseDialogProps & {
  title: string;
  patientInfo: RpmAccountSnapshotType | PatientsData;
  hideDialog: () => void;
  refetch?: any;
};

const ScheduleEnrollmentDialog = ({ title, patientInfo, hideDialog, refetch, ...props }: Props) => {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const verifyUserFormRef = useRef<VerifyUserFormHandles>(null);
  const followupFormRef = useRef<FollowUpFormHandles>(null);
  const externalServiceRequestRef = useRef<RequestExternalStepsHandles>(null);
  const truentityId = useMemo(() => patientInfo.truentityId, [patientInfo]);
  const rpmStatus = useMemo(() => patientInfo.rpmStatus, [patientInfo]);
  const isRpmEnrolled = rpmStatus === RpmStatusTypes.ENROLLED;
  const doNotCall = useMemo(() => patientInfo.doNotCall, [patientInfo]);
  const accountAssignees: RelyingPartyAdminData[] = useMemo(
    () => patientInfo.accountsAssignments?.map(assignment => assignment.relyingPartyAdmin) || [],
    [patientInfo]
  );

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number>(0);
  const [isVerifyFormChanged, setIsVerifyFormChanged] = useState<boolean>(false);
  const [savedFollowupsData, setSavedFollowupsData] = useState<FollowUpRemindersTypes[] | null>(null);
  const [isExternalServicesLoading, setIsExternalServicesLoading] = useState<boolean>(false);
  const [isExternalServicesCompleted, setIsExternalServicesCompleted] = useState<boolean>(false);

  const handleCancelDialog = useCallback(() => {
    if (completedSteps > 0) {
      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Cancellation of Enrollment Schedule',
        message: 'This will cancel, but not the steps already taken. Are you sure?',
        onAgree: () => {
          hideDialog();
          refetch && refetch();
          modal.hide();
        },
        onDisagree: () => modal.hide()
      });
    } else {
      hideDialog();
    }
  }, [completedSteps, hideDialog, refetch, showModal]);

  const increaseCurrentStep = () => setCurrentStep((previous: number) => previous + 1);
  const increaseCompletedStep = () => setCompletedSteps((previous: number) => previous + 1);
  const decreaseCurrentStep = () => setCurrentStep((previous: number) => previous - 1);

  const handleVerifyUserSave = async () => {
    verifyUserFormRef?.current?.submitForm().then(result => {
      if (result && result?.data?.updateUserAccountProfile?.account) {
        increaseCurrentStep();
        increaseCompletedStep();
        enqueueSnackbar("The patient's details have been successfully updated", {
          variant: 'success'
        });
      } else {
        enqueueSnackbar("The patient's details could not be updated successfully", {
          variant: 'error'
        });
      }
    });
  };

  const handleFollowUpSave = async () => {
    try {
      const result = await followupFormRef.current?.submitForm();
      if (result) {
        setSavedFollowupsData(result);
        increaseCurrentStep();
        increaseCompletedStep();
      }
    } catch (error) {
      enqueueSnackbar('An error occurred while saving the follow-up.', { variant: 'error' });
    }
  };

  const handleExternalServices = async () => {
    try {
      const results = await externalServiceRequestRef.current?.submitForm();
      if (results) {
        const isRequestsSuccess = true;

        setIsExternalServicesCompleted(isRequestsSuccess);
      }
    } catch (error) {
      console.error('Error while requesting external services:', error);
      enqueueSnackbar('An error occurred while requesting external services', { variant: 'error' });
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={handleCancelDialog} fullWidth maxWidth="md">
      <DialogContent>
        <HorizontalStepper
          steps={[
            {
              label: 'Verify Patient Profile',
              children: (
                <VerifyUserStep truentityId={truentityId} setIsVerifyFormChanged={setIsVerifyFormChanged} ref={verifyUserFormRef} />
              ),
              canNext: () => !isVerifyFormChanged,
              tryNext: () => increaseCurrentStep(),
              tryBack: () => decreaseCurrentStep(),
              canSave: () => isVerifyFormChanged,
              trySave: () => handleVerifyUserSave()
            },
            {
              label: 'Schedule Initial Visit',
              children: (
                <FollowUpStep
                  truentityId={truentityId}
                  ref={followupFormRef}
                  isRpmEnrolled={isRpmEnrolled}
                  doNotCall={doNotCall ?? false}
                  accountAssignees={accountAssignees}
                  savedFollowupsData={savedFollowupsData}
                />
              ),
              tryBack: () => decreaseCurrentStep(),
              customActionButtons: [<Button onClick={handleFollowUpSave}>Schedule</Button>]
            },
            {
              label: 'Request Medications and Clinical Summary',
              children: (
                <RequestExternalServiceStep
                  truentityId={truentityId}
                  ref={externalServiceRequestRef}
                  setIsExternalServicesLoading={setIsExternalServicesLoading}
                />
              ),
              tryBack: () => decreaseCurrentStep(),
              customActionButtons: [
                <Button onClick={handleExternalServices} isLoading={isExternalServicesLoading}>
                  Send Requests
                </Button>,
                <Button
                  color="success"
                  onClick={() => {
                    hideDialog();
                    refetch && refetch();
                  }}
                  disabled={!isExternalServicesCompleted}
                >
                  Done
                </Button>
              ]
            }
          ]}
          currentStep={currentStep}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default ScheduleEnrollmentDialog;
