import { getApprovalStatusChipStyles } from '@/util/rpm';
import { Chip } from '@mui/material';
import React from 'react';

type Props = {
  approvalStatus: string;
};

const RpmApprovalStatusChip: React.FC<Props> = ({ approvalStatus }) => {
  const chipStyles = getApprovalStatusChipStyles(approvalStatus);
  return (
    <Chip
      size="small"
      label={chipStyles?.text}
      sx={{
        minWidth: '40px',
        backgroundColor: chipStyles?.color,
        fontSize: 'small',
        color: 'white'
      }}
    />
  );
};

export default RpmApprovalStatusChip;
