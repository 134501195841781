import type React from 'react';

import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';

import AddDeviceByDeviceId from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmDevices/AddDeviceByDeviceId';
import AddDeviceByGatewayId from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmDevices/AddDeviceByGatewayId';
import DialogContent from '@mui/material/DialogContent';
import MuiTabs from '../MuiTabs';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  title: string;
  id: string | undefined;
  deviceId?: string;
  hideDialog: () => void;
};

const NewDeviceDialog = ({ id, title, hideDialog, deviceId, ...props }: Props): React.ReactElement => {
  const isUpdateModal = deviceId ? true : false;
  return (
    <BaseDialog title={title} hideDialog={hideDialog} fullWidth maxWidth={'sm'} {...props}>
      <DialogContent sx={{ backgroundColor: '#f5f5f5' }}>
        {isUpdateModal ? (
          <AddDeviceByDeviceId id={id} hideDialog={hideDialog} deviceId={deviceId} />
        ) : (
          <MuiTabs
            customPadding
            TabContentProps={{ padding: 0 }}
            tabs={[
              { label: 'Using Gateway ID', children: <AddDeviceByGatewayId id={id} hideDialog={hideDialog} /> },
              {
                label: 'Using Device ID',
                children: <AddDeviceByDeviceId id={id} hideDialog={hideDialog} deviceId={deviceId} />
              }
            ]}
          />
        )}
      </DialogContent>
    </BaseDialog>
  );
};

export default NewDeviceDialog;
