import type { StackProps } from '@mui/material';
import { Stack } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import type { ReactNode } from 'react';
import Chart from 'react-apexcharts';
import { H3 } from '../Typography';

type BaseChartProps = StackProps & {
  title?: ReactNode;
  children: ReactNode;
  additionalCss?: any;
};
export const BaseChart = ({ title, children, additionalCss, ...props }: BaseChartProps) => {
  return (
    <Stack
      sx={{
        padding: '15px',
        marginBottom: '10px',
        borderRadius: additionalCss?.borderRadius || undefined,
        width: additionalCss?.width ? additionalCss.width : null,
        backgroundColor: additionalCss?.backgroundColor ? additionalCss.backgroundColor : '#ffffff'
      }}
      {...props}
    >
      {title && <H3 textAlign={'center'}>{title}</H3>}
      {children}
    </Stack>
  );
};

export const PieChart = ({ options, series, width, ...props }) => {
  options = {
    ...options,
    dataLabels: {
      enabled: false
    },
    theme: {
      monochrome: {
        enabled: false
      }
    },
    colors: ['#3498DB', '#2ECC71', '#F1C40F', '#E67E22', '#E74C3C', '#9B59B6', '#1ABC9C', '#95A5A6']
  };

  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} type="pie" width={width} />
    </BaseChart>
  );
};

export const BarChart = ({ options, series, width, height, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} type="bar" width={width} height={height} />
    </BaseChart>
  );
};

export const LineChart = ({ options, series, width, height, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart style={{ minWidth: '-webkit-fill-available' }} options={options} series={series} type="line" width={width} height={height} />
    </BaseChart>
  );
};

export const DonutChart = ({ options, series, labels, width, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart options={options} series={series} labels={labels} type="donut" width={width} />
    </BaseChart>
  );
};

export const SparkLine = ({ title, subTitle, series, ...props }) => {
  return (
    <BaseChart {...props}>
      <Chart
        type="area"
        options={{
          chart: {
            type: 'area',
            height: 160,
            sparkline: {
              enabled: true
            }
          },
          stroke: {
            curve: 'straight'
          },
          yaxis: {
            min: 0,
            labels: {
              show: false
            }
          },
          title: {
            text: title,
            offsetX: 0,
            style: {
              fontSize: '24px'
            }
          },
          subtitle: {
            text: subTitle,
            offsetX: 0,
            style: {
              fontSize: '14px'
            }
          }
        }}
        series={series}
      />
    </BaseChart>
  );
};

export const EChart = ({ option, ...props }) => {
  return (
    <ReactECharts
      option={option}
      style={{ height: '420px', width: '550px' }}
      notMerge={true}
      lazyUpdate={true}
      opts={{ renderer: 'svg' }}
    />
  );
};
