import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import { DEFAULT_PAGE_SIZE } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmNavigateAwayDialog from '@/components/Dialogs/ConfirmNavigateAwayDialog';
import Stopwatch from '@/components/Stopwatch/Stopwatch';
import { Body2 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type {
  CreateRpmSetupResponse,
  GetAccountMonitoringDevicesResponse,
  GetRpmSetupResponse,
  RpmSetupTypes,
  UpdateRpmSetupResponse
} from '@/graphql/remotePatientMonitoring';
import {
  CREATE_RPM_SETUPS,
  GET_ACCOUNT_MONITORING_DEVICE_NAMES,
  GET_RPM_SETUPS,
  UPDATE_RPM_SETUP
} from '@/graphql/remotePatientMonitoring';
import RpmTabStatusHandler from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/RpmTabStatusHandler';
import RpmWorkflowToolbar from '@/routes/PatientDetails/RemotePatientMonitoring/RpmWorkflowToolbar';
import { theme } from '@/styles/mui-theme';
import type { AccountsMonitoringDeviceType } from '@/types/remotePatientMonitoring';
import { ActivityLogType, RpmSetupStatusTypes, RpmStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { findMissingElements } from '@/util/array';
import { mandatoryTabsForEnrolment } from '@/util/constants';
import { pathnameIncludes } from '@/util/location';
import { enrollmentEligibility, getSelectedTabData, refactorNotAvailableRpmSetups } from '@/util/rpm';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { Box, IconButton, Stack, Tab } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import RpmWorkflowStatusBar from './RpmWorkflowStatusBar';

export enum RpmMonitoringActivityTypes {
  CareActivity,
  Encounter
}

export const getRpmWorkflowSessionType = () => {
  if (pathnameIncludes('patient-setup/care-activity')) {
    return RpmMonitoringActivityTypes.CareActivity;
  } else if (pathnameIncludes('patient-setup/encounter')) {
    return RpmMonitoringActivityTypes.Encounter;
  }
  return undefined;
};

const getRpmWorkflowSessionTypePathStringFromId = id => {
  if (id === RpmMonitoringActivityTypes.CareActivity) {
    return 'care-activity';
  } else if (id === RpmMonitoringActivityTypes.Encounter) {
    return 'encounter';
  }
  return '';
};

type WorkflowTabType = {
  label: string;
  path: string;
  type: RpmMonitoringActivityTypes;
  tabType: RpmWorkflowTab;
};

const workflowTabs: WorkflowTabType[] = [
  {
    label: 'Clinical Summary',
    path: 'clinical-summary',
    type: RpmMonitoringActivityTypes.CareActivity,
    tabType: RpmWorkflowTab.CLINICAL_SUMMARY
  },
  { label: 'Devices', path: 'devices', type: RpmMonitoringActivityTypes.Encounter, tabType: RpmWorkflowTab.DEVICES },
  {
    label: 'Consent Management',
    path: 'consent-management',
    type: RpmMonitoringActivityTypes.Encounter,
    tabType: RpmWorkflowTab.CONSENT_MANAGEMENT
  },
  {
    label: 'Medication Reconciliation',
    path: 'medication-reconciliation',
    type: RpmMonitoringActivityTypes.CareActivity,
    tabType: RpmWorkflowTab.MEDICAL_RECONCILIATION
  },
  {
    label: 'Medical Consults',
    path: 'medical-consults',
    type: RpmMonitoringActivityTypes.Encounter,
    tabType: RpmWorkflowTab.MEDICAL_CONSULTS
  },
  { label: 'Care Plan', path: 'care-plan', type: RpmMonitoringActivityTypes.Encounter, tabType: RpmWorkflowTab.CARE_PLAN },
  { label: 'Configuration', path: 'configuration', type: RpmMonitoringActivityTypes.CareActivity, tabType: RpmWorkflowTab.CONFIGURATION }
];

const RpmMonitoringActivityTypesLabel = new Map<RpmMonitoringActivityTypes, string>([
  [RpmMonitoringActivityTypes.CareActivity, 'Care Activity'],
  [RpmMonitoringActivityTypes.Encounter, 'Encounter']
]);

const RpmWorkflow = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { patientInfo } = useContext(PatientDetailContext);
  const { rpmSetupTabs, setRpmSetupTabs, setIsEnrollmentEligibility, editRpmStatus } = useRpmSetupStore();
  const { enqueueSnackbar } = useSnackbar();

  const { activeTimer, setActiveTimer, careActivityTimer, encounterTimer } = useRpmWorkflowStore();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState<RpmSetupTypes>();
  const [type, setType] = useState<ActivityLogType>(ActivityLogType.COMMUNICATION_SUMMARY);
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);

  const [monitoringDevices, setMonitoringDevices] = useState<AccountsMonitoringDeviceType[]>([]);

  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const [getMonitoringDevicesQuery, { data: monitoringDevicesData, called: monitoringDevicesCalled, loading: monitoringDevicesLoading }] =
    useLazyQuery<GetAccountMonitoringDevicesResponse>(GET_ACCOUNT_MONITORING_DEVICE_NAMES, { fetchPolicy: 'cache-and-network' });
  const { data: rpmSetups, refetch: rpmSetupsRefetch } = useQuery<GetRpmSetupResponse>(GET_RPM_SETUPS, {
    variables: {
      truentityId: id
    }
  });
  const [createRpmSetups] = useMutation<CreateRpmSetupResponse>(CREATE_RPM_SETUPS);

  const openActivityDrawer = (type: ActivityLogType) => {
    setType(type);
    setIsActivityDrawerOpen(true);
  };
  const closeActivityDrawer = () => setIsActivityDrawerOpen(false);

  const getMonitoringDevices = useCallback(async () => {
    try {
      await getMonitoringDevicesQuery({
        variables: {
          truentityId: id,
          pageSize: DEFAULT_PAGE_SIZE,
          pageNum: 1
        }
      });
    } catch (error) {
      console.error('error while fetching devices', error);
    }
  }, [getMonitoringDevicesQuery, id]);

  const createNotAvailableRpmSetups = useCallback(
    (notAvailableRpmSetups: string[]) => {
      createRpmSetups({
        variables: {
          truentityId: id,
          rpmSetupValues: refactorNotAvailableRpmSetups(notAvailableRpmSetups)
        }
      }).then(() => rpmSetupsRefetch());
    },
    [createRpmSetups, id, rpmSetupsRefetch]
  );

  const checkRpmStatusTabAvailability = useCallback(
    (rpmWorkflowSetups: RpmSetupTypes[]) => {
      const rpmWorkflowTabTypes: string[] = Object.values(RpmWorkflowTab);
      const availableTabs = rpmWorkflowSetups.filter(setup => rpmWorkflowTabTypes.includes(setup.type));
      let notAvailableTabs = rpmWorkflowTabTypes.filter(tabType => !rpmWorkflowSetups.some(setup => setup.type === tabType));
      const mandatoryTypeArray = rpmWorkflowSetups.filter(setup => setup.isRequiredForEnrollment).map(setup => setup.type);
      const missingElements = findMissingElements(mandatoryTypeArray, mandatoryTabsForEnrolment);

      if (notAvailableTabs.length === 0 && missingElements.length > 0) notAvailableTabs = missingElements;

      if (notAvailableTabs.length !== 0) {
        return createNotAvailableRpmSetups(notAvailableTabs);
      } else {
        const updatedAvailableTabs = availableTabs.map(tabItem => ({ ...tabItem, canCurrentTabBeCompleted: true }));
        setRpmSetupTabs(updatedAvailableTabs);
      }
    },
    [createNotAvailableRpmSetups, setRpmSetupTabs]
  );

  const onStatusChange = (completed: boolean) => {
    try {
      if (selectedTab?.id) {
        updateRpmSetup({
          variables: {
            rpmSetupId: selectedTab?.id,
            status: completed ? RpmSetupStatusTypes.COMPLETED : RpmSetupStatusTypes.IN_PROGRESS,
            isManualStatusUpdate: true
          }
        })
          .then(res => {
            if (!res?.data) {
              throw new Error('Empty / Null data response');
            }
            const { status, message, rpmSetups } = res.data.updateRpmSetup;

            const variant = status === 'Success' ? 'success' : 'error';
            if (variant === 'error') {
              enqueueSnackbar(message, { variant });
            } else {
              editRpmStatus({ ...rpmSetups });
              enqueueSnackbar('RPM tab status updated successfully', { variant });
            }
          })
          .catch(error => {
            console.error(error);
            enqueueSnackbar('Failed to update RPM setup', { variant: 'error' });
          });
      }
    } catch (error) {
      enqueueSnackbar('Failed to update RPM setup', { variant: 'error' });
    }
  };

  useEffect(() => {
    const tabIndex = workflowTabs.findIndex(item => pathnameIncludes(item.path));
    setSelectedTabIndex(tabIndex);
  }, [location.pathname]);

  useEffect(() => {
    const selectedTab = getSelectedTabData(rpmSetupTabs, workflowTabs[selectedTabIndex].tabType);
    setSelectedTab(selectedTab);
  }, [selectedTabIndex, rpmSetupTabs]);

  useEffect(() => {
    const active = [careActivityTimer, encounterTimer].find(timer => timer.isRunning);
    setActiveTimer(active);
    // disabling this rule here otherwise maximum callstack error is triggered due to individual timer objects causing endless loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careActivityTimer.isRunning, careActivityTimer.timeElapsed, encounterTimer.isRunning, encounterTimer.timeElapsed]);

  useEffect(() => {
    getMonitoringDevices().catch(err => console.error(err));
  }, [getMonitoringDevices, id]);

  useEffect(() => {
    if (patientInfo?.rpmStatus === null) {
      navigate('/home');
    }
  }, [navigate, patientInfo]);

  useEffect(() => {
    if (monitoringDevicesCalled && !monitoringDevicesLoading && monitoringDevicesData) {
      const devices = monitoringDevicesData?.getAccountDevices?.devices;
      setMonitoringDevices(devices);
    }
  }, [monitoringDevicesLoading, monitoringDevicesData, monitoringDevicesCalled]);

  useEffect(() => {
    if (rpmSetups?.getRpmSetup && Array.isArray(rpmSetups.getRpmSetup)) {
      const rpmWorkflowSetups = rpmSetups.getRpmSetup;

      checkRpmStatusTabAvailability(rpmWorkflowSetups);
    }
  }, [checkRpmStatusTabAvailability, rpmSetups]);

  useEffect(() => {
    if (rpmSetupTabs && rpmSetupTabs.length > 0 && patientInfo?.rpmStatus !== RpmStatusTypes.ENROLLED) {
      setIsEnrollmentEligibility(enrollmentEligibility(rpmSetupTabs));
    }
  }, [rpmSetupTabs, patientInfo, setIsEnrollmentEligibility]);

  const getIcon = useCallback(
    (tabType: RpmWorkflowTab) => {
      const rpmTabStatus = rpmSetupTabs.find(setupTab => setupTab.type === tabType);

      return (
        <CheckCircleIcon
          sx={{ visibility: rpmTabStatus?.status === RpmSetupStatusTypes.COMPLETED ? 'visible' : 'hidden' }}
          fontSize={'medium'}
          color="success"
        />
      );
    },
    [rpmSetupTabs]
  );

  return (
    <Stack spacing={1} p={1} sx={{ background: theme.palette.background.default, borderRadius: 5 }}>
      <RpmWorkflowStatusBar />
      <RpmWorkflowToolbar devices={monitoringDevices}>
        {/* TODO: Time-Tracking Design Change: To be removed: @Oct 2023 */}
        {/* <Stopwatch
          timer={careActivityTimer}
          disabled={activeType === RpmMonitoringActivityTypes.Encounter || (activeTimer && activeTimer.id !== careActivityTimer.id)}
        />
        <Stopwatch
          timer={encounterTimer}
          disabled={activeType === RpmMonitoringActivityTypes.CareActivity || (activeTimer && activeTimer.id !== encounterTimer.id)}
        /> */}
        <Box>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Body2>Communications Summary</Body2>
            <IconButton title="Communications Summary" onClick={() => openActivityDrawer(ActivityLogType.COMMUNICATION_SUMMARY)}>
              <StickyNote2Icon color="primary" />
            </IconButton>
          </Stack>
        </Box>
      </RpmWorkflowToolbar>

      {activeTimer && (
        <Stack direction="row" flex={1}>
          <Stopwatch
            p={2}
            sx={{ backgroundColor: '#fffde7', width: '100%' }}
            timer={activeTimer}
            showStopButton={true}
            showTimer={true}
            showProgressText={true}
          />
        </Stack>
      )}

      <Stack direction="column" py={4} px={2} sx={{ background: theme.palette.background.paper, borderRadius: 4 }}>
        <Tabs
          orientation={'horizontal'}
          variant={'fullWidth'}
          value={selectedTabIndex}
          sx={{
            '& .MuiTab-root': {
              fontSize: '1rem',
              textTransform: 'capitalize'
            }
          }}
        >
          {workflowTabs.map((tab, index) => (
            <Tab
              label={tab.label}
              key={`tab-${index}`}
              id={`tab-${index}`}
              icon={getIcon(tab.tabType)}
              iconPosition="top"
              data-typeid={tab.type}
              onClick={() => {
                navigate(getRpmWorkflowSessionTypePathStringFromId(tab.type) + '/' + tab.path);
              }}
            />
          ))}
        </Tabs>
      </Stack>
      <Stack direction="column" spacing={2} p={4} sx={{ background: theme.palette.background.paper, borderRadius: 4 }}>
        {selectedTab?.status && (
          <RpmTabStatusHandler
            tabType={selectedTab.type as RpmWorkflowTab}
            tabStatus={selectedTab?.status}
            onStatusChange={onStatusChange}
            canCurrentTabBeCompleted={selectedTab.canCurrentTabBeCompleted}
          />
        )}
        <Outlet />
      </Stack>

      {activeTimer && (
        <ConfirmNavigateAwayDialog
          when={() => {
            return activeTimer?.isRunning && document.activeElement?.getAttribute('data-typeId') !== activeTimer.id.toString();
          }}
          title={RpmMonitoringActivityTypesLabel.get(activeTimer.id) + ' is running'}
          message={'If you navigate away, the timer will be stopped.  Stop anyway?'}
          onNavigateAway={() => {
            console.warn('navigated away');
            activeTimer?.stopTimer();
          }}
        />
      )}

      <ActivityDrawer isOpen={isActivityDrawerOpen} onClose={closeActivityDrawer} type={type} />
    </Stack>
  );
};

export default RpmWorkflow;
