import Alert from '@/components/Alert';
import AutoSave from '@/components/AutoSave';
import Button from '@/components/Button';
import InteractivePanel from '@/components/InteractivePanel';
import { H4 } from '@/components/Typography';
import type {
  AccountProviderType,
  GetAccountConfigurationsTypes,
  RpmSetupTypes,
  SelectedProvidersType,
  UpdateRpmSetupResponse
} from '@/graphql/remotePatientMonitoring';
import { CREATE_MULTIPLE_ACCOUNT_CONFIGURATIONS, GET_ACCOUNT_CONFIGURATIONS, UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import { RpmSetupStatusTypes, RpmWorkflowTab } from '@/types/remotePatientMonitoring';
import { getSelectedTabData, updateRpmTabStatus } from '@/util/rpm';
import { useProviderStore } from '@/zustand/ProviderStore';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useMutation, useQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import ProviderConfigElement from './Components/ReportCommunication/ProviderConfigElement';

export type FormValues = {
  providerConfig?: SelectedProvidersType[];
  patientConfig: {
    isRpmReportPatientByEmail: boolean;
    isRpmReportPatientByText: boolean;
  };
};

const defaultValues: FormValues = {
  patientConfig: {
    isRpmReportPatientByEmail: false,
    isRpmReportPatientByText: false
  }
};

const ReportCommunication = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { rpmSetupTabs, editRpmStatus } = useRpmSetupStore();
  const methods = useForm<FormValues>({ defaultValues, mode: 'onBlur' });
  const { control, setValue, watch } = methods;
  const { fields, append, remove } = useFieldArray({ control, name: 'providerConfig' });
  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);
  const providerConfig = watch('providerConfig');

  // const isProviderAdded = fields && fields.length > 0 ? (providerConfig ?? []).some(config => config.providerId !== '') : false;

  const [accountsProviders, setAccountsProviders] = useState<AccountProviderType[]>([]);
  const autoCompletionStatus = useRef<RpmSetupStatusTypes | null>(null);

  const { showAssignedProviders, assignedProviders, setShowAssignedProviders } = useProviderStore();

  const [crateMultipleAccountConfigurations] = useMutation(CREATE_MULTIPLE_ACCOUNT_CONFIGURATIONS);
  const { data: accountConfigData, loading: accountConfigLoading } = useQuery<GetAccountConfigurationsTypes>(GET_ACCOUNT_CONFIGURATIONS, {
    variables: {
      truentityId: id
    },
    fetchPolicy: 'cache-and-network'
  });
  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);

  const removeElement = (id: number) => {
    remove(id);
  };

  const handleInteractivePanel = () => {
    append({ providerId: '', isRpmReportProviderByEmail: false, isRpmReportProviderByFax: false, isRpmReportProviderByText: false });
  };

  const handleSubmitImpl = async (values: FormValues) => {
    try {
      const providerConfig = values.providerConfig?.filter(config => config.providerId !== '');
      const response = await crateMultipleAccountConfigurations({
        variables: {
          truentityId: id,
          configurationsInputs: {
            providerConfig: providerConfig,
            patientConfig: values.patientConfig
          }
        }
      });

      if (!response.data.createMultipleAccountConfigurations) {
        throw new Error('Empty / Null Response');
      }

      const data = response.data.createMultipleAccountConfigurations;
      const variant = data!.status === 'Success' ? 'success' : 'error';

      if (variant === 'success') {
        autoCompletionStatus.current =
          providerConfig && providerConfig?.length > 0 ? RpmSetupStatusTypes.COMPLETED : RpmSetupStatusTypes.IN_PROGRESS;
      }

      enqueueSnackbar(data.message, { variant: variant });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Request failed', { variant: 'error' });
    }
  };

  const changeRpmStatus = async (rpmSetupTabs: RpmSetupTypes[], status: RpmSetupStatusTypes) => {
    try {
      await updateRpmTabStatus(rpmSetupTabs, RpmWorkflowTab.CONFIGURATION, editRpmStatus, updateRpmSetup, true, status);
    } catch (err) {
      console.error('Error updating RPM Tab status:', err);
    }
  };

  useEffect(() => {
    if (rpmSetupTabs.length > 0 && typeof autoCompletionStatus.current === 'string') {
      const selectedTab = getSelectedTabData(rpmSetupTabs, RpmWorkflowTab.CONFIGURATION);

      if (selectedTab?.status !== autoCompletionStatus.current) {
        changeRpmStatus(rpmSetupTabs, autoCompletionStatus.current);
        autoCompletionStatus.current = null;
      }
    }
  }, [rpmSetupTabs, autoCompletionStatus.current]);

  useEffect(() => {
    setAccountsProviders(assignedProviders);
  }, [assignedProviders]);

  useEffect(() => {
    if (fields.length === 0) {
      setValue('patientConfig.isRpmReportPatientByEmail', false);
      setValue('patientConfig.isRpmReportPatientByText', false);
    }
  }, [fields]);

  useEffect(() => {
    if (accountConfigData && accountConfigData.getAccountConfigurations && !accountConfigLoading) {
      const response = accountConfigData.getAccountConfigurations;

      const mappedFormValues: FormValues = {
        providerConfig: response.map(configuration => ({
          providerId: configuration.provider.id,
          isRpmReportProviderByFax: configuration.rpmReportProviderByFax,
          isRpmReportProviderByEmail: configuration.rpmReportProviderByEmail,
          isRpmReportProviderByText: configuration.rpmReportProviderByText
        })),
        patientConfig: {
          isRpmReportPatientByEmail: response[0]?.rpmReportPatientByEmail || false,
          isRpmReportPatientByText: response[0]?.rpmReportPatientByText || false
        }
      };

      setValue('providerConfig', mappedFormValues.providerConfig);
      setValue('patientConfig', mappedFormValues.patientConfig);
    }
  }, [accountConfigData, accountConfigLoading]);

  return (
    <FormProvider {...methods}>
      <Stack component="form">
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4}>
            <H4>Reports to Providers</H4>
            <Stack direction="row" justifyContent="flex-end">
              <Button label="MANAGE PROVIDERS" size={'small'} onClick={() => setShowAssignedProviders(!showAssignedProviders)} />
            </Stack>
          </Stack>
          {!assignedProviders?.find(v => v.isPrimaryCare) && (
            <Alert
              status={'warning'}
              variant="outlined"
              description={
                'PCP (Primary Care Provider) not assigned. Click Manage Providers to assign one and configure here to send the RPM Reports.'
              }
            />
          )}
          <Stack spacing={3}>
            {fields.map((field, index) => {
              return (
                <ProviderConfigElement
                  key={field.id}
                  accountsProviders={accountsProviders}
                  index={index}
                  removeElement={removeElement}
                  control={control}
                  setValue={setValue}
                  providerConfig={providerConfig}
                  watch={watch}
                />
              );
            })}
            <InteractivePanel
              text="Add a provider"
              icon={<AddIcon />}
              onClick={handleInteractivePanel}
              isSelect={fields.length !== accountsProviders.length}
            />
          </Stack>
        </Stack>
        {/* <Box flex={0.4}>
          <PatientConfigElement control={control} setValue={setValue} isProviderAdded={isProviderAdded} watch={watch} />
        </Box> */}
        <AutoSave defaultValues={defaultValues} onSubmit={onSubmit} disableProgressAnimation />
      </Stack>
    </FormProvider>
  );
};

export default ReportCommunication;
