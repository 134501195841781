import type { GetClientOrganizationsResponse } from '@/graphql/administration';
import { GET_CLIENT_ORGANIZATIONS_STANDARD } from '@/graphql/administration';
import type { DeleteTaskResponse, SaveUpdateTaskResponse } from '@/graphql/taskEncounter';
import { DELETE_TASK, SAVE_UPDATE_TASK } from '@/graphql/taskEncounter';
import type { ClientOrganizationObject, ClientStoreObject } from '@/types/administration';
import type { GridColDef } from '@mui/x-data-grid-pro';
import type { SyntheticEvent } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Icon from '@/elements/Icon';
import Link from '@/elements/Link';
import useToken from '@/hooks/useToken';
import { Role } from '@/types/admin';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import DeleteIcon from '@mui/icons-material/Delete';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';

import { color } from '@/styles/assets/colors';
import { formatDateIgnoreTZ } from '@/util/format';

import { H3 } from '@/components/Typography';

import Button from '@/components/Button';
import PatientDetailContext from '@/context/patientDetailContext';
import { TASK_MODE_OF_RESOLUTION_TYPES, TASK_TYPES, TASK_WRAP_UP_TYPES, UPDATED_SUB_TASK_TYPES } from '@/types/medication';
import { FormControl, InputLabel, MenuItem, Paper, Select, Stack, Tab, Tabs } from '@mui/material';
import moment from 'moment';

import AttemptedIcon from '@mui/icons-material/Done';
import PendingIcon from '@mui/icons-material/Pending';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { DeleteEncounterConfirmationDialog } from '@/components/Dialogs';
import EditIcon from '@mui/icons-material/Edit';

import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { TaskType } from '@/types/graphql';
import type { TaskStatus } from '@/types/tasks';
import { TaskStatuses } from '@/types/tasks';

import { toDateOrNull } from '@/util/date';

const taskTabs: any[] = [
  {
    icon: <PendingIcon />,
    iconPosition: 'start',
    label: 'Pending',
    value: TaskStatuses.Pending,
    status: TaskStatuses.Pending
  },
  {
    icon: <AttemptedIcon />,
    iconPosition: 'start',
    label: 'Attempted',
    value: TaskStatuses.Attempted,
    status: TaskStatuses.Attempted
  },
  {
    icon: <TaskAltIcon />,
    iconPosition: 'start',
    label: 'Completed',
    value: TaskStatuses.Completed,
    status: TaskStatuses.Completed
  }
];

const TasksList = () => {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    loading: organizationDataLoading,
    data: organizationData,
    called: organizationDataCalled
  } = useQuery<GetClientOrganizationsResponse>(GET_CLIENT_ORGANIZATIONS_STANDARD, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    notifyOnNetworkStatusChange: true
  });

  const [saveUpdateTask] = useMutation<SaveUpdateTaskResponse>(SAVE_UPDATE_TASK);
  const [deleteTask] = useMutation<DeleteTaskResponse>(DELETE_TASK);

  const [currentPage, setCurrentPage] = useState(0);

  const { roleType } = useToken();
  const { id } = useParams();

  const [selectedOrgId, setSelectedOrgId] = useState<string>('all');
  const [selectedClientStoreId, setSelectedClientStoreId] = useState<string>('');

  const {
    setTaskDrawerInfo,
    fetchTasksInPatientContextLocally,
    setFetchTasksInPatientContextLocally,
    callGetTasks,
    reloadTasks,
    tasks,
    tasksTotalCount
  } = useContext(PatientDetailContext);

  const [isProviderAdmin] = useState<boolean>(roleType === Role.PROVIDER);
  const { showModal } = useModal();
  const [tasksFromContext, setTasksFromContext] = useState<TaskType[]>([]);

  const [rowCountState, setRowCountState] = useState(tasksTotalCount);

  const defaults: { id: string; name: string }[] = [{ id: 'all', name: 'All' }];

  const [clientOrganizations, setClientOrganizations] = useState<ClientOrganizationObject[]>([]);
  const [filteredClientStores, setFilteredClientStores] = useState<ClientStoreObject[]>([]);

  const taskIdFromParam = searchParams.get('taskId');
  const [taskIdFromQs, setTaskIdFromQs] = useState(taskIdFromParam);

  const [tab, setTab] = useState<number>(0);
  const [taskStatus, setTaskStatus] = useState<TaskStatus>(TaskStatuses.Pending);

  const showDeleteEncounterConfirmationModal = (taskData: TaskType) => {
    const modalRef = showModal(DeleteEncounterConfirmationDialog, {
      title: 'Delete Task',
      description: 'Are you sure you want to delete this task?',
      onConfirm: () => {
        modalRef.hide();
        onRemoveTaskConfirmed(taskData?.id);
      },
      hideDialog: () => modalRef.hide()
    });
  };

  const onRemoveTaskConfirmed = taskId => {
    deleteTask({
      variables: {
        truentityId: id,
        taskId: taskId
      }
    })
      .then(() => {
        reloadTasks();
        // fetch context level tasks as well if necessary
        // reloadTasks();
      })
      .catch(() => {
        enqueueSnackbar('Error while deleting a task', {
          variant: 'error'
        });
      });
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: 'Type',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_TYPES.filter(type => type.value === cellValues.row.type);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={1} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'subType',
        headerName: 'Sub Type',
        minWidth: 210,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = UPDATED_SUB_TASK_TYPES.filter(type => type.value === cellValues.row.subType);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={6} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'billedAmount',
        headerName: 'Billed Amount',
        minWidth: 110,
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        valueFormatter: params => `$ ${params.value || 0}`
      },
      {
        field: 'bonusAmount',
        headerName: 'Bonus Amount',
        sortable: true,
        flex: 1,
        minWidth: 110,
        align: 'left',
        headerAlign: 'left',
        valueFormatter: params => `$ ${params.value || 0}`
      },
      {
        field: 'medicationName',
        headerName: 'Medication',
        sortable: true,
        valueGetter: params => params.row.medicationName,
        flex: 1,
        minWidth: 160,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'modeOfResolution',
        headerName: 'Mode Of Resolution',
        sortable: true,
        flex: 1,
        minWidth: 200,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_MODE_OF_RESOLUTION_TYPES.filter(type => type.value === cellValues.row.modeOfResolution);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'wrapUp',
        headerName: 'Status',
        sortable: true,
        flex: 1,
        minWidth: 130,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedType = TASK_WRAP_UP_TYPES.filter(type => type.value === cellValues.row.wrapUpStatus);

          let labelType = '---';
          if (formattedType.length > 0) {
            labelType = formattedType[0].label;
          }

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {labelType}
            </Stack>
          );
        }
      },
      {
        field: 'performedOn',
        headerName: 'Performed On',
        sortable: true,
        type: 'date',
        valueGetter: params => toDateOrNull(params.row.performedOn),
        flex: 1,
        minWidth: 130,
        align: 'left',
        headerAlign: 'left',
        renderCell: cellValues => {
          const formattedDate = formatDateIgnoreTZ(cellValues.row.performedOn, 'YYYY-MM-DD');
          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {cellValues.row.performedOn ? formattedDate : ''}
            </Stack>
          );
        }
      },
      {
        field: 'completedByAdmin',
        headerName: 'Performed By',
        sortable: true,
        valueGetter: params => (params.row.completedByAdmin ? params.row.completedByAdmin : '---'),
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'noOfEncounters',
        headerName: '# of Encounters ',
        sortable: true,
        minWidth: 180,
        valueGetter: params => params.row.encounters.length,
        flex: 1,
        align: 'left',
        headerAlign: 'left'
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        filterable: false,
        minWidth: 110,
        renderCell: cellValues => {
          return (
            <Stack spacing={2} direction="row" sx={{ width: '100%' }} alignItems="center" justifyContent={'center'}>
              <Link to={`/patients/${id}/details/medications/tasks/edit/${cellValues?.row?.type?.toLowerCase()}/${cellValues?.id}`}>
                <EditIcon fontSize="small" style={{ color: color.truentityBlue[500] }} />
              </Link>

              <Link
                to={''}
                onClick={() => {
                  showDeleteEncounterConfirmationModal(cellValues?.row);
                }}
              >
                <DeleteIcon fontSize="small" style={{ color: color.truentityBlue[500] }} />
              </Link>

              <Link
                to={''}
                onClick={() => {
                  handleOnClickDuplicateTask(cellValues?.row);
                }}
              >
                <Icon icon={faCopy} fixedWidth size="lg" />
              </Link>
            </Stack>
          );
        }
      }
    ],
    [handleOnClickDuplicateTask, id, showDeleteEncounterConfirmationModal]
  );

  useEffect(() => {
    setTaskIdFromQs(taskIdFromParam);
  }, [taskIdFromParam]);

  useEffect(() => {
    if (tasksFromContext?.length > 0 && taskIdFromQs) {
      const task = tasksFromContext.find(t => t.id === taskIdFromQs);

      if (task) {
        setTaskDrawerInfo(task);
      } else {
        const snackbarKey = enqueueSnackbar('No Task with this id found in your organization', {
          variant: 'error'
        });

        setTimeout(() => {
          setTaskDrawerInfo({} as TaskType);
          closeSnackbar(snackbarKey);

          navigate(`/patients/${id}/details/medications/tasks/list`);
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksFromContext, taskIdFromQs]);

  // useEffect(() => {
  //   if (!taskDrawerInfo && taskIdFromQs) {
  //     searchParams.delete('taskId');
  //     setSearchParams(searchParams);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tasks]);

  useEffect(() => {
    if (tasks) {
      setTasksFromContext(tasks);
    } else {
      setTasksFromContext([]);
    }
  }, [tasks]);

  useEffect(() => {
    setRowCountState(prevRowCountState => (tasksTotalCount !== undefined ? tasksTotalCount : prevRowCountState));
  }, [tasksTotalCount, setRowCountState]);

  useEffect(() => {
    if (organizationDataCalled && !organizationDataLoading && organizationData) {
      setClientOrganizations([...defaults, ...organizationData.clientOrganizations.clientOrganizations]);
    }
  }, [organizationDataLoading, organizationData, organizationDataCalled, defaults]);

  useEffect(() => {
    setFilteredClientStores([]);
    setSelectedClientStoreId('');

    const org = clientOrganizations?.find(o => o.id === selectedOrgId);
    if (org && org.clientStores?.length > 0) {
      setFilteredClientStores([...defaults, ...org.clientStores]);
      setSelectedClientStoreId('all');
    }
  }, [clientOrganizations, defaults, selectedOrgId]);

  useEffect(() => {
    callGetTasks({
      truentityId: id,
      currentPage,
      selectedOrgId,
      selectedClientStoreId,
      taskStatus
    });
  }, [selectedOrgId, selectedClientStoreId, taskStatus, currentPage, callGetTasks, id]);

  useEffect(() => {
    if (fetchTasksInPatientContextLocally) {
      reloadTasks();
      setFetchTasksInPatientContextLocally(false);
    }
  }, [fetchTasksInPatientContextLocally, reloadTasks, setFetchTasksInPatientContextLocally]);

  const handleOnClickDuplicateTask = task => {
    const formattedPerformedOnDate = task?.performedOn ? moment(task?.performedOn).format() : null;
    try {
      const taskObj = {
        name: task?.name,
        type: task?.type,
        subType: task?.subType,
        status: 'PENDING',
        resolutionStatus: task?.resolutionStatus,
        resolutionNotes: 'NOTES',
        performedOn: formattedPerformedOnDate,
        minutesTaken: 23,
        performedAt: 'at',
        nextCallScheduledFor: '2002-03-22',
        nextSteps: task?.nextSteps,
        healthPlan: task?.healthPlan,
        location: task?.location,
        payment: task?.payment,
        billableEligible: false,
        billedAmount: Number(task?.billedAmount),
        validationPaymentEligible: true,
        validationPaymentAmount: true,
        notes: 'notes test',
        medicationName: task?.medicationName,
        modeOfResolution: task?.modeOfResolution,
        wrapUpStatus: task?.wrapUpStatus,
        isPrimaryAffiliation: Boolean(task?.isPrimaryAffiliation)
      };

      saveUpdateTask({
        variables: {
          taskData: {
            ...taskObj
          },
          truentityId: id,
          taskId: ''
        }
      }).then(response => {
        if (response.data?.saveUpdateTask?.status === 'Success') {
          reloadTasks();
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (event: SyntheticEvent, selectedTabIndex: number) => {
    setTab(selectedTabIndex);

    const selectedStatus: TaskStatus = taskTabs[selectedTabIndex].value;
    setTaskStatus(selectedStatus);
  };

  return (
    <Stack spacing={2}>
      <H3 textAlign="center" css={{ width: '100%' }}>
        Task List
      </H3>

      <Stack direction="row">
        <FormControl sx={{ width: '24%', marginRight: 1 }} variant="outlined" margin="dense" size="medium">
          <InputLabel id={'organizations-input'}>Organization</InputLabel>

          <Select
            labelId={'organizations'}
            label={'Organizations'}
            placeholder={'select an organization'}
            value={selectedOrgId}
            onChange={event => {
              setSelectedOrgId(event.target.value);
            }}
          >
            {clientOrganizations.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {filteredClientStores.length > 0 && (
          <FormControl sx={{ width: '24%' }} variant="outlined" fullWidth margin="dense" size="medium">
            <InputLabel id={'organizations-input'}>Location</InputLabel>

            <Select
              labelId={'stores'}
              label={'Stores'}
              placeholder={'select a store'}
              value={selectedClientStoreId}
              onChange={event => {
                setSelectedClientStoreId(event.target.value);
              }}
            >
              {filteredClientStores.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      <Stack
        spacing={2}
        sx={{
          borderBottom: 1,
          borderRight: 0,
          borderColor: 'divider'
        }}
      >
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange} sx={{ width: '350px' }}>
          {taskTabs.map((tab, index) => (
            <Tab key={index} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>

        <Stack direction="row" spacing={1} justifyContent={'flex-end'}>
          <Button
            disabled={isProviderAdmin}
            label="Add TIP Tasks"
            onClick={() => {
              navigate(`/patients/${id}/details/medications/tasks/new/tip`);
            }}
          />
          <Button
            disabled={isProviderAdmin}
            label="Add CMR Tasks"
            onClick={() => {
              navigate(`/patients/${id}/details/medications/tasks/new/cmr`);
            }}
          />
          <Button
            disabled={isProviderAdmin}
            label="Add Adherence Tasks"
            onClick={() => {
              navigate(`/patients/${id}/details/medications/tasks/new/adherence`);
            }}
          />
        </Stack>

        <Paper component={Stack} direction="column" spacing={2}>
          <div style={{ display: 'flex' }}>
            <TruentityDataGrid
              name={'dg-tasks-list'}
              columnVisibilityModel={{
                completedByAdmin: tab === 0
              }}
              autoHeight
              rows={tasksFromContext}
              rowCount={rowCountState}
              paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
              onPaginationModelChange={({ page }) => {
                setCurrentPage(page);
              }}
              columns={columns}
              paginationMode="server"
              onCellClick={selectedCell => {
                if (selectedCell.field !== 'actions') {
                  setTaskDrawerInfo(selectedCell?.row);
                }
              }}
            />
          </div>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default TasksList;
