import Alert from '@/components/Alert';
import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box, Button, DialogActions, DialogContent } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

type Props = BaseDialogProps & {
  open: boolean;
  onSave: (file) => void;
  hideDialog: () => void;
};

const UploadLogoDialog = ({ open, hideDialog, onSave, ...props }: Props) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [preview, setPreview] = useState<string | ArrayBuffer | null>(null);

  const onDrop = acceptedFiles => {
    setError('');
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    const file = acceptedFiles[0];

    if (file && file.size < maxSize) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError('File is too large.');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png']
    }
  });

  const handleSave = () => {
    onSave(file);
    hideDialog();
    setError('');
  };

  return (
    <BaseDialog {...props} title={props.title} open={open} hideDialog={hideDialog} fullWidth={true} maxWidth={'sm'}>
      <DialogContent>
        {error && <Alert status={'error'} title={'Failed to upload logo'} description={error} sx={{ marginBottom: 2 }} />}
        <Box {...getRootProps()} sx={{ border: '2px dashed gray', padding: 2, textAlign: 'center', cursor: 'pointer' }}>
          <input {...getInputProps()} />
          <PhotoCamera sx={{ fontSize: 40 }} />
          <p>Drag your photo here, or</p>
          <Button variant="contained" component="span">
            Browse Files
          </Button>
        </Box>
        {preview && (
          <Box sx={{ marginTop: 2, textAlign: 'center' }}>
            <Box component={'img'} src={preview} sx={{ margin: 'auto', maxWidth: '100px', maxHeight: '100px' }} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={hideDialog}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default UploadLogoDialog;
