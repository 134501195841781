import { Subtitle } from '@/components/Typography';
import FlexContainer from '@/elements/FlexContainer';
import type {
  ConsultQuestion,
  EndConsultResponse,
  GetMedConsultTemplateItemsByIdResponse,
  SaveConsultItemResponse,
  StartConsultResponse
} from '@/graphql/med-consult';
import {
  CHANGE_MED_CONSULT_STATUS,
  END_CONSULT,
  GET_MED_CONSULT_ITEMS_BY_TEMPLATE_ID,
  SAVE_CONSULT_ITEM,
  START_CONSULT
} from '@/graphql/med-consult';
import { ADD_ACCOUNT_NOTE } from '@/graphql/remotePatientMonitoring';
import useDebounce from '@/hooks/useDebounce';
import type { MedrecSnapshot } from '@/types/dischargePatient';
import { MedConsultStatusType } from '@/types/remotePatientMonitoring';
import { formatDateAndTime } from '@/util/format';
import { cleanupObj } from '@/util/medications';
import { medConsultToString } from '@/util/rpm';
import type { QuestionUpdate } from '@/zustand/MedConsultWindowStore';
import { useMedConsultWindowStore } from '@/zustand/MedConsultWindowStore';
import { useLazyQuery, useMutation } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, Box, Button, Card, CardActions, CardHeader, Fab, Tooltip, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Alert from '../Alert';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import TruentityTextField from '../TruentityTextField';

type Props = {
  firstName?: string;
  lastName?: string;
  availableMedRec?: MedrecSnapshot[];
};

const MedConsultWindow = ({ firstName = '', lastName = '' }: Props) => {
  const { id } = useParams();
  const { showModal } = useModal();
  const location = useLocation();

  const {
    openWindow,
    consult,
    addNewQuestion,
    updateQuestion,
    newQuestionTxt,
    newQuestionSecondaryTxt,
    expanded,
    isMedConsultStart,
    selectedConsultType,
    setOpenWindow,
    setRefetchConsults,
    setConsult,
    setAddNewQuestion,
    setUpdateQuestion,
    setNewQuestionTxt,
    setNewQuestionSecondaryTxt,
    setExpanded,
    setIsMedConsultStart,
    setSelectedConsultType
  } = useMedConsultWindowStore();

  const fullName = lastName && firstName ? `${lastName}, ${firstName}` : '---';

  const debouncedUpdateQuestion = useDebounce<QuestionUpdate>(updateQuestion, 500);

  const [consultItems, setConsultItems] = useState<ConsultQuestion[]>([]);
  const [isResumedClick, setIsResumedClick] = useState<boolean>(false);

  const [getMedConsultItems] = useLazyQuery<GetMedConsultTemplateItemsByIdResponse>(GET_MED_CONSULT_ITEMS_BY_TEMPLATE_ID, {
    fetchPolicy: 'network-only'
  });

  const [startMedConsult] = useMutation<StartConsultResponse>(START_CONSULT);
  const [saveMedConsultItem, { data: savedItemData }] = useMutation<SaveConsultItemResponse>(SAVE_CONSULT_ITEM);
  const [endMedConsult] = useMutation<EndConsultResponse>(END_CONSULT);
  const [updateMedConsult] = useMutation(CHANGE_MED_CONSULT_STATUS);
  const [addAccountNote] = useMutation(ADD_ACCOUNT_NOTE);

  const { enqueueSnackbar } = useSnackbar();

  const isInProgressConsultStatus = consult?.status === MedConsultStatusType.IN_PROGRESS;
  const isEndedConsultStatus = consult?.status === MedConsultStatusType.ENDED;
  const isArchivedConsultStatus = consult?.status === MedConsultStatusType.ARCHIVED;

  const isNotNewConsult = isEndedConsultStatus || isInProgressConsultStatus || isArchivedConsultStatus;
  const answerTextAreaDisableStatus = !isMedConsultStart && !isResumedClick && !isInProgressConsultStatus;
  const addQuestionButtonStatus = !isInProgressConsultStatus && !isResumedClick;
  const startButtonDisableStatus = isMedConsultStart || isEndedConsultStatus || isInProgressConsultStatus || isArchivedConsultStatus;
  const archiveButtonDisableStatus = !isMedConsultStart && !isResumedClick && !(isInProgressConsultStatus || isEndedConsultStatus);
  const endButtonDisableStatus = !isMedConsultStart && !isResumedClick && !isInProgressConsultStatus;
  const resumeButtonDisableStatus = !(isEndedConsultStatus || isArchivedConsultStatus) || isResumedClick;

  const handleQuestionTxtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewQuestionTxt(event.target.value);
  };

  const handleQuestionSecondaryTxtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewQuestionSecondaryTxt(event.target.value);
  };

  const addQuestion = () => {
    if (consult) {
      if (newQuestionTxt) {
        saveMedConsultItem({
          variables: {
            medConsultId: consult.id,
            medConsultItemData: {
              primaryText: newQuestionTxt,
              secondaryText: newQuestionSecondaryTxt,
              responseText: '',
              orderNum: consult.items.length + 1
            }
          }
        }).finally(() => {
          setAddNewQuestion(false);
          setNewQuestionTxt('');
          setNewQuestionSecondaryTxt('');
        });
      }
    }
  };

  const ConsultSubHeading = () => {
    return (
      <>
        <Subtitle>{fullName}</Subtitle>
        {consult && (
          <Subtitle fontSize={'small'} mt={'-5px'}>
            {formatDateAndTime(consult.startTime)}
          </Subtitle>
        )}
      </>
    );
  };
  const handleChangePanel = panel => (evt, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const createPatientNote = useCallback(() => {
    if (!consult) {
      return;
    }

    try {
      return addAccountNote({
        variables: {
          truentityId: id,
          modeOfCapture: 'Other',
          description: medConsultToString(consult),
          dateTime: null
        }
      });
    } catch (error) {
      console.log('Error occurred in med consult add account' + error);
    }
  }, [consult]);

  const onConsultQuestionChange = (index: number, answer: string) => {
    if (consult) {
      setUpdateQuestion({ index, answer });
      const currentConsult = cloneDeep(consult);
      currentConsult.items[index].responseText = answer;
      setConsult(currentConsult);
    }
  };

  const openMedConsultCard = async () => {
    try {
      if (!selectedConsultType) {
        enqueueSnackbar('Please select a consult type', {
          variant: 'error'
        });
      } else {
        const response = await getMedConsultItems({ variables: { templateId: selectedConsultType?.id } });
        const templateItems = response.data?.medConsultTemplateItemsById;
        if (!templateItems) {
          enqueueSnackbar('Unable to get consult items', {
            variant: 'error'
          });
          return;
        }
        setConsultItems(templateItems);
      }
    } catch (error) {
      console.error(error);

      enqueueSnackbar('Unable to start new consult', {
        variant: 'error'
      });
    }
  };

  const startMedConsultation = async () => {
    const response = await startMedConsult({ variables: { truentityId: id, templateId: selectedConsultType?.id } });
    const medicalConsultation = response.data?.startMedConsult?.medConsult;
    if (!medicalConsultation) {
      enqueueSnackbar('Unable to start new consult', {
        variant: 'error'
      });
      return;
    }
    medicalConsultation.items = cleanupObj(medicalConsultation.items);
    setConsult(medicalConsultation);
  };

  const updateConsult = async (medConsultStatus: MedConsultStatusType) => {
    if (!consult) {
      return;
    }

    const result = await updateMedConsult({
      variables: {
        medConsultId: consult.id,
        medConsultStatus: medConsultStatus
      }
    });

    const status = result.data?.updateMedConsultStatus?.status;

    if (status === 'Success') {
      enqueueSnackbar('Successfully update consult status', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar('Unable to update consult', {
        variant: 'error'
      });
    }
  };

  const endConsult = async () => {
    if (!consult) {
      return;
    }

    const result = await endMedConsult({
      variables: {
        medConsultId: consult.id
      }
    });

    const status = result.data?.endMedConsult?.status;

    if (status === 'Success') {
      createPatientNote()?.finally(() => clearValues());
      enqueueSnackbar('Successfully ended consult', {
        variant: 'success'
      });
    } else {
      enqueueSnackbar('Unable to end consult', {
        variant: 'error'
      });
    }
  };

  const handelResume = () => {
    updateConsult(MedConsultStatusType.IN_PROGRESS).then(() => setIsResumedClick(true));
  };

  const showConfirmationModal = () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Submission',
      message: 'This will complete this Q&A, and make the responses available in the reports. Are you sure?',
      onAgree: () => {
        endConsult()
          .then(() => {
            modal.hide();
          })
          .catch(err => {
            console.error(err);
            enqueueSnackbar('Unable to complete consult', { variant: 'error' });
          });
        clearValues();
      },
      onDisagree: () => modal.hide()
    });
  };

  const showArchiveConfirmationModal = () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Submission',
      message: 'This will archive this Q&A and these will not be available in the reports. Are you sure?',
      onAgree: () => {
        updateConsult(MedConsultStatusType.ARCHIVED)
          .then(() => {
            modal.hide();
            clearValues();
          })
          .catch(err => {
            console.error(err);
            enqueueSnackbar('Unable to complete consult', { variant: 'error' });
          });
      },
      onDisagree: () => modal.hide()
    });
  };

  const showCloseConfirmationDialog = () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Close Medical Consult',
      message:
        'Are you sure you want to close the currently running medical consult? If you proceed, please note that the consult will be recorded as "In Progress," allowing you to answer the remaining questions at a later time.',
      onAgree: () => {
        clearValues();
        modal.hide();
      },
      onDisagree: () => modal.hide()
    });
  };

  const handelCloseButton = () => {
    if ((consult && isInProgressConsultStatus) || isResumedClick) showCloseConfirmationDialog();
    else clearValues();
  };

  const clearValues = useCallback(() => {
    setConsult(null);
    setConsultItems([]);
    setUpdateQuestion({} as QuestionUpdate);
    setAddNewQuestion(false);
    setRefetchConsults(true);
    setOpenWindow(false);
    setExpanded('');
    setIsMedConsultStart(false);
    setIsResumedClick(false);
  }, [
    setConsult,
    setConsultItems,
    setUpdateQuestion,
    setAddNewQuestion,
    setRefetchConsults,
    setOpenWindow,
    setExpanded,
    setIsMedConsultStart,
    setIsResumedClick
  ]);

  useEffect(() => {
    setSelectedConsultType(null);
    clearValues();
  }, [location, setSelectedConsultType, clearValues]);

  useEffect(() => {
    if (debouncedUpdateQuestion) {
      const question = consult?.items[debouncedUpdateQuestion.index];

      if (!question) {
        return;
      }

      question.responseText = debouncedUpdateQuestion.answer;
      consult.items[debouncedUpdateQuestion.index] = question;

      saveMedConsultItem({
        variables: {
          medConsultId: consult.id,
          medConsultItemData: {
            id: question.id,
            primaryText: question.primaryText,
            secondaryText: question.secondaryText,
            responseText: question.responseText
          }
        }
      });
    }
  }, [debouncedUpdateQuestion, saveMedConsultItem]);

  useEffect(() => {
    if (savedItemData) {
      const { medConsultItem } = savedItemData.saveMedConsultItem;

      if (consult?.items && !consult?.items.find(item => item.id === medConsultItem.id)) {
        const currentConsult = cloneDeep(consult);
        currentConsult.items = [...currentConsult.items, medConsultItem];
        setConsult(currentConsult);
      }
    }
  }, [savedItemData]);

  return (
    <>
      {!openWindow && (
        <Tooltip title={!selectedConsultType ? 'Please select a consult type' : 'Start new consult'}>
          <Fab
            variant="extended"
            color="primary"
            onClick={() => {
              if (selectedConsultType || consult) {
                if (!consult && !addNewQuestion) {
                  openMedConsultCard();
                  setOpenWindow(!openWindow);
                } else {
                  setOpenWindow(!openWindow);
                }
              }
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Consult
          </Fab>
        </Tooltip>
      )}

      {openWindow && (
        <Card
          elevation={14}
          sx={{
            zIndex: theme => theme.zIndex.drawer + 1,
            position: 'fixed',
            bottom: 50,
            right: 50,
            width: 450,
            padding: '0 4px',
            border: '1px solid #00000021'
          }}
        >
          <CardHeader
            title="Medical Consult"
            subheader={ConsultSubHeading()}
            action={
              <IconButton aria-label="Close" onClick={handelCloseButton}>
                <CloseIcon />
              </IconButton>
            }
            sx={{ '&.MuiCardHeader-root': { pb: 0 } }}
          />
          <Alert
            variant="standard"
            status="info"
            title="This is a new consultation. You may click on Start to begin recording answers."
            hidden={isNotNewConsult}
          />

          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#f5f5f5',
              flex: '1 0 auto',
              maxHeight: 500,
              overflow: 'auto'
            }}
          >
            {!consult && !selectedConsultType && (
              <Typography variant="body1" color="text.secondary">
                Please select a Consult Template
              </Typography>
            )}

            {((consult && consult.items) || consultItems) &&
              !addNewQuestion &&
              ((consult?.items ?? []).length > 0 ? consult?.items : consultItems)?.map((consultQuestion, index) => {
                return (
                  <Accordion key={`index_${index}`} expanded={expanded === `index_${index}`} onChange={handleChangePanel(`index_${index}`)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`consult-content-${index}`}
                      id={`consult-header-${index}`}
                    >
                      <FlexContainer justify={'start'} align={'start'}>
                        <Typography variant="body1">{consultQuestion.primaryText}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {consultQuestion.secondaryText}
                        </Typography>
                      </FlexContainer>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TruentityTextField
                        sx={{ width: '100%' }}
                        multiline
                        rows={4}
                        defaultValue={consultQuestion.responseText ?? ''}
                        onChange={e => onConsultQuestionChange(index, e.target.value)}
                        disabled={answerTextAreaDisableStatus}
                        focused={expanded === `index_${index}`}
                      />

                      {consultQuestion.suggestedText && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              width: '100%'
                            }
                          }}
                        >
                          <Typography sx={{ fontSize: 12, mb: 0.5, mt: 1.3, color: 'error.main' }} color="text.secondary" gutterBottom>
                            Suggested Action
                          </Typography>

                          <Typography sx={{ fontSize: 13, m: 0 }} variant="body2">
                            {consultQuestion.suggestedText}
                          </Typography>
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                );
              })}

            {consult && addNewQuestion && (
              <>
                <TruentityTextField
                  sx={{ width: '100%' }}
                  multiline
                  rows={4}
                  placeholder="Enter new question"
                  id="add-new-question-txt"
                  value={newQuestionTxt}
                  onChange={handleQuestionTxtChange}
                />

                <TruentityTextField
                  sx={{ width: '100%' }}
                  multiline
                  rows={2}
                  value={newQuestionSecondaryTxt}
                  onChange={handleQuestionSecondaryTxtChange}
                  placeholder="Enter additional text (optional)"
                  id="add-new-question-secondary-txt"
                />
              </>
            )}
          </CardContent>

          <CardActions disableSpacing sx={{ height: 50 }}>
            {consult && !addNewQuestion && (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  setAddNewQuestion(true);
                }}
                disabled={addQuestionButtonStatus}
              >
                Add Question
              </Button>
            )}

            {consult && addNewQuestion && (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  addQuestion();
                }}
              >
                Save
              </Button>
            )}
            {consult && addNewQuestion && (
              <Button
                size="small"
                color="primary"
                onClick={() => {
                  setAddNewQuestion(false);
                }}
              >
                Cancel
              </Button>
            )}

            {(consult || consultItems.length > 0) && !addNewQuestion && (
              <Box sx={{ marginLeft: 'auto' }}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    setIsMedConsultStart(true);
                    startMedConsultation();
                  }}
                  disabled={startButtonDisableStatus}
                >
                  Start
                </Button>
                <Button size="small" color="primary" onClick={showConfirmationModal} disabled={endButtonDisableStatus}>
                  End
                </Button>
                <Button size="small" color="primary" onClick={showArchiveConfirmationModal} disabled={archiveButtonDisableStatus}>
                  Archive
                </Button>
                <Button size="small" color="primary" onClick={handelResume} disabled={resumeButtonDisableStatus}>
                  Resume
                </Button>
              </Box>
            )}
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default MedConsultWindow;
