export const removeDashes = (str: string) => {
  return str.replaceAll('-', ' ');
};

export const isStringDefined = (str: string | undefined | null) => {
  return str && str.length > 0;
};

export const unknown = () => {
  return '---';
};

export const emptyString = () => {
  return ' ';
};

export const invariantCultureIgnoreCase = (str1: string, str2: string) => {
  return str1.localeCompare(str2, undefined, { sensitivity: 'base' }) === 0;
};

export const capitalizeLetterBeforeCharacter = (str: string | undefined) => {
  return str?.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const phoneNumberFormat = (str: string | undefined) => {
  return str?.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
};

export const removeNonNumeric = (str: string | null | undefined) => {
  return str?.replace(/\D/g, '');
};

export const convertToSentenceCase = (str: string | undefined) => {
  if (!str) return '';

  const lowerCaseText = str.toLowerCase();

  return lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1);
};

export const capitalizeLetterBeforeCharacterAddSpace = (str: string | null | undefined) => {
  return str?.replace(/([a-z])([A-Z])/g, '$1 $2')?.replace(/\b\w/g, match => match?.toUpperCase());
};

export const parseStringOrDefault = (value: string | null | undefined): string => {
  return value ? value.trim() : '';
};

export const isString = (input: unknown) => typeof input === 'string' || input instanceof String;
export const containsOnlyDigits = (input: string): boolean => /^\d+$/.test(input);
