import Button from '@/components/Button';
import RpmApprovalStatusChip from '@/components/ChipLabel/RpmApprovalStatusChip';
import type { CommentDialogValues } from '@/components/Dialogs/CreateCommentDialog';
import ProviderCommentDialog from '@/components/Dialogs/ProviderCommentDialog';
import GenderIcon from '@/components/Gender/GenderIcon';
import LoadingOverlay from '@/components/LoadingOverlay';
import MuiTabs from '@/components/MuiTabs';
import type { TextSelectOption } from '@/components/SelectList';
import SelectList from '@/components/SelectList';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { Body1, H1, H2, H3, H5 } from '@/components/Typography';
import type { CareSummaryUrlType, GetRpmProviderSessionAccountResponse } from '@/graphql/remotePatientMonitoring';
import { GET_PROVIDER_SESSION_ACCOUNT, UPDATE_PROVIDER_SESSION_ACCOUNT_STATUS } from '@/graphql/remotePatientMonitoring';
import styled from '@/styles';
import {
  ConfirmDialogLabelTypes,
  mappedDialogLabelToRpmStatus,
  ProviderReviewTypeEnum,
  RpmApprovalStatusTypes
} from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDateIgnoreTZ } from '@/util/format';
import { getRpmProviderAuthCode, mapRpmDialogLabelToStatus } from '@/util/rpm';
import { useLazyQuery, useMutation } from '@apollo/client';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Chip, Divider, Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import type { PatientsData } from '../Patients/patients';

const PDFViewer = styled('iframe', {
  width: '100%',
  height: '100%',
  minHeight: '600px'
});

const RpmEnrollmentRecord = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [getRpmProviderAccount, { data: rpmProviderAccountData, error: rpmProviderAccountError, loading: rpmProviderAccountLoading }] =
    useLazyQuery<GetRpmProviderSessionAccountResponse>(GET_PROVIDER_SESSION_ACCOUNT, { fetchPolicy: 'cache-and-network' });
  const [updateRpmSessionAccountStatus] = useMutation(UPDATE_PROVIDER_SESSION_ACCOUNT_STATUS);

  const [patientDetail, setPatientDetail] = useState<PatientsData | null>(null);
  const [careSummaryReportUrls, setCareSummaryReportUrls] = useState<TextSelectOption[]>([]);
  const [selectedReportTab, setSelectedReportTab] = useState<number>(0);
  const [selectedCareSummaryReportIndex, setSelectedCareSummaryReportIndex] = useState<number | null>(null);
  const [rpmEncounterReportUrl, setRpmEncounterReportUrl] = useState<string | null>(null);

  const { showModal } = useModal();
  const { providerActivityId } = useParams();
  const navigate = useNavigate();
  const authCode = getRpmProviderAuthCode();

  const showCommentModal = async (status: ConfirmDialogLabelTypes) => {
    try {
      const modal = showModal(ProviderCommentDialog, {
        title:
          status === ConfirmDialogLabelTypes.APPROVE
            ? ((
                <Stack textAlign="center" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                  <H2
                    sx={{
                      fontSize: 'medium'
                    }}
                  >
                    RPM Enrollment Approval
                  </H2>
                  <H3>{`${getAccountUserFullName(patientDetail?.user)}`}</H3>
                </Stack>
              ) as any)
            : ((
                <Stack textAlign="center" display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                  <H2
                    sx={{
                      fontSize: 'medium'
                    }}
                  >
                    {`Are you sure you want to ${status === ConfirmDialogLabelTypes.SKIP ? 'mark Incomplete' : status.toLowerCase()}?`}{' '}
                  </H2>
                </Stack>
              ) as any),
        uiLabels: {
          commentInput: 'Comment',
          submitButton: status,
          cancelButton: 'Cancel'
        },
        prevComment:
          patientDetail?.rpmApprovalStatus && mappedDialogLabelToRpmStatus[status] === patientDetail?.rpmApprovalStatus
            ? patientDetail?.rpmStatusComment
            : undefined,
        hideInitialComment: status !== ConfirmDialogLabelTypes.APPROVE,
        reviewType: ProviderReviewTypeEnum.ACCOUNT,
        providerActivityId: providerActivityId,
        authCode: authCode,
        onSubmitted: async (values: CommentDialogValues) => {
          try {
            await updateRpmSessionAccountStatus({
              variables: {
                providerActivityId: providerActivityId,
                rpmStatus: mapRpmDialogLabelToStatus(status),
                authCode: authCode,
                comment: values.comment
              }
            });

            navigate('../view');

            enqueueSnackbar('Successfully Updated RPM Status', { variant: 'success' });
          } catch (error) {
            enqueueSnackbar(`Could not ${status.toLowerCase()} patient, please try again later.`, { variant: 'error' });
          } finally {
            modal.hide();
          }
        },
        onCancelled: () => {
          modal.hide();
        },
        hideDialog: () => modal.hide()
      });
    } catch (error) {
      console.error('Error showing or processing comment modal:', error);
      enqueueSnackbar('Error showing or processing comment modal', { variant: 'error' });
    }
  };

  const callGetRpmProviderAccount = async () => {
    try {
      await getRpmProviderAccount({
        variables: {
          providerActivityId: providerActivityId,
          authCode: authCode
        }
      });
    } catch (error: any) {
      enqueueSnackbar('Failed to retrieve provider account', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (rpmProviderAccountData?.getProviderSessionAccount) {
      setPatientDetail(rpmProviderAccountData.getProviderSessionAccount.account);
      const mappedCareSummaryReportUrls = rpmProviderAccountData.getProviderSessionAccount.careSummaryUrls.map(
        (careSummaryUrl: CareSummaryUrlType, index) => {
          return {
            label: formatDateIgnoreTZ(careSummaryUrl.requestedOn),
            value: `${index}`
          };
        }
      );
      setCareSummaryReportUrls(mappedCareSummaryReportUrls);
      if (mappedCareSummaryReportUrls.length > 0) {
        setSelectedCareSummaryReportIndex(0);
      } else {
        setSelectedCareSummaryReportIndex(null);
      }
      if (rpmProviderAccountData.getProviderSessionAccount.rpmEncounterUrl) {
        fetch(`${rpmProviderAccountData.getProviderSessionAccount.rpmEncounterUrl}`)
          .then(response => response.blob())
          .then(blob => {
            const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
            setRpmEncounterReportUrl(url);
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        setRpmEncounterReportUrl(null);
      }
    }
  }, [rpmProviderAccountData]);

  useEffect(() => {
    if (providerActivityId) {
      callGetRpmProviderAccount();
    }
  }, [providerActivityId]);

  useEffect(() => {
    if (rpmProviderAccountError) {
      enqueueSnackbar(rpmProviderAccountError?.message ?? 'Failed to retrieve patient details', { variant: 'error' });
    }
  }, [enqueueSnackbar, rpmProviderAccountError]);

  return (
    <Stack flex={1}>
      {<LoadingOverlay active={rpmProviderAccountLoading} text="Please wait. Loading Landing Patient Data..." />}
      {rpmProviderAccountError ? (
        <Paper
          sx={{
            width: '100%',
            minHeight: '600px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Stack flex={1} display="flex" justifyContent="center" alignItems="center">
            <H5>Patient Details Not Found</H5>
          </Stack>
        </Paper>
      ) : (
        <Stack spacing={2}>
          <Paper
            elevation={1}
            color={'inherit'}
            component={Stack}
            p={4}
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack alignItems={'flex-start'} spacing={1}>
              <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                <Button
                  type="button"
                  label={'All Patients'}
                  onClick={() => navigate('../view')}
                  variant={'text'}
                  startIcon={<ArrowBackIosIcon />}
                />
              </Stack>
              <Stack direction="row" spacing={1} alignItems={'center'}>
                <H1>{getAccountUserFullName(patientDetail?.user)}</H1>
                <Chip label={patientDetail?.healthPlan?.orgName} variant={'outlined'} />
              </Stack>
              <Stack direction="row" spacing={1} alignItems={'center'}>
                {patientDetail?.gender && <GenderIcon gender={patientDetail?.gender} />}
                <Body1 fontWeight={'600'}>{formatDateIgnoreTZ(patientDetail?.birthDate)}</Body1>
                <Divider variant={'middle'} orientation="vertical" flexItem role={'presentation'} />
                <TruentityPhoneNumber value={patientDetail?.phone} />
              </Stack>
            </Stack>
            <Stack flexDirection={'column'} spacing={1} justifyContent={'center'} alignItems={'center'}>
              <Stack direction={'row'} spacing={1}>
                <Button
                  disabled={rpmProviderAccountLoading}
                  label={ConfirmDialogLabelTypes.APPROVE}
                  onClick={() => showCommentModal(ConfirmDialogLabelTypes.APPROVE)}
                />
                <Button
                  disabled={rpmProviderAccountLoading}
                  label={ConfirmDialogLabelTypes.REJECT}
                  onClick={() => showCommentModal(ConfirmDialogLabelTypes.REJECT)}
                />
                <Button
                  disabled={rpmProviderAccountLoading}
                  label={ConfirmDialogLabelTypes.SKIP}
                  onClick={() => showCommentModal(ConfirmDialogLabelTypes.SKIP)}
                />
              </Stack>
              {patientDetail?.rpmApprovalStatus &&
              [RpmApprovalStatusTypes.REJECTED, RpmApprovalStatusTypes.APPROVED, RpmApprovalStatusTypes.SKIPPED].includes(
                patientDetail?.rpmApprovalStatus as RpmApprovalStatusTypes
              ) ? (
                <Stack direction="row" spacing={1} paddingY={1} alignItems={'center'} justifyContent={'flex-end'}>
                  <Body1>Current Status:&nbsp;</Body1>
                  <RpmApprovalStatusChip approvalStatus={patientDetail?.rpmApprovalStatus} />
                </Stack>
              ) : (
                <></>
              )}
            </Stack>
          </Paper>
          <Paper>
            <MuiTabs
              tabVariant="standard"
              onTabChange={selectedTabIndex => {
                setSelectedReportTab(selectedTabIndex);
              }}
              sx={{
                height: selectedReportTab === 0 ? '1100px' : '1000px',
                width: '100%'
              }}
              tabs={[
                {
                  label: 'Care Summary',
                  disabled: careSummaryReportUrls?.length === 0,
                  children: (
                    <Stack flexDirection="column" justifyContent="flex-start" alignItems="stretch">
                      {careSummaryReportUrls.length > 1 && (
                        <Stack
                          sx={{
                            m: 1
                          }}
                          width="100%"
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <SelectList
                            fullWidth={false}
                            formControlProps={{ sx: { width: 250 } }}
                            label="Report Date"
                            options={careSummaryReportUrls}
                            value={selectedCareSummaryReportIndex !== null ? `${selectedCareSummaryReportIndex}` : ''}
                            onChange={event => {
                              if (!isNaN(Number(event.target.value))) {
                                setSelectedCareSummaryReportIndex(Number(event.target.value));
                              }
                            }}
                          />
                        </Stack>
                      )}
                      <Stack
                        width="100%"
                        height="100%"
                        sx={{ border: '1px solid #ccc', height: '900px', justifyContent: 'stretch', alignItems: 'stretch' }}
                      >
                        {selectedCareSummaryReportIndex !== null ? (
                          <PDFViewer
                            src={rpmProviderAccountData?.getProviderSessionAccount?.careSummaryUrls[selectedCareSummaryReportIndex].url}
                          />
                        ) : (
                          <Stack flex={1} textAlign={'center'} paddingTop={2}>
                            <H3>
                              {careSummaryReportUrls?.length === 0 && !rpmProviderAccountLoading ? 'No Care Summary Report Found' : ''}
                            </H3>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  )
                },
                {
                  label: 'New Patient Encounter Document',
                  disabled: rpmEncounterReportUrl === null,
                  children: (
                    <Stack
                      width="100%"
                      height="100%"
                      sx={{ border: '1px solid #ccc', height: '900px', justifyContent: 'stretch', alignItems: 'stretch' }}
                    >
                      {rpmEncounterReportUrl && <PDFViewer src={rpmEncounterReportUrl} />}
                    </Stack>
                  )
                }
              ]}
            />
          </Paper>
        </Stack>
      )}
    </Stack>
  );
};

export default RpmEnrollmentRecord;
